import React from 'react';
import PropTypes from 'prop-types';
import { Container, Header, Grid } from 'semantic-ui-react';
import awsvideoconfig from '../../../aws-video-exports';
import VideoPlayer from '../../Atoms/VideoStreamingExample/VideoPlayer';

export default function VideoUI(props) {
    let videoJsOptions = {
        autoplay: true,
        controls: true,
        sources: [
            {
                src: null,
            },
        ],
        width: 800,
        height: 450,
    };

    const { content } = props;

    if (content?.content?.upload_file?.key) {
        let key = content.content.upload_file.key;

        let noTypeTag = key.slice(0, key.length - 4);
        let splitbySlash = key.split('/')[2];
        let addEnd = splitbySlash.slice(0, splitbySlash.length - 4);
        addEnd += '.m3u8';

        videoJsOptions.sources[0].src = `https://${awsvideoconfig.awsOutputVideo}/public/${noTypeTag}/hls/${addEnd}`;
    } else {
        return <p>File Not Found</p>;
    }

    const margin = {
        marginBottom: '100px',
    };

    return (
        <div style={margin}>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Container textAlign="center">
                            <Header as="h2">
                                {content.content && content.content.name != null
                                    ? content.content.name
                                    : 'No Title Assigned For Video'}
                            </Header>
                        </Container>
                        <Container textAlign="center">
                            <Header as="h4" color={'grey'}>
                                {content.content &&
                                content.content.description != null
                                    ? content.content.description
                                    : 'No Description Assigned For Video'}
                            </Header>
                        </Container>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={5} centered={true}>
                    <Grid.Column width={10}>
                        <VideoPlayer {...videoJsOptions} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}

VideoUI.propTypes = {
    assignment: PropTypes.object,
    content: PropTypes.object,
};
