import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { listProfiles } from '../../../graphql/queries';
import PieChart from '../../Molecules/Charts/PieChart';
import { Grid, Segment, Header } from 'semantic-ui-react';
import * as actionType from '../../../store/actionType';

class MySchool extends Component {
    async componentDidMount() {
        if (
            Object.keys(this.props.interests).length === 0 &&
            Object.keys(this.props.goals).length === 0
        ) {
            // get all profiles from backend
            const profiles = await API.graphql(graphqlOperation(listProfiles));

            // build data structures specified in state
            profiles.data.listProfiles.items.forEach((profile) => {
                this.props.onPopulateSchoolData(profile);
            });
        }
    }

    // pick a random color
    randomColor = () => {
        return '#' + Math.floor(Math.random() * 16777215).toString(16);
    };

    render() {
        // put all values of career interests in an array used as chart labels
        const interests = Object.keys(this.props.interests).map(
            (interest) => interest
        );

        // put all values of post-secondary goals in an array used as chart labels
        const goals = Object.keys(this.props.goals).map((goal) => goal);

        // get student count for each career interest in an array
        const interestCounts = Object.keys(this.props.interests).map((interest) => {
            let count = 0;
            Object.keys(this.props.interests[interest]).forEach((grade) => {
                count += this.props.interests[interest][grade];
            });
            return count;
        });

        // get student count for each post-secondary goal in an array
        const goalCounts = Object.keys(this.props.goals).map((goal) => {
            let count = 0;
            Object.keys(this.props.goals[goal]).forEach((grade) => {
                count += this.props.goals[goal][grade];
            });
            return count;
        });

        // get colors for charts
        const interestColors = Object.keys(this.props.interests).map((interest) =>
            this.randomColor()
        );
        const goalColors = Object.keys(this.props.goals).map((goal) =>
            this.randomColor()
        );

        // data for career interests chart
        const interestData = {
            labels: interests,
            datasets: [
                {
                    label: 'Career Interests',
                    backgroundColor: interestColors,
                    data: interestCounts,
                },
            ],
        };

        // data for post-secondary goals chart
        const goalData = {
            labels: goals,
            datasets: [
                {
                    label: 'Post-Secondary Paths',
                    backgroundColor: goalColors,
                    data: goalCounts,
                },
            ],
        };

        // options for career interests chart
        const interestOptions = {
            title: {
                display: true,
                text: 'Career Interests',
                fontSize: 20,
            },
            legend: {
                display: true,
                position: 'right',
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            const interest = interests[context.dataIndex];
                            const grades = this.props.interests[interest];
                            let label = [];
                            label.push(context.label + ': ' + context.parsed);
                            label.push('Grade breakdown:');

                            // calculate grade breakdown
                            Object.keys(grades).forEach((grade) => {
                                const percent = (
                                    (100 * grades[grade]) /
                                    interestCounts[context.dataIndex]
                                ).toFixed(0);
                                label.push('Grade ' + grade + ': ' + percent + '%');
                            });

                            return label;
                        },
                    },
                },
            },
        };

        // options for post-secondary goals chart
        const goalOptions = {
            title: {
                display: true,
                text: 'Post-Secondary Paths',
                fontSize: 20,
            },
            legend: {
                display: true,
                position: 'right',
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            const goal = goals[context.dataIndex];
                            const grades = this.props.goals[goal];
                            let label = [];
                            label.push(context.label + ': ' + context.parsed);
                            label.push('Grade breakdown:');

                            // calculate grade breakdown
                            Object.keys(grades).forEach((grade) => {
                                const percent = (
                                    (100 * grades[grade]) /
                                    goalCounts[context.dataIndex]
                                ).toFixed(0);
                                label.push('Grade ' + grade + ': ' + percent + '%');
                            });

                            return label;
                        },
                    },
                },
            },
        };

        const charsDisplay = (
            <Segment basic>
                <Grid columns={2} stackable textAlign="center" divided>
                    <Grid.Row verticalAlign="middle">
                        <Grid.Column>
                            <Header as="h5">
                                {'Career Interests'.toUpperCase()}
                            </Header>
                            <PieChart
                                data={interestData}
                                options={interestOptions}
                            />
                        </Grid.Column>

                        <Grid.Column>
                            <Header as="h5">
                                {'Post-Secondary Paths'.toUpperCase()}
                            </Header>
                            <PieChart data={goalData} options={goalOptions} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );

        return charsDisplay;
    }
}

// get access to desired global states
const mapStateToProps = (state) => {
    return {
        interests: state.cm.interests,
        goals: state.cm.postSecondaryGoals,
    };
};

// get access to desired dispatches in reducer
const mapDispatchToProps = (dispatch) => {
    return {
        onPopulateSchoolData: (profile) =>
            dispatch({ type: actionType.POPULATE_MY_SCHOOL, profile: profile }),
    };
};

MySchool.propTypes = {
    interests: PropTypes.shape({}),
    goals: PropTypes.shape({}),
    onPopulateSchoolData: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(MySchool);
