/* eslint-disable react/prop-types */
import React from 'react';
import { Field as FormikField, ErrorMessage } from 'formik';
import { Dropdown, Message, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';

/*
 * Checboxes could be use as Radios (single choice) or DropDown (multiple choice)
 * pass in type 'radio' or 'checkbox' as prop
 */
const DropDown = (props) => {
    const { label, name, options, ...rest } = props;
    const onChange = (e, data, form) => {
        form.setFieldTouched(data.name);
        form.setFieldValue(data.name, data.value);
        form.validateField(data.name);
    };
    const onBlur = (e, data, form) => {
        form.handleBlur(data.name);
    };
    return (
        <div style={{ margin: '1rem' }}>
            <Form.Field required={rest.required}>
                <label>{label}</label>
            </Form.Field>
            <ErrorMessage
                name={name}
                component={(errMsg) => (
                    <Message negative>
                        <p>{errMsg.children}</p>
                    </Message>
                )}
            />
            <FormikField name={name} {...rest}>
                {(props) => {
                    const { field, form } = props;
                    // console.log(field);
                    return (
                        <Dropdown
                            {...field}
                            placeholder={rest.placeholder}
                            name={name}
                            fluid
                            search
                            multiple={rest.multiple}
                            selection
                            options={options}
                            onChange={(e, data) => onChange(e, data, form)}
                            onBlur={(e, data) => onBlur(e, data, form)}
                        />
                    );
                }}
            </FormikField>
        </div>
    );
};

DropDown.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.array.isRequired,
    field: PropTypes.object,
};

export default DropDown;
