/* eslint-disable react/prop-types */

import React, { useState } from 'react';
import { Formik, Field as FormikField, yupToFormErrors } from 'formik';
import * as Yup from 'yup';
import { Form, Table, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const LikertScale = (props) => {
    const { label, question, options, scaleValues, setScaleValues } = props;
    const [value, setValue] = useState(null);

    const onChange = (value) => {
        setValue(value);
        setScaleValues({ ...scaleValues, [question]: value });
    };

    return (
        <Table.Row>
            <Table.Cell textAlign="left">{question}</Table.Cell>
            {options.map((option) => (
                <Table.Cell key={option}>
                    <Form.Radio
                        label={label}
                        value={option}
                        checked={value === option}
                        onChange={(e, { value }) => onChange(value)}
                    />
                </Table.Cell>
            ))}
        </Table.Row>
    );
};

LikertScale.propTypes = {
    question: PropTypes.string,
};

export default LikertScale;
