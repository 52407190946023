export const getPathway = /* GraphQL */ `
    query GetPathway($id: ID!) {
        getPathway(id: $id) {
            id
            name
            is_approved
            category
            description
            LearningOutcome
            owner
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            Assignments {
                items {
                    studentID
                }
            }
            PathwayContents {
                items {
                    content {
                        id
                        name
                        type
                        upload_file {
                            key
                        }
                        createdAt
                        description
                    }
                }
            }
        }
    }
`;

export const getTeacher = /* GraphQL */ `
    query GetTeacher($id: ID!) {
        getTeacher(id: $id) {
            id
            name
            email
        }
    }
`;

export const getCaseManager = /* GraphQL */ `
    query GetCaseManager($id: ID!) {
        getCaseManager(id: $id) {
            id
            name
            email
        }
    }
`;
