import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const TabButton = (props) => {
    const { show = true, children, onClick, color } = props;
    return (
        show && (
            <Button primary floated="right" onClick={onClick} color={'blue'}>
                {children}
            </Button>
        )
    );
};

TabButton.propTypes = {
    show: PropTypes.bool,
    children: PropTypes.string,
    onClick: PropTypes.func,
    color: PropTypes.string,
};

export default TabButton;
