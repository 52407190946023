import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Storage, API, graphqlOperation, Auth } from 'aws-amplify';
import config from '../aws-exports';
import { v4 as uuidv4 } from 'uuid';
import { Role } from '../UI/index';

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties,
    };
};

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

/**
 * Custom countdown hook
 * @returns remaining seconds to 0
 *
 * @author Duc
 */
export const useCountDown = (seconds = 5, path = '/') => {
    const history = useHistory();
    const [count, setCount] = useState(seconds);
    useEffect(() => {
        if (count >= 0) {
            setTimeout(() => setCount(count - 1), 1000);
        } else {
            history.push(path);
        }
    });
    return count;
};

/**
 * Create file in DynamoDB table
 * @param {*} data coresponding data for the graphql mutation
 * @param {*} mutation graphql mutation
 * @returns graphql mutation data
 */
export const upload = async (data, mutation) => {
    try {
        const result = await API.graphql(
            graphqlOperation(mutation, { input: data })
        );
        return result;
    } catch (e) {
        throw e;
    }
};

/**
 * Retrieve signed S3 url from S3Object key
 * @param {*} key S3Object key
 * @returns signed url
 */
export const getUrl = async (key) => {
    return await Storage.get(key);
};

/**
 * Upload file to S3 and return an S3Object with key
 * @param {*} file Selected file
 * @param {*} path S3 path
 * @returns S3Object
 * @author Duc
 */
export const uploadFile = async (file, path) => {
    // Construct unique filename with path
    const fileName = `${path}/${uuidv4()}-${file.name}`;
    // Check logged in user
    await Auth.currentAuthenticatedUser({
        bypassCache: false,
    });
    // Upload file to S3 using Storage API
    const result = await Storage.put(fileName, file);
    // Construct an S3Object
    const fileData = {
        bucket: config.aws_user_files_s3_bucket,
        region: config.aws_user_files_s3_bucket_region,
        key: result.key,
        // contentType: "application/pdf"
    };
    return fileData; // S3Object
};

/**
 * redirect user to the first tab of their dashboard, or home if the useer is not authenticated
 * @param {*} userRole authenticatedUser's role
 * @param {*} history useState variable
 */
export const redirectUser = (userRole, history) => {
    if (userRole == Role.CASE_MANAGER) {
        history.push('/dashboard/caseload');
    } else if (userRole == Role.TEACHER) {
        history.push('/dashboard/progress-monitor');
    } else if (userRole == Role.STUDENT) {
        history.push('/dashboard');
    } else if (userRole == Role.PARENT) {
        history.push('/dashboard/data-insights');
    }
};
