import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as actionTypes from './actionTypes';
import * as queries from './queries';

export const fetchStudentStart = () => {
    return {
        type: actionTypes.FETCH_STUDENT_START,
    };
};

export const fetchStudentFail = (error) => {
    return {
        type: actionTypes.FETCH_STUDENT_FAIL,
        error: error,
    };
};

export const fetchStudentSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_STUDENT_SUCCESS,
        payload: payload,
    };
};

export const fetchParentSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_PARENT_SUCCESS,
        payload: payload,
    };
};

export const fetchChild = () => {
    return async (dispatch) => {
        await dispatch(fetchStudentStart());
        try {
            let payload;
            const user = await Auth.currentAuthenticatedUser();
            const parentData = await API.graphql(
                graphqlOperation(queries.getParent, {
                    id: user.username,
                })
            );
            payload = parentData.data.getParent;
            await dispatch(fetchParentSuccess(payload));
            const studentID = payload.ParentStudents.items[0]?.studentID;
            if (!studentID) throw new Error('No children found!');
            const result = await API.graphql(
                graphqlOperation(queries.getStudent, { id: studentID })
            );
            payload = result.data.getStudent;
            await dispatch(fetchStudentSuccess(payload));
        } catch (error) {
            await dispatch(fetchStudentFail());
        }
    };
};
