import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import 'semantic-ui-css/semantic.min.css';
import Amplify from 'aws-amplify';
import config from './aws-exports';
import './index.css';
import 'semantic-ui-css/semantic.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import caseManagerReducer from './store/reducers/caseManagerReducer';
import teacherReducer from './store/reducers/teacherReducer';
import parentReducer from './store/Parent/reducer';
import adminReducer from './store/reducers/adminReducer';
import cmReducer from './store/CaseManager/reducer';
import studentUserReducer from './store/Student/reducer';
import studentReducer from './store/reducers/studentReducer';
import authReducer from './store/Auth/reducer';
import studentReducer2 from './store/reducers/studentReducer';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

Amplify.configure(config);

const rootReducer = combineReducers({
    cm: caseManagerReducer,
    teacher: teacherReducer,
    student: studentReducer,
    studentUser: studentUserReducer,
    parent: parentReducer,
    admin: adminReducer,
    casemanager: cmReducer,
    auth: authReducer,
    student2: studentReducer2,
});

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
