/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Form, Transition, Input, Message } from 'semantic-ui-react';
import { Field } from 'formik';
import { Role } from '../../';
import { useParams } from 'react-router-dom';
import {
    listSchools,
    listCaseManagers,
    listTeachers,
    listStudents,
} from '../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';

/**
 * Validate school email with matched school domain
 * @param {*} value input email
 * @param {*} domain school domain
 * @param {*} role user role
 * @returns error if email not have correct school domain
 */
const validateEmail = (value, domain, role) => {
    let error;
    if (!domain) {
        return 'School domain not found. Please contact admin';
    }
    const domainRegex = new RegExp(`^[A-Za-z0-9._%+-]+@${domain}$`);
    if (role !== Role.PARENT && !domainRegex.test(value)) {
        error = 'Incorrect school email domain';
    }
    return error;
};

const Verification = (props) => {
    const {
        values,
        touched,
        errors,
        handleBlur,
        setFieldTouched,
        setFieldValue,
        validateField,
        verified,
        setVerified,
        setFieldError,
        setVerifiedUser,
    } = props;

    const [schools, setSchools] = useState();
    const [isParent, setIsParent] = useState(false);

    const [domain, setDomain] = useState();
    const { role } = useParams();

    const verifyHandler = async (data) => {
        let userData, result;

        const getUser = async (query) => {
            // Verification ID is the user school ID. This will be saved
            // if user is parent, verification ID is student school ID
            // when user create an account
            let data = {
                filter: {
                    // id: {
                    //     eq: "a2af4d47-8430-460a-8170-4e233e60f54"
                    // },
                    verification_id: {
                        eq: values.verificationID,
                    },
                },
            };
            // Check parent access code from student account by sending
            // along with the request to get student
            if (isParent) {
                data.filter.parent_access_code = {
                    eq: values.accessCode.toLowerCase(),
                };
            }

            try {
                result = await API.graphql(graphqlOperation(query, data));
            } catch (err) {
                setFieldError('error', 'Verification failed, please contact admin');
            }
            return result.data;
        };

        switch (data.role) {
            case Role.CASE_MANAGER:
                userData = await getUser(listCaseManagers);
                userData = userData.listCaseManagers.items;
                break;
            case Role.TEACHER:
                userData = await getUser(listTeachers);
                userData = userData.listTeachers.items;
                break;
            case Role.STUDENT:
                userData = await getUser(listStudents);
                userData = userData.listStudents.items;
                break;
            case Role.PARENT:
                userData = await getUser(listStudents);
                userData = userData.listStudents.items;
                break;
            default:
                break;
        }

        if (userData.length !== 0) {
            setVerified(true);
            const user = userData.find(
                (user) => user.verification_id === values.verificationID
            );
            if (user) setVerifiedUser(user);
            else setFieldError('error', 'User school ID not exist');
        } else {
            setVerified(false);
            setFieldError(
                'error',
                'Failed to verify your account, please contact admin'
            );
        }
    };

    // Retrieve all the school from database then map to dropdown list
    const getSchools = async () => {
        const result = await API.graphql(graphqlOperation(listSchools));
        setSchools(
            result.data.listSchools.items?.map((item) => {
                return {
                    key: item.id,
                    text: item.name,
                    value: item.id,
                    domain: item.domain,
                };
            })
        );
        // const domain = 'nyc.com' // replace with s
        // setDomain(domain);
    };

    useEffect(() => {
        getSchools();
    }, []);

    // Disable verification mechanism for
    // user roles other than parent temporary
    useEffect(() => {
        if (role !== Role.PARENT) {
            setVerified(true);
        } else setIsParent(true);
    }, [role]);

    return (
        <>
            {(schools && (
                <Field
                    name="school"
                    as={Form.Select}
                    options={schools}
                    placeholder="Select your school"
                    onBlur={(e, data) => {
                        handleBlur(data.name);
                    }}
                    onChange={(e, data) => {
                        setFieldTouched(data.name);
                        setFieldValue(data.name, data.value);
                        validateField(data.name);
                        setFieldValue('role', role);
                        if (role === Role.PARENT) setVerified(false);
                        setDomain(
                            schools.find((school) => school.value === data.value)
                                ?.domain
                        );
                    }}
                />
            )) || <Input fluid disabled loading />}

            <Transition visible={false}>
                <Field
                    value={role}
                    name="role"
                    readOnly
                    type="hidden"
                    as={Form.Input}
                />
            </Transition>

            <Transition
                visible={!!values.school && !!values.role && (verified || isParent)}
                animation="slide down"
                duration={500}
            >
                <Field
                    type="email"
                    name="email"
                    fluid
                    icon="mail outline"
                    iconPosition="left"
                    placeholder={isParent ? 'Email' : 'School Email'}
                    error={
                        touched.email && errors.email
                            ? {
                                  content: errors.email,
                                  pointing: 'above',
                              }
                            : null
                    }
                    as={Form.Input}
                    readOnly={verified && isParent}
                    validate={(value) => validateEmail(value, domain, values.role)}
                />
            </Transition>

            <Transition
                visible={!!values.school && !!values.role}
                animation="slide down"
                duration={500}
            >
                <Field
                    name="verificationID"
                    fluid
                    icon="id badge"
                    iconPosition="left"
                    placeholder={
                        values.role === Role.STUDENT || isParent
                            ? 'Student ID'
                            : 'Employee ID'
                    }
                    error={
                        touched.verificationID && errors.verificationID
                            ? {
                                  content: errors.verificationID,
                                  pointing: 'above',
                              }
                            : null
                    }
                    as={Form.Input}
                    readOnly={verified && isParent}
                />
            </Transition>
            <Transition
                visible={!!values.school && !!values.role && isParent}
                animation="slide down"
                duration={500}
            >
                <Field
                    name="accessCode"
                    fluid
                    icon="id badge"
                    iconPosition="left"
                    placeholder="Access Code"
                    error={
                        touched.accessCode && errors.accessCode
                            ? {
                                  content: errors.accessCode,
                                  pointing: 'above',
                              }
                            : null
                    }
                    as={Form.Input}
                    readOnly={verified}
                />
            </Transition>

            <Transition
                visible={!!values.school && !!values.role && isParent && !verified}
                animation="slide down"
                duration={500}
            >
                <Form.Button
                    type="button"
                    color={verified ? 'green' : 'blue'}
                    inverted={verified}
                    fluid
                    disabled={
                        verified ||
                        !values.school ||
                        !values.role ||
                        !values.email ||
                        !values.verificationID ||
                        (!values.accessCode && isParent)
                    }
                    readOnly={verified}
                    onClick={() => verifyHandler(values)}
                >
                    {verified ? 'Verified' : 'Verify'}
                </Form.Button>
            </Transition>
            {verified && isParent && (
                <Message positive>Verified successfully</Message>
            )}
        </>
    );
};

export default Verification;
