import React, { useState } from 'react';
import AddButton from './TabButton/TabButton';
import { WorkSpace } from '../../Molecules';
import { Segment, Item, Grid } from 'semantic-ui-react';
import StudentProfileCards from './StudentProfileCards';
import { connect } from 'react-redux';
import { AddStudentModal } from '../../Molecules';
import PropTypes from 'prop-types';

const StudentList = (props) => {
    const { student, isCollaborator } = props;
    const [openSearchStudent, setOpenSearchStudent] = useState(false);
    const [openWorkSpace, setOpenWorkSpace] = useState(false);
    return (
        (openWorkSpace && (
            <WorkSpace
                studentID={student?.id}
                openWorkSpace={openWorkSpace}
                setOpenWorkSpace={setOpenWorkSpace}
                isCollaborator={isCollaborator}
            />
        )) || (
            <Item>
                <Segment basic clearing style={{ padding: 0 }}>
                    <AddButton
                        show={!isCollaborator}
                        onClick={() => setOpenSearchStudent(true)}
                    >
                        Add Student
                    </AddButton>
                </Segment>
                <Segment basic clearing style={{ padding: 0 }}>
                    <StudentProfileCards
                        isCollaborator={isCollaborator}
                        openWorkSpace={openWorkSpace}
                        setOpenWorkSpace={setOpenWorkSpace}
                    />
                </Segment>
                <AddStudentModal
                    title="Add Students To Caseload"
                    setOpen={setOpenSearchStudent}
                    open={openSearchStudent}
                />
            </Item>
        )
    );
};

const mapStateToProps = (state) => ({
    student: state.casemanager.student,
});

export default connect(mapStateToProps)(StudentList);

StudentList.propTypes = {
    student: PropTypes.object,
    isCollaborator: PropTypes.bool,
};
