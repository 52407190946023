import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Container } from 'semantic-ui-react';
import CongratulationPopup from '../../Atoms/CongratsPopup/CongratulationPopup';

export const ConfirmationButtons = (props) => {
    const { setOpen, next, complete } = props;
    // const [openCongratsPopup, setCongratsPopup] = useState(false)

    // if (openCongratsPopup) {
    //     return <CongratulationPopup />
    // }

    return (
        <Container fluid textAlign="right" style={{ paddingRight: '1rem' }}>
            <Button color="yellow" onClick={complete}>
                Yes, I&apos;m Done
            </Button>
            <Button positive onClick={next}>
                Add Another Module
            </Button>
            <Button color="black" onClick={() => setOpen(false)}>
                Close
            </Button>
        </Container>
    );
};

export default ConfirmationButtons;

ConfirmationButtons.propTypes = {
    setOpen: PropTypes.func,
    isValid: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    dirty: PropTypes.bool,
    next: PropTypes.func,
    complete: PropTypes.func,
};
