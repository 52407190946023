import React from 'react';
import { Field as FormikField, ErrorMessage } from 'formik';
import { Checkbox, Radio, Message, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { formData } from '..';

/*
 * Checboxes could be use as Radios (single choice) or Checkboxes (multiple choice)
 * pass in type 'radio' or 'checkbox' as prop
 */
const CheckBoxes = (props) => {
    const { label, name, options, type, ...rest } = props;
    const onClick = (e, data) => {
        if ('setExtraInput' in rest && data.id === formData.SOMETHING_ELSE) {
            rest.setExtraInput(data.checked ? true : false);
        }
    };
    const control = type === formData.RADIO_TYPE ? Radio : Checkbox;

    return (
        <div style={{ margin: '1rem' }}>
            <Form.Field required={rest.required}>
                <label>{label}</label>
            </Form.Field>
            <ErrorMessage
                name={name}
                component={(errMsg) => (
                    <Message negative>
                        <p>{errMsg.children}</p>
                    </Message>
                )}
            />
            <FormikField name={name} {...rest}>
                {(props) => {
                    const { field } = props;
                    return options.map(
                        (option) =>
                            option.value && (
                                <Form.Field
                                    {...field}
                                    key={option.key}
                                    id={option.key}
                                    value={option.value}
                                    checked={
                                        type === formData.RADIO_TYPE
                                            ? field.value === option.value
                                            : field.value.includes(option.value)
                                    }
                                    label={option.value}
                                    control={control}
                                    onClick={onClick}
                                    onMouseOver={() => {
                                        if (rest.canHover) {
                                            rest.onMouseOver(option.description);
                                        }
                                    }}
                                />
                            )
                    );
                }}
            </FormikField>
        </div>
    );
};

CheckBoxes.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.array.isRequired,
    field: PropTypes.object,
    type: PropTypes.string.isRequired,
};

export default CheckBoxes;
