import React, { Component } from 'react';
import { Grid, Button, Segment, Label, Card } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

class Syllabus extends Component {
    state = {
        dueDate: null,
    };

    componentDidMount() {
        if (
            this.props.content.length > 0 &&
            this.props.content[0] &&
            this.props.content[0].Assignments &&
            this.props.content[0].Assignments.items &&
            this.props.content[0].Assignments.items.length > 0 &&
            this.props.content[0].Assignments.items[0].createdAt !== null
        ) {
            const date = new Date(
                this.props.content[0].Assignments.items[0].createdAt
            );
            const formattedDate = format(date, 'MMMM do, yyyy H:mma');
            this.setState({ dueDate: formattedDate });
        }
    }

    render() {
        let modules = [];
        let projects = [];
        if (
            (this.props.content.length > 0 || this.props.content[0]) &&
            this.props.content[0].PathwayContents
        ) {
            const contents = this.props.content[0].PathwayContents.items.filter(
                (content) => content.content.type !== 'Project'
            );
            const projectModules =
                this.props.content[0].PathwayContents.items.filter(
                    (content) =>
                        content.content.type === 'Project' &&
                        content.content.description !== 'example'
                );
            contents.sort(function (a, b) {
                return new Date(a.createdAt) - new Date(b.createdAt);
            });

            projectModules.sort(function (a, b) {
                return new Date(a.createdAt) - new Date(b.createdAt);
            });

            modules = contents.map((content, index) => (
                <Card key={'content' + index} color="orange">
                    <Card.Content>
                        <Card.Header>{'Module ' + (index + 1)}</Card.Header>
                        <Card.Meta>{content.content.type}</Card.Meta>
                        <Card.Description>{content.content.name}</Card.Description>
                    </Card.Content>
                </Card>
            ));

            projects = projectModules.map((content, index) => (
                <Card key={'content' + index} color="purple">
                    <Card.Content>
                        <Card.Header>{'Project ' + (index + 1)}</Card.Header>
                        <Card.Meta>{content.content.name}</Card.Meta>
                        <Card.Description>
                            {content.content.description}
                        </Card.Description>
                    </Card.Content>
                </Card>
            ));
        }

        return (
            <Grid relaxed stackable centered padded>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <Label>
                            {this.props.content.length > 0 &&
                            this.props.content[0] &&
                            this.props.content[0].Assignments &&
                            this.props.content[0].Assignments.items &&
                            this.props.content[0].Assignments.items.length > 0 &&
                            this.props.content[0].Assignments.items[0].due_date !==
                                null &&
                            (this.props.content[0].Assignments.items[0]
                                .casemanagerID !== null ||
                                this.props.content[0].Assignments.items[0]
                                    .teacherID !== null)
                                ? 'Assigned'
                                : 'Not Assigned'}
                        </Label>
                    </Grid.Column>
                    {this.props.content.length > 0 &&
                    this.props.content[0] &&
                    this.props.content[0].Assignments &&
                    this.props.content[0].Assignments.items &&
                    this.props.content[0].Assignments.items.length > 0 &&
                    this.props.content[0].Assignments.items[0].due_date !== null &&
                    (this.props.content[0].Assignments.items[0].casemanagerID !==
                        null ||
                        this.props.content[0].Assignments.items[0].teacherID !==
                            null) ? (
                        <Grid.Column width={5}>
                            <Label>
                                Due
                                <Label.Detail>{this.state.dueDate}</Label.Detail>
                            </Label>
                        </Grid.Column>
                    ) : null}
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Segment>
                            <Label as="a" color="teal" ribbon>
                                Overview
                            </Label>
                            <br />
                            {this.props.content.length > 0 &&
                                this.props.content[0] &&
                                this.props.content[0].description}
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                {modules.length > 0 ? (
                    <Grid.Row>
                        <Grid.Column>
                            <Segment>
                                <Label as="a" color="orange" ribbon>
                                    Modules
                                </Label>
                                <br />
                                <Card.Group centered>{modules}</Card.Group>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                ) : null}
                {projects.length > 0 ? (
                    <Grid.Row>
                        <Grid.Column>
                            <Segment>
                                <Label as="a" color="purple" ribbon>
                                    Projects
                                </Label>
                                <br />
                                <Card.Group centered>{projects}</Card.Group>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                ) : null}
                <Grid.Row>
                    <Grid.Column>
                        <Segment>
                            <Label as="a" color="yellow" ribbon>
                                Takeaway
                            </Label>
                            <br />
                            {this.props.content.length > 0 &&
                                this.props.content[0] &&
                                this.props.content[0].LearningOutcome}
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

Syllabus.propTypes = {
    content: PropTypes.array,
    assignment: PropTypes.bool,
};

export default withRouter(Syllabus);
