import React from 'react';
import { CheckBox } from '../../Atoms';
import PropTypes from 'prop-types';

export const StudentResult = (props) => {
    const { students } = props;

    return <CheckBox label="Search Student" name="students" options={students} />;
};

export default StudentResult;

StudentResult.propTypes = {
    students: PropTypes.array,
    formProps: PropTypes.object,
};
