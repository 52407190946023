export const ListStudentsForCM = /* GraphQL */ `
    query SyncCaseManagerStudents(
        $filter: ModelCaseManagerStudentFilterInput
        $limit: Int
        $nextToken: String
        $lastSync: AWSTimestamp
    ) {
        syncCaseManagerStudents(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            lastSync: $lastSync
        ) {
            items {
                student {
                    id
                    name
                    email
                    createdAt
                    IEPGoals {
                        items {
                            id
                            name
                            description
                            createdAt
                            _version
                            updatedAt
                        }
                    }
                    Profile {
                        id
                        grade
                        post_secondary
                        interests
                        career_interest
                        skill_strengths
                        skill_weaknesses
                        is_completed
                        completedDate
                        superpower_survey_file {
                            key
                        }
                        _version
                        _deleted
                        _lastChangedAt
                        createdAt
                        updatedAt
                    }
                    Progresses {
                        items {
                            _version
                            academic_strengths
                            attendance_frequency
                            academic_weaknesses
                            createdAt
                            extra_input
                            id
                            iepgoalID
                            improve_area
                            meet_daily_objectives
                            observation
                            progress_toward_iep
                            school_class
                            success_used_support
                        }
                    }
                }
            }
            nextToken
            startedAt
        }
    }
`;

export const syncTeacherStudents = /* GraphQL */ `
    query SyncTeacherStudents(
        $filter: ModelTeacherStudentFilterInput
        $limit: Int
        $nextToken: String
        $lastSync: AWSTimestamp
    ) {
        syncTeacherStudents(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            lastSync: $lastSync
        ) {
            items {
                student {
                    id
                    name
                    email
                    createdAt
                    IEPGoals {
                        items {
                            id
                            name
                            description
                            createdAt
                            _version
                            updatedAt
                        }
                    }
                    Profile {
                        id
                        grade
                        post_secondary
                        interests
                        career_interest
                        skill_strengths
                        skill_weaknesses
                        is_completed
                        completedDate
                        superpower_survey_file {
                            key
                        }
                        _version
                        _deleted
                        _lastChangedAt
                        createdAt
                        updatedAt
                    }
                    Progresses {
                        items {
                            _version
                            academic_strengths
                            attendance_frequency
                            academic_weaknesses
                            createdAt
                            extra_input
                            id
                            iepgoalID
                            improve_area
                            meet_daily_objectives
                            observation
                            progress_toward_iep
                            school_class
                            success_used_support
                        }
                    }
                }
            }
            nextToken
            startedAt
        }
    }
`;

export const getStudent = /* GraphQL */ `
    query GetStudent($id: ID!) {
        getStudent(id: $id) {
            id
            name
            email
            verification_id
            parent_access_code
            is_active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            IEPGoals {
                items {
                    id
                    name
                    description
                    createdAt
                    _version
                    updatedAt
                }
            }
            Progresses {
                items {
                    _version
                    academic_strengths
                    attendance_frequency
                    academic_weaknesses
                    createdAt
                    extra_input
                    id
                    iepgoalID
                    improve_area
                    meet_daily_objectives
                    observation
                    progress_toward_iep
                    school_class
                    success_used_support
                }
                nextToken
                startedAt
            }
            Profile {
                id
                grade
                post_secondary
                interests
                career_interest
                skill_strengths
                skill_weaknesses
                is_completed
                completedDate
                superpower_survey_file {
                    key
                }
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
            }
            Assignments {
                nextToken
                startedAt
            }
            badges {
                nextToken
                startedAt
            }
            parents {
                nextToken
                startedAt
            }
            casemanagers {
                nextToken
                startedAt
            }
            teachers {
                nextToken
                startedAt
            }
        }
    }
`;

export const listPathways = /* GraphQL */ `
    query ListPathways(
        $filter: ModelPathwayFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listPathways(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
            }
            nextToken
            startedAt
        }
    }
`;

export const listPathways2 = /* GraphQL */ `
    query ListPathways(
        $filter: ModelPathwayFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listPathways(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                is_approved
                category
                description
                LearningOutcome
                owner
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                Assignments {
                    items {
                        studentID
                    }
                }
                PathwayContents {
                    items {
                        content {
                            id
                            name
                            type
                            description
                            upload_file {
                                bucket
                                region
                                key
                            }
                            article_input
                            resource_link
                            _version
                            _deleted
                            _lastChangedAt
                            createdAt
                            updatedAt
                        }
                    }
                }
            }
            nextToken
            startedAt
        }
    }
`;
