import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextArea, Form } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { PathwayNameButtons } from '..';
import { API, graphqlOperation } from 'aws-amplify';
import { listPathways } from '../../../graphql/queries';

const PathwayName = (props) => {
    const [error, setError] = useState('');
    const initialValues = {
        pathwayInputDescrip: '',
        overviewInputDescrip: '',
        takeawayInputDescrip: '',
    };

    const validationSchema = Yup.object({
        pathwayInputDescrip: Yup.string()
            .min(1, 'Please write a description')
            .required('Input Required'),
        overviewInputDescrip: Yup.string()
            .min(1, 'Please write a description')
            .required('Input Required'),
        takeawayInputDescrip: Yup.string()
            .min(1, 'Please write a description')
            .required('Input Required'),
    });

    // check if pathway name already exists
    const duplicatePathway = async (name) => {
        const filter = {
            name: { eq: name },
        };
        const pathways = await API.graphql({
            query: listPathways,
            variables: { filter: filter },
        });
        if (pathways.data.listPathways.items.length > 0) {
            return true;
        }
    };

    const onSubmit = async (values, actions) => {
        actions.setSubmitting(false);
        if (await duplicatePathway(values.pathwayInputDescrip)) {
            setError('Pathway already exists');
        } else {
            props.next(
                values.pathwayInputDescrip,
                values.overviewInputDescrip,
                values.takeawayInputDescrip
            );
        }
    };

    // To-do: (Colin) refer to AssignStudentPathwayContent.js for incorporating formik and yup for pathway
    // then uncomment the props below for TopicSelectionButtons
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={onSubmit}
        >
            {(formProps) => {
                const {
                    isValid,
                    isSubmitting,
                    dirty,
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    handleReset,
                    handleBlur,
                } = formProps;
                return (
                    <Form onReset={handleReset} onSubmit={handleSubmit}>
                        <Grid relaxed stackable>
                            <Grid.Column width={5}>
                                <Grid.Row
                                    style={{ height: '20vh' }}
                                    verticalAlign="top"
                                    stretched
                                >
                                    <h3>Pathway Name</h3>
                                    <TextArea
                                        onChange={handleChange}
                                        name="pathwayInputDescrip"
                                        value={values.pathwayInputDescrip}
                                        placeholder="Create a title for your pathway. Hint- your title should give a clue to the topic."
                                        style={{ minHeight: 100, minWidth: 800 }}
                                        onBlur={handleBlur}
                                    />
                                    <p style={{ color: 'red' }}>
                                        {errors.pathwayInputDescrip}
                                        {error}
                                    </p>
                                </Grid.Row>

                                <Grid.Row
                                    style={{ height: '20vh' }}
                                    verticalAlign="top"
                                >
                                    <h3>Overview</h3>
                                    <TextArea
                                        onChange={handleChange}
                                        name="overviewInputDescrip"
                                        value={values.overviewInputDescrip}
                                        placeholder="Give the students a short description of what the pathway is about."
                                        style={{ minHeight: 100, minWidth: 800 }}
                                    />
                                    <p style={{ color: 'red' }}>
                                        {errors.overviewInputDescrip}
                                    </p>
                                </Grid.Row>

                                <Grid.Row
                                    style={{ height: '20vh' }}
                                    verticalAlign="top"
                                >
                                    <h3>Takeaway</h3>
                                    <TextArea
                                        onChange={handleChange}
                                        name="takeawayInputDescrip"
                                        value={values.takeawayInputDescrip}
                                        placeholder="What learning outcomes can the student expect to gain from this pathway?"
                                        style={{ minHeight: 100, minWidth: 800 }}
                                    />
                                    <p style={{ color: 'red' }}>
                                        {errors.takeawayInputDescrip}
                                    </p>
                                </Grid.Row>
                            </Grid.Column>

                            <Grid.Row columns={1} stretched>
                                <Grid.Column>
                                    <PathwayNameButtons
                                        setOpen={props.setOpen}
                                        isValid={isValid}
                                        isSubmitting={isSubmitting}
                                        dirty={dirty}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

PathwayName.propTypes = {
    setOpen: PropTypes.func,
    next: PropTypes.func,
};

export default PathwayName;
