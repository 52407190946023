import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { ConfirmationButtons } from '..';

const Confirmation = (props) => {
    return (
        <Grid relaxed stackable>
            <Grid.Row style={{ height: '63vh' }} centered columns={2}>
                <Grid.Column>
                    <h3>Are you sure you are ready to upload your pathway?</h3>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1} stretched>
                <Grid.Column>
                    <ConfirmationButtons
                        setOpen={props.setOpen}
                        next={props.next}
                        complete={props.complete}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

Confirmation.propTypes = {
    setOpen: PropTypes.func,
    next: PropTypes.func,
    complete: PropTypes.func,
};

export default Confirmation;
