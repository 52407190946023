export const RADIO_TYPE = 'radios';
export const CHECKBOX_TYPE = 'checkbox';
export const TEXT_AREA_TYPE = 'textarea';
export const TEXT_INPUT_TYPE = 'input';
export const SOMETHING_ELSE = 'Something else';
export const successUsedSupportOptions = [
    'Extended time on tests/assignments',
    'Read aloud',
    'Shortened assignments',
    'Modified/chunked assignments',
    'Use of computer/scribe',
    'Use of calculator',
    'Verbal and written directions',
    'Visual Aids/Cues/Manipulatives',
    'Additional examples of concepts/Concrete examples',
    'Guided Notes',
    'Graphic Organizers/Sentence starters',
    'One-On-One instruction',
    'Frequent Breaks',
    'Frequent redirection/check-ins',
    'Deliberate seating',
]
    .sort()
    .concat(['Something else'])
    .map((item) => ({
        key: item,
        value: item,
    }));

export const progressTowardIEPOptions = [
    'No Progression',
    'Inconsistently',
    'Gradually',
    'Satisfactorily',
    'Achieved',
].map((item) => ({
    key: item,
    text: item,
    value: item,
}));

export const attendanceFrequencyOptions = ['Yes', 'No'].map((item) => ({
    key: item,
    value: item,
}));

export const meetDailyObjectivesOptions = [
    'Always',
    'Sometimes',
    'Rarely',
    'Never',
].map((item) => ({
    key: item,
    value: item,
}));

export const academicSkills = [
    'Listening Comprehension',
    'Recalling Details',
    'Written expression',
    'Organization/Organizational Thinking',
    'Planning (pre-writes/experiments)',
    'Following steps (math problems/experiments)',
    'Making Connections',
    'Vocabulary',
    'Critical Thinking',
    'Problem-Solving',
    'Creativity',
    'Working Collaboratively',
    'Presentations',
    'Reading Comprehension',
    'Using Supporting Evidence',
    'Time Management',
    'Analytical Writing',
    'Creative Writing',
    'Informative Writing',
    'Computational Skills',
    'Writing Mechanics and Grammar',
    'Identifying claims and/or counterclaims',
    'Transferring Skills',
    'Interpreting Tables/Graphs',
    'Understanding abstract concepts',
].map((item) => ({
    key: item,
    text: item,
    value: item,
}));

export const schoolClasses = [
    'Math - Trigonometry',
    'Math - Pre-Calculus',
    'Math',
    'English Language Arts',
    'Elective',
    'Global Studies',
    'US History',
    'Government/Economics',
    'Science - Living Environment',
    'Science - Earth Science',
    'Science - Chemistry',
    'Science - Physics',
    'Science',
    'Foreign Language',
    'Physical Education/Health',
].map((item) => ({
    key: item,
    text: item,
    value: item,
}));
