import React from 'react';
import { Label, Menu, Tab } from 'semantic-ui-react';

/* 
    Single Tab object will be combine with other tabs into a pane array.
    The pane will be inserted into ContentBox component as prop
*/

const MTab = () => <Tab.Pane>Coming soon...</Tab.Pane>;
const MessageTab = {
    menuItem: (
        <Menu.Item key="messages">
            Messages<Label>15</Label>
        </Menu.Item>
    ),
    render: MTab,
};

export default MessageTab;
