export const gradeOptions = ['9', '10', '11', '12'].map((item) => ({
    key: item,
    text: item,
    value: item,
}));

export const postSecondaryOptions = [
    '4 year college/university',
    '2 year community college',
    'Trade School/ Vocational Program',
    'WorkForce',
    'Military',
    'Unsure',
].map((item) => ({
    key: item,
    text: item,
    value: item,
}));

export const careerIndustryOptions = [
    {
        industry: 'Health Sciences',
        description:
            'Careers in the Health Sciences Industry include all jobs related to providing care to people. Careers in this field include biologists, athletic trainers, dentistry, doctors, physical therapists, surgeons, sports medicine.',
    },
    {
        industry: 'Education & Training',
        description:
            'Careers in the Education & Training Industry include all teaching and administration professions from early childhood to university. This can also include private sector education such as corporate training professionals or motivational speakers. Careers in this field include teachers, school counsellors, librarians, school psychologists.',
    },
    {
        industry: 'Government',
        description:
            'Careers in Government interact with a lot of other industries because government work includes all of the systems and laws that shape society. Careers in this field include city/regional planners, legislators, tax collectors, loan officers, court officials, property inspectors, and coroners.',
    },
    {
        industry: 'Finance',
        description:
            'Careers in Finance are professions that deal mainly in money and finance management, including planning, and risk mitigation. Careers include actuaries, claims examiners, credit checkers, financial analysts, investment funds, tax preparers.',
    },
    {
        industry: 'Business Management & Administration',
        description:
            'Careers in the Business Management & Administration Industry are jobs related to the total scope of business operations. Careers include accountants, auditors, managers, chief executives, secretaries, as well as careers in advertising and marketing.',
    },
    {
        industry: 'Construction & Architecture',
        description:
            'Careers in the Construction & Architecture Industry are concerned with the development and planning of infrastructure and transportation systems. Careers include civil engineers, construction workers, heavy machinery operators, electricians, drillers, landscaping, architects, plumbers.',
    },
    {
        industry: 'Manufacturing',
        description:
            'Careers in the Manufacturing Industry are involved in the making and processing of products that are sold as goods. Careers include machine technicians, locksmiths, robotics technicians, aircrafts manufacturers, jewelers.',
    },
    {
        industry: 'Human Services',
        description:
            'The Human Services Industry refers to professions designed to help deliver services to individuals, families and communities. Careers include hairstylists, social workers, childcare, fitness trainers, psychologists, therapists, sociologists, massage therapists.',
    },
    {
        industry: 'Law, Public Safety, Corrections & Security',
        description:
            'Careers in the Law, Public Safety and Security Industry are careers that involve upholding the law and protecting people. Careers include corrections officers, police officers, criminal investigators, emergency medical technicians, paramedics, fire inspectors, lawyers, probation officers, security guards.',
    },
    {
        industry: 'Marketing, Sales & Services',
        description:
            'Careers in the Marketing, Sales & Services Industry are concerned with the marketing and selling of commercial goods or services. Careers include real estate, customer service, fashion designers, sales representatives, public relations.',
    },
    {
        industry: 'Hospitality & Tourism',
        description:
            'Careers in the Hospitality & Tourism Industry deal with lodging, travel, and recreational events. Careers include baristas, athletes, chefs, janitors, hotel/resort clerks, travel agents.',
    },
    {
        industry: 'Audio/Visual Technology, Communications & the Arts',
        description:
            'Careers in the Audio/Visual Technology, Communications & the Arts Industries are all media related jobs.Careers include actors, audio/visual equipment technicians, broadcast news analysts, reporters, editors, producers, music artists, authors/writers, choreographers.',
    },
    {
        industry: 'Science, Technology & Engineering',
        description:
            'Careers in the Science, Technology & Engineering Industries try to improve quality of life through technological innovations and science. Careers in these fields include aerospace engineers, engineers, anthropologists, astronomers chemists, scientists.',
    },
    {
        industry: 'Information Technology',
        description:
            'Careers in Information Technology (IT) keep all major industries running as they provide the computer support and infrastructure required to operate in the modern world. Careers include computer network architects, research scientists, computer programmers, computer engineers, software developers, database architects, video game designers, web developers.',
    },
    {
        industry: 'Transportation/Distribution & Logistics',
        description:
            'Careers in the Transportation, Distribution and Logistics Industry are concerned with the transportation of people or things. Careers include  air traffic controllers, pilots, automotive mechanics, truck delivery drivers.',
    },
    {
        industry: 'Agriculture/ Food & Natural Resources',
        description:
            'Careers in the Agriculture, Food & Natural Resource Industry are concerned with the production of food and the use of natural resources. Careers include animal breeders, farmers, environmental engineers, butchers, pest control, garbage/recycle workers, veterinarians.',
    },
    {
        industry: 'Unsure',
        description:
            'Click on an industry for a short description and examples careers.',
    },
].map((item) => ({
    key: item.industry,
    value: item.industry,
    description: item.description,
}));

export const argreementScale = [
    'Strongly Disagree',
    'Disagree',
    'Neutral',
    'Agree',
    'Strongly Agree',
];

export const numericalScale = ['1', '2', '3', '4', '5'];

export const WorkFutureQuestions = [
    '1. You prefer your work to be outdoors.',
    '2. You prefer your work to be indoors.',
    '3. You would like to collaborate with a lot of different people/teams/groups.',
    '4. You would like to work by yourself.',
    '5. You would be interested in traveling regularly.',
    '6. When you think about your career, the amount of money you make is most important.',
    '7. When you think about your career, being passionate about your work is most important.',
    '8. When you think about your career, loving your job is most important.',
    '9. You would like your job to include a lot of physical activity.',
    '10. You would like to work more with your hands.',
    '11. You would like to work more with computers/technology.',
    '12. You would like to work more with people',
];

export const PersonalityQuestions = [
    '1. You are very artistic.',
    '2. You are very creative.',
    '3. You find it easy to be outgoing in new social situations.',
    '4. You find it difficult to ask for help.',
    '5. You find it difficult to expose yourself to new situations.',
    '6. You like learning new things.',
    '7. For you, making and maintaining eye contact during conversations is difficult.',
    '8. For you, getting to school/work on time is easy.',
    '9. You have a morning/nighttime routine to get ready for the day/ for bed.',
    '10. For you, defining your goals is easy.',
    '11. For you, transitioning from one activity to another is difficult.',
    '12. You find it is easy to work together in a group.',
    '13. You would describe yourself as a take charge individual.',
    '14. You would describe yourself as an easygoing individual.',
    '15. You care about the big picture .',
    '16. You care about the details.',
    '17. You don’t mind arguing to prove your point.',
    '18. You find it difficult to express your thoughts/opinions to others.',
    '19. You don’t mind being alone.',
    '20. You care a lot about what others think of you.',
    '21. You feel a lot of pressure to succeed.',
    '22. You feel a lot of pressure to make other people happy.',
];

export const AcademicQuestions = [
    '1. Organizing your ideas/ thoughts into words.',
    '2. Organizing your ideas/thoughts into writing.',
    '3. Listening to and following directions.',
    '4. Understanding the main idea of things you read.',
    '5. Using computational skills(math formulas and operations) to solve math problems.',
    '6. Monitoring progress toward completing a goal or task.',
    '7. Setting goals.',
    '8. Reflecting honestly on your performance.',
    '9. Communicating your thoughts and feelings to others.',
    '10. Making friends.',
    '11. Proficiency with technology (computers, software, etc.).',
    '12. Determining reliable sources/information from the internet.',
    '13. Managing your time effectively.',
    '14. Dealing with stressful situations.',
    '15. Asking for the help or resources you need to complete a task.',
    '16. Studying for an exam.',
    '17. Taking tests.',
    '18. Focusing on assignments.',
    '19. Getting to school/work on time.',
    '20. Planning out steps to complete a project.',
    '21. Using a planner to stay organized in school.',
];
