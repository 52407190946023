import React, { useState, useEffect } from 'react';
import { Item, Label, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { getUrl } from '../../../utility/utility';

export const PathwayInfo = (props) => {
    const { pathway } = props;
    return (
        <Segment raised>
            <Label as="a" color="red" ribbon>
                {pathway ? pathway.category : null}
            </Label>
            <span>{pathway ? pathway.name : null}</span>

            <Item.Group divided>
                {pathway && pathway.PathwayContents && pathway.PathwayContents.items
                    ? pathway.PathwayContents.items.map((content, idx) => {
                          return (
                              <Item key={content.content.id}>
                                  <ContentImage
                                      objectKey={
                                          content.content.upload_file
                                              ? content.content.upload_file.key
                                              : null
                                      }
                                  />

                                  <Item.Content>
                                      <Item.Header as="a">
                                          {content.content.name}
                                      </Item.Header>
                                      <Item.Meta>
                                          <span className="cinema">
                                              {'Created: ' +
                                                  new Date(
                                                      content.content.createdAt
                                                  ).toDateString()}
                                          </span>
                                      </Item.Meta>
                                      <Item.Description>
                                          {content.content.description}
                                      </Item.Description>
                                      <Item.Extra>
                                          <Label>{content.content.type}</Label>
                                      </Item.Extra>
                                  </Item.Content>
                              </Item>
                          );
                      })
                    : null}
            </Item.Group>
        </Segment>
    );
};

export default PathwayInfo;

PathwayInfo.propTypes = {
    pathway: PropTypes.shape({
        id: PropTypes.String,
        name: PropTypes.string,
        category: PropTypes.string,
        PathwayContents: PropTypes.object, // Todo: add shape
    }),
};

export const ContentImage = (props) => {
    const { objectKey } = props;
    const [contentUrl, setContentUrl] = useState();
    useEffect(() => {
        if (objectKey) getImageUrl();
    }, [objectKey]);

    const getImageUrl = async (objectKey) => {
        const url = await getUrl(objectKey);
        setContentUrl(url);
    };
    const altUrl = 'https://react.semantic-ui.com/images/wireframe/image-text.png';
    return (
        <Item.Image
            src={contentUrl ? contentUrl : altUrl}
            size="tiny"
            alt="Not Found"
        />
    );
};

ContentImage.propTypes = {
    objectKey: PropTypes.string,
};
