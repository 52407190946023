import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardDescription,
    CardMeta,
    Image,
    Grid,
    Dropdown,
    Icon,
    Button,
} from 'semantic-ui-react';
import { withRouter } from 'react-router';

/**
 * Renders a single student's profile card based on props
 *
 **props.key: student's id
 * props.name: student's name
 * props.grade: student's grade
 * props.postSecondaryGoal: student's post secondary goal
 * props.careerInterest: student's career interest
 * props.iepGoal: a list of student's iep goal (can be null if the student does not have one)
 * props.strengths: the student's strengths
 * props.weaknesses: the student's weaknesses
 * props.interests: the student's interests
 * props.buttons: a component of the buttons depending on what type of profile card is used (either a set of 3, 2 or 0)
 * props.canEditIEP: a boolean that is true if the user can edit the IEP goal, can leave as null if editing IEP is not necessary
 */

class ProfileCard extends Component {
    constructor(props) {
        super(props);
    }

    // Use this handler for the workspace button onClick
    openWorkSpaceHandler = () => {
        this.props.setStudent(this.props.student);
        this.props.setOpenWorkSpace(true);
    };

    render() {
        const profile = this.props.student?.Profile;
        const avatar = `https://avatars.dicebear.com/api/jdenticon/${this.props.student?.id}.svg`;
        const iepGoalOptions = this.props.student.IEPGoals.items.map((goal) => ({
            key: goal.id,
            text: goal.name,
            value: goal.description,
        }));

        // construct a single profile card
        const studentCard = (
            <Card fluid>
                <Card.Content>
                    <Image floated="left" circular size="mini" src={avatar} />
                    <Button
                        basic
                        size="tiny"
                        floated="right"
                        onClick={this.openWorkSpaceHandler}
                    >
                        <Icon name="external alternate" />
                        Workspace
                    </Button>

                    <Card.Header>{this.props.student?.name}</Card.Header>
                    <Card.Meta>{profile?.grade}th grade</Card.Meta>

                    <Card.Description>
                        {iepGoalOptions.length > 0 && (
                            <div>
                                <p>IEP Goal: </p>
                                <Dropdown
                                    fluid
                                    selection
                                    options={iepGoalOptions}
                                    defaultValue={iepGoalOptions[0]?.value}
                                    style={{ fontWeight: 'bold' }}
                                />
                            </div>
                        )}
                    </Card.Description>
                </Card.Content>
                <Card.Content extra>
                    Post Secondary Goal: {profile?.post_secondary}
                </Card.Content>
            </Card>
        );

        return studentCard;
    }
}

ProfileCard.propTypes = {
    /*key: PropTypes.number.isRequired,*/
    setOpenInsight: PropTypes.func,
    name: PropTypes.string,
    grade: PropTypes.number,
    iepGoal: PropTypes.array,
    postSecondaryGoal: PropTypes.string,
    careerInterest: PropTypes.string,
    strengths: PropTypes.string,
    weaknesses: PropTypes.string,
    interests: PropTypes.string,
    studentID: PropTypes.string,
    buttons: PropTypes.element,
    canEditIEP: PropTypes.bool,
    openWorkSpace: PropTypes.bool,
    setOpenWorkSpace: PropTypes.func,
    student: PropTypes.object,
    setStudent: PropTypes.func,
};
export default withRouter(ProfileCard);
