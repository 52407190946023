import React from 'react';
import PropTypes from 'prop-types';
import { Button, Container } from 'semantic-ui-react';

export const TopicSelectionButtons = (props) => {
    const { setOpen, isValid, isSubmitting, dirty, next, disable } = props;

    return (
        <Container fluid textAlign="right" style={{ paddingRight: '1rem' }}>
            <Button
                // disabled={!isValid || isSubmitting || !dirty}
                // content="Assign Pathway"
                // labelPosition={dirty && isValid ? 'right' : null}
                // icon={dirty && isValid ? 'checkmark' : null}
                positive
                onClick={next}
                disabled={disable}
            >
                Next
            </Button>
            <Button color="black" onClick={() => setOpen(false)}>
                Close
            </Button>
        </Container>
    );
};

export default TopicSelectionButtons;

TopicSelectionButtons.propTypes = {
    setOpen: PropTypes.func,
    isValid: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    dirty: PropTypes.bool,
    next: PropTypes.func,
    disable: PropTypes.bool,
};
