/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import ProtectedRoute from './Authentication/ProtectedRoute';
import { SuperPowerSurvey } from '../Molecules/';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    CaseLoadTab,
    ProgressMonitorTab,
    LibraryTab,
    MySchoolTab,
    ProfileTab,
    AssignmentsTab,
    DataInsightTab,
    BadgeTab,
    MessageTab,
    ContentBox,
    Role,
} from '..';
import PropTypes from 'prop-types';
import { redirectUser } from '../../utility/utility';
import { fetchStudent, fetchChild } from '../../store';

const User = (props) => {
    const { authUser, fetchStudent, fetchChild } = props;
    const history = useHistory();
    const [role, setRole] = useState();
    useEffect(() => {
        if (authUser) {
            setRole(authUser.attributes?.['custom:role']);
        }
    }, [authUser]);

    useEffect(() => {
        redirectUser(role, history);
    }, [role]);

    useEffect(() => {
        if (role === Role.STUDENT) {
            // fetch student info and save in casemanager redux state (temporary)
            fetchStudent(authUser?.username);
        } else if (role === Role.PARENT) {
            // fetch children info and save in parent redux state
            fetchChild();
        }
    }, [role]);

    const rightPanes = [BadgeTab, MessageTab];
    let leftPanes;
    switch (role) {
        case Role.CASE_MANAGER:
            leftPanes = [CaseLoadTab, ProgressMonitorTab, LibraryTab, MySchoolTab];
            break;
        case Role.TEACHER:
            leftPanes = [ProgressMonitorTab, LibraryTab, MySchoolTab];
            break;
        case Role.STUDENT:
            leftPanes = [ProfileTab, AssignmentsTab, LibraryTab];
            break;
        case Role.PARENT:
            leftPanes = [DataInsightTab];
            break;
        default:
            leftPanes = [];
    }

    const panes = { leftPanes, rightPanes };
    let display = <Segment loading basic />;
    if (role === Role.STUDENT) {
        // this will check if student submitted Power Survey
        display = <StudentUser panes={panes} />;
    } else if (role == Role.PARENT) {
        display = <ParentUser panes={panes} />;
    } else {
        display = <UserLayout panes={panes} />;
    }
    return display;
};

const mapStateToProps = (state) => ({
    authUser: state.auth.authenticatedUser,
});

export default connect(mapStateToProps, { fetchStudent, fetchChild })(User);

User.propTypes = {
    authUser: PropTypes.object,
};

const UserLayout = ({ panes }) => {
    const { leftPanes, rightPanes } = panes;
    return (
        <Grid columns={2} relaxed stackable>
            <ContentBox
                panes={leftPanes}
                width={11}
                renderActiveOnly={false}
                activeIndex={-1}
            />
            <ContentBox panes={rightPanes} width={5} />
        </Grid>
    );
};

const ParentUser = ({ panes }) => {
    const { leftPanes } = panes;
    return (
        <Grid columns={1} relaxed stackable>
            <ContentBox
                panes={leftPanes}
                width={13}
                renderActiveOnly={false}
                activeIndex={-1}
            />
        </Grid>
    )
}

const StudentUser = connect((state) => ({ student: state.casemanager.student }))(
    ({ student, panes }) => {
        const history = useHistory();
        const [submitted, setSubmitted] = useState(false);

        useEffect(() => {
            if (student?.is_active) {
                setSubmitted(true);
                history.push('/dashboard/profile');
            } else {
                setSubmitted(false);
                history.push('/dashboard/power-survey');
            }
        }, [student]);
        return (
            <div>
                {submitted && <UserLayout panes={panes} />}
                <ProtectedRoute
                    exact
                    path="/dashboard/power-survey"
                    student={student}
                    setSurveySubmitStatus={setSubmitted}
                    component={SuperPowerSurvey}
                />
            </div>
        );
    }
);
