import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Grid, Segment, Button, Header, Icon } from 'semantic-ui-react';
import PathWayWorkSpaceMenu from './PathWayWorkSpaceMenu';
import { updateAssignment } from '../../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { connect } from 'react-redux';
import { contentTypes as types } from '../menuItems';
import NavigateButtons from './NavigateButtons';
import ModuleContent from './ModuleContent';
import BreadCrumb from './BreadCrumb';
import { fetchPathway } from '../../../../store';
import { Role } from '../../../';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import CongratsModal from '../../Modal/CongratsModal';

import CompleteButton from './CompleteButton';
const PathWayWorkSpace = (props) => {
    const { authUser, pathway, fetchPathway, loading } = props;
    const [activeItem, setActiveItem] = useState();
    const [contents, setContents] = useState();
    const [content, setContent] = useState([]);
    const [assignment, setAssignment] = useState();
    const [type, setType] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const modules = new Set([types.SYLLABUS]);
    const history = useHistory();

    const pathwayID =
        history.location &&
        history.location.state &&
        history.location.state.pathwayID &&
        history.location.state.pathwayID;

    useEffect(() => {
        if (
            authUser &&
            authUser.attributes &&
            authUser.attributes['custom:role'] === Role.STUDENT &&
            pathwayID
        ) {
            fetchPathway(pathwayID);
        }
    }, [pathwayID, authUser]);

    useEffect(() => {
        if (pathway && pathway.PathwayContents && pathway.PathwayContents.items) {
            setContents(pathway.PathwayContents.items);
            setContent([pathway]);
        }
    }, [pathway]);

    useEffect(() => {
        if (pathway) {
            switch (activeItem) {
                case types.RESOURCE_LINK:
                    switchContent(types.RESOURCE_LINK);
                    break;
                case types.ARTICLE:
                    switchContent(types.ARTICLE);
                    break;
                case types.PROJECT:
                    switchContent(types.PROJECT);
                    break;
                case types.VIDEO:
                    switchContent(types.VIDEO);
                    break;
                default:
                    switchContent(types.SYLLABUS);
            }
        }
    }, [activeItem]);
    const switchContent = (type) => {
        setType(type);
        const { PathwayContents } = pathway;
        if (type === types.SYLLABUS) {
            setContent([pathway]);
        } else
            setContent(
                PathwayContents.items.filter((item) => item.content.type === type)
            );
    };

    contents &&
        contents.forEach((item) => {
            modules.add(item.content.type);
        });

    useEffect(() => {
        if (
            authUser &&
            pathway &&
            pathway.Assignments &&
            pathway.Assignments.items &&
            pathway.Assignments.items.length !== 0
        ) {
            setAssignment(
                pathway.Assignments.items.find((item) => {
                    // Make sure only one assignment created for a student and a pathway
                    // Check teacherID and casemanagerID for the self-assigned status
                    // Self assigned student starts the pathway on their own
                    return (
                        item.pathwayID === pathway.id &&
                        item.studentID === authUser.username
                    );
                })
            );
        }
    }, [pathway, authUser]);

    const submitAssignmentHandler = async () => {
        if (assignment) {
            try {
                const result = await API.graphql(
                    graphqlOperation(updateAssignment, {
                        input: {
                            id: assignment.id,
                            _version: assignment._version,
                            completedDate: new Date().toISOString(),
                            is_completed: true,
                        },
                    })
                );
                setAssignment(result.data.updateAssignment);
                setOpenModal(true);
            } catch (error) {
                // Todo: (Duc) replace with global alert (not built)
            }
        }
    };

    let display;
    if (
        authUser &&
        authUser.attributes &&
        authUser.attributes['custom:role'] === Role.STUDENT
    ) {
        if (pathway) {
            display = (
                <Grid columns={2} rows={3} stackable>
                    <Grid.Row>
                        <BreadCrumb pathway={pathway} history={history} />
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <Segment.Group>
                                <Segment clearing style={{ padding: '0.5rem' }}>
                                    <Header
                                        as="h4"
                                        floated="left"
                                        style={{
                                            marginBottom: 0,
                                            marginTop: '0.5rem',
                                        }}
                                    >
                                        <Icon name="student" />
                                        <Header.Content>
                                            {pathway.name}
                                        </Header.Content>
                                    </Header>
                                    <NavigateButtons
                                        activeItem={activeItem}
                                        setActiveItem={setActiveItem}
                                        modules={modules}
                                    />
                                    {assignment && (
                                        <Button
                                            primary={!assignment.completedDate}
                                            color={'green'}
                                            size="small"
                                            floated="right"
                                            onClick={submitAssignmentHandler}
                                            disabled={!isCompleted}
                                            loading={loading}
                                        >
                                            {assignment.completedDate
                                                ? 'Submitted'
                                                : 'Submit'}
                                        </Button>
                                    )}
                                </Segment>
                                <Segment loading={loading}>
                                    <ModuleContent
                                        content={content}
                                        assignment={assignment}
                                        type={type}
                                    />
                                </Segment>
                                <Segment clearing>
                                    <CompleteButton
                                        activeItem={activeItem}
                                        setActiveItem={setActiveItem}
                                        modules={modules}
                                        assignment={assignment}
                                        setAssignment={setAssignment}
                                        setIsCompleted={setIsCompleted}
                                        loading={loading}
                                        studentID={authUser?.username}
                                        pathwayID={pathwayID}
                                    />
                                </Segment>
                            </Segment.Group>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <PathWayWorkSpaceMenu
                                modules={modules}
                                activeItem={activeItem}
                                setActiveItem={setActiveItem}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <CongratsModal open={openModal} setOpen={setOpenModal} />
                </Grid>
            );
        } else {
            display = <Segment padded loading style={{ height: '50vh' }} />;
        }
    } else {
        display = <Redirect to="/" />;
    }

    return display;
};

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.authenticatedUser,
        pathway: state.studentUser.pathway,
        loading: state.studentUser.loading,
    };
};
export default connect(mapStateToProps, { fetchPathway })(PathWayWorkSpace);

PathWayWorkSpace.propTypes = {
    authUser: PropTypes.object,
    pathway: PropTypes.object,
    fetchPathway: PropTypes.func,
    loading: PropTypes.bool,
};
