import React from 'react';
import { Tab } from 'semantic-ui-react';
import StudentProfile from '../../Templates/StudentProfile/StudentProfile';
import { Route, NavLink } from 'react-router-dom';
import ProtectedRoute from '../../Templates/Authentication/ProtectedRoute';

/*
    Single Tab object will be combine with other tabs into a pane array.
    The pane will be inserted into ContentBox component as prop
*/

const SPTab = () => (
    <Tab.Pane key="sp">
        <StudentProfile />
    </Tab.Pane>
);
let str = 0;
const StudentProfileTab = {
    menuItem: {
        as: NavLink,
        id: 'sp',
        content: 'Student Profile',
        to: '/student/student-profile',
        exact: true,
        key: 'sp',
    },
    pane: (
        <ProtectedRoute
            key="sp"
            exact
            path="/student/student-profile"
            component={SPTab}
        />
    ),
};

export default StudentProfileTab;
