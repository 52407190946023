import React from 'react';
import { Grid } from 'semantic-ui-react';
import './Footer.css';

/* 
    Sample footer
*/

const Footer = () => {
    return <Grid.Row id="footer-content">© 2021 elpis</Grid.Row>;
};

export default Footer;
