export const FETCH_STUDENTS_START = 'FETCH_STUDENTS_START';
export const FETCH_STUDENTS_FAIL = 'FETCH_STUDENTS_FAIL';
export const FETCH_CASELOAD_STUDENTS_SUCCESS = 'FETCH_CASELOAD_STUDENTS_SUCCESS';
export const FETCH_COLLABORATE_STUDENTS_SUCCESS =
    'FETCH_COLLABORATE_STUDENTS_SUCCESS';
export const FETCH_UNASSIGNED_STUDENTS_SUCCESS = 'FETCH_UNASSIGNED_STUDENTS_SUCCESS';

export const FETCH_STUDENT_START = 'FETCH_STUDENT_START';
export const FETCH_STUDENT_FAIL = 'FETCH_STUDENT_FAIL';
export const FETCH_STUDENT_SUCCESS = 'FETCH_STUDENT_SUCCESS';

export const FETCH_PATHWAYS_CREATED_START = 'FETCH_PATHWAYS_CREATED_START';
export const FETCH_PATHWAYS_CREATED_FAIL = 'FETCH_PATHWAYS_CREATED_FAIL';
export const FETCH_PATHWAYS_CREATED_SUCCESS = 'FETCH_PATHWAYS_CREATED_SUCCESS';

export const FETCH_PATHWAYS_START = 'FETCH_PATHWAYS_START';
export const FETCH_PATHWAYS_FAIL = 'FETCH_PATHWAYS_FAIL';
export const FETCH_PATHWAYS_SUCCESS = 'FETCH_PATHWAYS_SUCCESS';

export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
