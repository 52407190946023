/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { API, graphqlOperation } from 'aws-amplify';
import { updateProgress, createProgress } from '../../../graphql/mutations';
import { scrollToTop } from '../../../utility/utility';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { uploadFile } from '../../../utility/utility';
import { CheckBoxes, TextArea, DropDown, SubmitModal, formData } from '.';
import {
    Segment,
    Form,
    Button,
    Divider,
    Icon,
    Header,
    Message,
    Menu,
    Dropdown as DropdownUI,
    Container,
} from 'semantic-ui-react';

const initialValues = {
    schoolClass: '',
    progressTowardIep: '',
    observation: '',
    attendanceFrequency: '',
    meetDailyObjectives: '',
    improveArea: '',
    academicStrengths: [],
    academicWeaknesses: [],
    successUsedSupport: [],
    extraInput: '',
};

const validationSchema = Yup.object({
    schoolClass: Yup.string().required('Please select a class'),
    progressTowardIep: Yup.string().required(
        'Required. Please choose a progress option'
    ),
    observation: Yup.string().required('Required. Please write your observations'),
    attendanceFrequency: Yup.string(),
    meetDailyObjectives: Yup.string(),
    improveArea: Yup.string(),
    academicStrengths: Yup.array().test({
        message: 'Please select at most two strengths',
        test: (arr) => arr.length < 3,
    }),
    academicWeaknesses: Yup.array().test({
        message: 'Please select at most two weaknesses',
        test: (arr) => arr.length < 3,
    }),
    successUsedSupport: Yup.array(),
    extraInput: Yup.string(),
});

// Todo: Connect Badge Modal to Badge

const ProgressMonitorForm = (props) => {
    const { student } = props;
    const [showInfo, setShowInfo] = useState(true);
    const [goal, setGoal] = useState();
    const [progresses, setProgresses] = useState();
    const [progress, setProgress] = useState();
    const [extraInput, setExtraInput] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const [openModal, setOpenModal] = useState();
    const [alert, setAlert] = useState();

    const name = student.name.replace(/\s+/g, '');
    var fileName = 'ProgressMonitorForm_' + name + '.pdf';

    const onSubmit = async (values, actions) => {
        // pasrse string to boolean for yes/no question
        // if not specify, default to true
        values.attendanceFrequency = values.attendanceFrequency
            ? values.attendanceFrequency === 'Yes'
            : false;
        const S3ObjectOfPDF = await savePDF();
        actions.setSubmitting(false);
        const submitData = transformSubmitData(values, S3ObjectOfPDF);
        // Todo: User can see and edit previous progress form submissions
        // updateStudentProgress(submitData); // will be used to update old form
        createStudentProgress(submitData);
        actions.resetForm();
    };
    const transformSubmitData = (values, S3ObjectOfPDF) => {
        const transformedData = {
            school_class: values.schoolClass,
            progress_toward_iep: values.progressTowardIep,
            observation: values.observation,
            attendance_frequency: values.attendanceFrequency,
            meet_daily_objectives: values.meetDailyObjectives,
            improve_area: values.improveArea,
            extra_input: values.extraInput,
            academic_strengths: values.academicStrengths,
            academic_weaknesses: values.academicWeaknesses,
            success_used_support: values.successUsedSupport,
            iepgoalID: goal.id,
            studentID: student.id,
            progress_monitor_file: S3ObjectOfPDF,
        };
        return transformedData;
    };

    const updateStudentProgress = async (progressData) => {
        try {
            const progress = await API.graphql(
                graphqlOperation(updateProgress, {
                    input: progressData,
                })
            );
            setProgress(progress.data.updateProgress);
            scrollToTop();
            displayAlert(
                {
                    message: `Successfully ${
                        progress.progress_toward_iep ? 'updated' : 'created'
                    } progress monitor.`,
                    status: 'success',
                },
                10000
            );
            setOpenModal(true);
        } catch (e) {
            scrollToTop();
            setAlert(
                {
                    message: `Fail to ${
                        progress.progress_toward_iep ? 'update' : 'create'
                    } progress monitor, please try again later.`,
                    status: 'fail',
                },
                10000
            );
            throw e;
        }
    };

    const createStudentProgress = async (progressData) => {
        try {
            const progress = await API.graphql(
                graphqlOperation(createProgress, {
                    input: progressData,
                })
            );
            setProgress(progress.data.createProgress);
            scrollToTop();
            displayAlert(
                {
                    message: `Successfully created student progress toward IEP
                    goal ${goal.name}`,
                    status: 'success',
                },
                10000
            );
            setOpenModal(true);
        } catch (e) {
            scrollToTop();
            setAlert(
                {
                    message: `Fail to creat student progress toward IEP
                    goal ${goal.name}, please try again later.`,
                    status: 'fail',
                },
                10000
            );
            throw e;
        }
    };

    const savePDF = async () => {
        try {
            const generatedPDF = await generatePDF();
            const generatedFile = new File([generatedPDF], fileName);
            const s3Object = await uploadFile(generatedFile, 'test-folder');
            return s3Object;
        } catch (e) {
            throw e;
        }
    };

    const generatePDF = async () => {
        const input = document.getElementById('divToPrint');

        try {
            const canvas = await html2canvas(input);

            // variables used when sizing the html to PDF, and multiple pages
            var HTML_Width = canvas.width;
            var HTML_Height = canvas.height;
            var top_left_margin = 40;
            var PDF_Width = HTML_Width + top_left_margin * 2;
            var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
            var canvas_image_width = HTML_Width;
            var canvas_image_height = HTML_Height;

            var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('portrait', 'mm', [PDF_Width, PDF_Height]);
            pdf.addImage(
                imgData,
                'PNG',
                top_left_margin,
                top_left_margin,
                canvas_image_width,
                canvas_image_height
            );

            for (var i = 1; i <= totalPDFPages; i++) {
                pdf.addPage([PDF_Width, PDF_Height], 'portrait');
                pdf.addImage(
                    imgData,
                    'PNG',
                    top_left_margin,
                    -(PDF_Height * i) + top_left_margin * 2,
                    canvas_image_width,
                    canvas_image_height
                );
            }
            const pdfOutput = pdf.output('blob', { filename: fileName });
            // pdf.output('datauri');              //opens the data uri in current window

            return pdfOutput;
        } catch (e) {
            throw e;
        }
    };

    const displayAlert = (data, timeOut) => {
        const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        setAlert(data);
        wait(timeOut).then(() => setAlert(null));
    };

    useEffect(() => {
        if (student && student.Progresses) setProgresses(student.Progresses.items);
    }, [progress, student]);

    useEffect(() => {
        if (progress) {
            const {
                school_class,
                academic_strengths,
                progress_toward_iep,
                observation,
                academic_weaknesses,
                attendance_frequency,
                improve_area,
                meet_daily_objectives,
                success_used_support,
            } = progress;

            setFormValues({
                schoolClass: school_class ? school_class : '',
                progressTowardIep: progress_toward_iep ? progress_toward_iep : '',
                observation: observation ? observation : '',
                attendanceFrequency: attendance_frequency ? 'Yes' : 'No',
                meetDailyObjectives: meet_daily_objectives
                    ? meet_daily_objectives
                    : '',
                improveArea: improve_area ? improve_area : '',

                academicStrengths: academic_strengths ? academic_strengths : [],
                academicWeaknesses: academic_weaknesses ? academic_weaknesses : [],
                successUsedSupport: success_used_support ? success_used_support : [],
                extraInput: progress.extra_input ? progress.extra_input : '',
            });
        }
    }, [progress]);

    useEffect(() => {
        if (
            formValues &&
            formValues.successUsedSupport.includes(formData.SOMETHING_ELSE)
        )
            setExtraInput(true);
    });

    const iepList =
        student && student.IEPGoals && student.IEPGoals.items
            ? student.IEPGoals.items.map((goal) => ({
                  key: goal.id,
                  value: JSON.stringify(goal),
                  text: goal.name,
              }))
            : [];

    return (
        <Container id="divToPrint">
            <SubmitModal
                open={openModal}
                setOpen={setOpenModal}
                size="tiny"
                closeIcon
                dimmer="blurring"
            >
                <Header textAlign="center">You are Awesome!</Header>
                <Segment basic textAlign="center">
                    <p>
                        <Icon name="trophy" size="big" color="orange" />
                    </p>
                    <p>Thank you for taking the time to share your observations</p>
                </Segment>
            </SubmitModal>

            <Menu attached="top" stackable>
                <Menu.Item>
                    <Icon name="list" />
                    IEP Goals
                </Menu.Item>

                <DropdownUI
                    inline
                    floating
                    placeholder="Select IEP Goal..."
                    lazyLoad
                    item
                    search
                    selection
                    options={iepList}
                    onChange={(e, data) => setGoal(JSON.parse(data.value))}
                />
            </Menu>
            {goal ? <Segment attached="bottom">{goal.description}</Segment> : null}

            {goal ? (
                <Segment>
                    {alert ? (
                        <Message
                            positive={alert.status === 'success'}
                            negative={alert.status === 'fail'}
                            style={{ width: '100%' }}
                            onDismiss={() => setAlert(null)}
                        >
                            {alert.message}
                        </Message>
                    ) : null}
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        enableReinitialize
                    >
                        {(formikProps) => {
                            return (
                                <Form onSubmit={formikProps.handleSubmit}>
                                    <DropDown
                                        label="Your Class"
                                        name="schoolClass"
                                        placeholder="Select your class..."
                                        options={formData.schoolClasses}
                                        required
                                        multiple={false}
                                    />
                                    <CheckBoxes
                                        label="How would you describe the student's progress toward the IEP goal? "
                                        name="progressTowardIep"
                                        options={formData.progressTowardIEPOptions}
                                        type={formData.RADIO_TYPE}
                                        required
                                    />
                                    <TextArea
                                        label="Student Observations "
                                        name="observation"
                                        placeholder="Write your related observations here... "
                                        type={formData.TEXT_AREA_TYPE}
                                        required
                                    />

                                    <Divider horizontal>
                                        <Header as="h4">
                                            <Icon name="bar chart" />
                                            More Information
                                        </Header>
                                    </Divider>
                                    {showInfo ? (
                                        <Message
                                            info
                                            onDismiss={() => setShowInfo(false)}
                                        >
                                            <Message.Content>
                                                The more information we have, the
                                                more meaningful we can make the IEP -
                                                share whatever you can about the
                                                student&#39;s performance in your
                                                class
                                            </Message.Content>
                                        </Message>
                                    ) : null}

                                    <CheckBoxes
                                        label="1. Does this student attend class regularly? "
                                        name="attendanceFrequency"
                                        options={formData.attendanceFrequencyOptions}
                                        type={formData.RADIO_TYPE}
                                    />

                                    <CheckBoxes
                                        label="2. How often does the student meet daily objectives in class? "
                                        name="meetDailyObjectives"
                                        options={formData.meetDailyObjectivesOptions}
                                        type={formData.RADIO_TYPE}
                                    />

                                    <DropDown
                                        label="3. What are two of the student's academic strengths? "
                                        name="academicStrengths"
                                        placeholder="Select two skill strengths"
                                        options={formData.academicSkills}
                                        multiple={true}
                                    />

                                    <DropDown
                                        label="4. What are two of the student's academic weaknesses? "
                                        name="academicWeaknesses"
                                        placeholder="Select two skill weaknesses"
                                        options={formData.academicSkills}
                                        multiple={true}
                                    />

                                    <TextArea
                                        label="5. What is an area the student should focus on for improvement?"
                                        name="improveArea"
                                        placeholder="Could be specific to your class or in general... "
                                        type={formData.TEXT_AREA_TYPE}
                                    />

                                    <CheckBoxes
                                        label="6. What supports have you used successfully with the student? (check all that apply) "
                                        name="successUsedSupport"
                                        options={formData.successUsedSupportOptions}
                                        type={formData.CHECKBOX_TYPE}
                                        extraInput={extraInput}
                                        setExtraInput={setExtraInput}
                                    />

                                    <TextArea
                                        name="extraInput"
                                        placeholder="Please specify..."
                                        type={formData.TEXT_AREA_TYPE}
                                        style={
                                            extraInput ? null : { display: 'none' }
                                        }
                                    />

                                    <Button
                                        type="submit"
                                        primary
                                        disabled={
                                            !formikProps.isValid ||
                                            formikProps.isSubmitting
                                        }
                                        loading={formikProps.isSubmitting}
                                    >
                                        Submit
                                    </Button>
                                </Form>
                            );
                        }}
                    </Formik>
                </Segment>
            ) : (
                <Segment placeholder>
                    <Header icon size="tiny">
                        <Icon name="file alternate"/>
                        Select IEP Goal to enable form
                    </Header>
                </Segment>
            )}
        </Container>
    );
};
export default ProgressMonitorForm;
ProgressMonitorForm.propTypes = {};
