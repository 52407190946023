/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createType = /* GraphQL */ `
  mutation CreateType(
    $input: CreateTypeInput!
    $condition: ModelTypeConditionInput
  ) {
    createType(input: $input, condition: $condition) {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Contents {
        nextToken
        startedAt
      }
    }
  }
`;
export const updateType = /* GraphQL */ `
  mutation UpdateType(
    $input: UpdateTypeInput!
    $condition: ModelTypeConditionInput
  ) {
    updateType(input: $input, condition: $condition) {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Contents {
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteType = /* GraphQL */ `
  mutation DeleteType(
    $input: DeleteTypeInput!
    $condition: ModelTypeConditionInput
  ) {
    deleteType(input: $input, condition: $condition) {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Contents {
        nextToken
        startedAt
      }
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Pathways {
        nextToken
        startedAt
      }
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Pathways {
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Pathways {
        nextToken
        startedAt
      }
    }
  }
`;
export const createModule = /* GraphQL */ `
  mutation CreateModule(
    $input: CreateModuleInput!
    $condition: ModelModuleConditionInput
  ) {
    createModule(input: $input, condition: $condition) {
      id
      name
      description
      pathwayID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateModule = /* GraphQL */ `
  mutation UpdateModule(
    $input: UpdateModuleInput!
    $condition: ModelModuleConditionInput
  ) {
    updateModule(input: $input, condition: $condition) {
      id
      name
      description
      pathwayID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteModule = /* GraphQL */ `
  mutation DeleteModule(
    $input: DeleteModuleInput!
    $condition: ModelModuleConditionInput
  ) {
    deleteModule(input: $input, condition: $condition) {
      id
      name
      description
      pathwayID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createIEPGoal = /* GraphQL */ `
  mutation CreateIEPGoal(
    $input: CreateIEPGoalInput!
    $condition: ModelIEPGoalConditionInput
  ) {
    createIEPGoal(input: $input, condition: $condition) {
      id
      name
      description
      studentID
      casemanagerID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Progresses {
        nextToken
        startedAt
      }
    }
  }
`;
export const updateIEPGoal = /* GraphQL */ `
  mutation UpdateIEPGoal(
    $input: UpdateIEPGoalInput!
    $condition: ModelIEPGoalConditionInput
  ) {
    updateIEPGoal(input: $input, condition: $condition) {
      id
      name
      description
      studentID
      casemanagerID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Progresses {
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteIEPGoal = /* GraphQL */ `
  mutation DeleteIEPGoal(
    $input: DeleteIEPGoalInput!
    $condition: ModelIEPGoalConditionInput
  ) {
    deleteIEPGoal(input: $input, condition: $condition) {
      id
      name
      description
      studentID
      casemanagerID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Progresses {
        nextToken
        startedAt
      }
    }
  }
`;
export const createPicture = /* GraphQL */ `
  mutation CreatePicture(
    $input: CreatePictureInput!
    $condition: ModelPictureConditionInput
  ) {
    createPicture(input: $input, condition: $condition) {
      id
      name
      owner
      file {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updatePicture = /* GraphQL */ `
  mutation UpdatePicture(
    $input: UpdatePictureInput!
    $condition: ModelPictureConditionInput
  ) {
    updatePicture(input: $input, condition: $condition) {
      id
      name
      owner
      file {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deletePicture = /* GraphQL */ `
  mutation DeletePicture(
    $input: DeletePictureInput!
    $condition: ModelPictureConditionInput
  ) {
    deletePicture(input: $input, condition: $condition) {
      id
      name
      owner
      file {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createStudent = /* GraphQL */ `
  mutation CreateStudent(
    $input: CreateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    createStudent(input: $input, condition: $condition) {
      id
      name
      email
      is_active
      verification_id
      parent_access_code
      schoolID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      IEPGoals {
        nextToken
        startedAt
      }
      Progresses {
        nextToken
        startedAt
      }
      Profile {
        id
        grade
        post_secondary
        interests
        career_interest
        skill_strengths
        skill_weaknesses
        is_completed
        completedDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      Assignments {
        nextToken
        startedAt
      }
      badges {
        nextToken
        startedAt
      }
      parents {
        nextToken
        startedAt
      }
      casemanagers {
        nextToken
        startedAt
      }
      teachers {
        nextToken
        startedAt
      }
    }
  }
`;
export const updateStudent = /* GraphQL */ `
  mutation UpdateStudent(
    $input: UpdateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    updateStudent(input: $input, condition: $condition) {
      id
      name
      email
      is_active
      verification_id
      parent_access_code
      schoolID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      IEPGoals {
        nextToken
        startedAt
      }
      Progresses {
        nextToken
        startedAt
      }
      Profile {
        id
        grade
        post_secondary
        interests
        career_interest
        skill_strengths
        skill_weaknesses
        is_completed
        completedDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      Assignments {
        nextToken
        startedAt
      }
      badges {
        nextToken
        startedAt
      }
      parents {
        nextToken
        startedAt
      }
      casemanagers {
        nextToken
        startedAt
      }
      teachers {
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteStudent = /* GraphQL */ `
  mutation DeleteStudent(
    $input: DeleteStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    deleteStudent(input: $input, condition: $condition) {
      id
      name
      email
      is_active
      verification_id
      parent_access_code
      schoolID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      IEPGoals {
        nextToken
        startedAt
      }
      Progresses {
        nextToken
        startedAt
      }
      Profile {
        id
        grade
        post_secondary
        interests
        career_interest
        skill_strengths
        skill_weaknesses
        is_completed
        completedDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      Assignments {
        nextToken
        startedAt
      }
      badges {
        nextToken
        startedAt
      }
      parents {
        nextToken
        startedAt
      }
      casemanagers {
        nextToken
        startedAt
      }
      teachers {
        nextToken
        startedAt
      }
    }
  }
`;
export const createAdmin = /* GraphQL */ `
  mutation CreateAdmin(
    $input: CreateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    createAdmin(input: $input, condition: $condition) {
      id
      name
      email
      is_active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAdmin = /* GraphQL */ `
  mutation UpdateAdmin(
    $input: UpdateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    updateAdmin(input: $input, condition: $condition) {
      id
      name
      email
      is_active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdmin = /* GraphQL */ `
  mutation DeleteAdmin(
    $input: DeleteAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    deleteAdmin(input: $input, condition: $condition) {
      id
      name
      email
      is_active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createParent = /* GraphQL */ `
  mutation CreateParent(
    $input: CreateParentInput!
    $condition: ModelParentConditionInput
  ) {
    createParent(input: $input, condition: $condition) {
      id
      name
      email
      is_active
      verification_id
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      ParentStudents {
        nextToken
        startedAt
      }
    }
  }
`;
export const updateParent = /* GraphQL */ `
  mutation UpdateParent(
    $input: UpdateParentInput!
    $condition: ModelParentConditionInput
  ) {
    updateParent(input: $input, condition: $condition) {
      id
      name
      email
      is_active
      verification_id
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      ParentStudents {
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteParent = /* GraphQL */ `
  mutation DeleteParent(
    $input: DeleteParentInput!
    $condition: ModelParentConditionInput
  ) {
    deleteParent(input: $input, condition: $condition) {
      id
      name
      email
      is_active
      verification_id
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      ParentStudents {
        nextToken
        startedAt
      }
    }
  }
`;
export const createProgress = /* GraphQL */ `
  mutation CreateProgress(
    $input: CreateProgressInput!
    $condition: ModelProgressConditionInput
  ) {
    createProgress(input: $input, condition: $condition) {
      id
      progress_toward_iep
      observation
      attendance_frequency
      meet_daily_objectives
      academic_strengths
      academic_weaknesses
      success_used_support
      improve_area
      iep_goal
      studentID
      school_class
      extra_input
      iepgoalID
      teacherID
      casemanagerID
      progress_monitor_file {
        bucket
        region
        key
      }
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const updateProgress = /* GraphQL */ `
  mutation UpdateProgress(
    $input: UpdateProgressInput!
    $condition: ModelProgressConditionInput
  ) {
    updateProgress(input: $input, condition: $condition) {
      id
      progress_toward_iep
      observation
      attendance_frequency
      meet_daily_objectives
      academic_strengths
      academic_weaknesses
      success_used_support
      improve_area
      iep_goal
      studentID
      school_class
      extra_input
      iepgoalID
      teacherID
      casemanagerID
      progress_monitor_file {
        bucket
        region
        key
      }
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const deleteProgress = /* GraphQL */ `
  mutation DeleteProgress(
    $input: DeleteProgressInput!
    $condition: ModelProgressConditionInput
  ) {
    deleteProgress(input: $input, condition: $condition) {
      id
      progress_toward_iep
      observation
      attendance_frequency
      meet_daily_objectives
      academic_strengths
      academic_weaknesses
      success_used_support
      improve_area
      iep_goal
      studentID
      school_class
      extra_input
      iepgoalID
      teacherID
      casemanagerID
      progress_monitor_file {
        bucket
        region
        key
      }
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      grade
      post_secondary
      interests
      career_interest
      skill_strengths
      skill_weaknesses
      is_completed
      completedDate
      superpower_survey_file {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      grade
      post_secondary
      interests
      career_interest
      skill_strengths
      skill_weaknesses
      is_completed
      completedDate
      superpower_survey_file {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      grade
      post_secondary
      interests
      career_interest
      skill_strengths
      skill_weaknesses
      is_completed
      completedDate
      superpower_survey_file {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSchool = /* GraphQL */ `
  mutation CreateSchool(
    $input: CreateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    createSchool(input: $input, condition: $condition) {
      id
      name
      address
      code
      domain
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      SchoolStudents {
        nextToken
        startedAt
      }
      SchoolTeachers {
        nextToken
        startedAt
      }
      SchoolCaseManagers {
        nextToken
        startedAt
      }
      Pathways {
        nextToken
        startedAt
      }
    }
  }
`;
export const updateSchool = /* GraphQL */ `
  mutation UpdateSchool(
    $input: UpdateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    updateSchool(input: $input, condition: $condition) {
      id
      name
      address
      code
      domain
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      SchoolStudents {
        nextToken
        startedAt
      }
      SchoolTeachers {
        nextToken
        startedAt
      }
      SchoolCaseManagers {
        nextToken
        startedAt
      }
      Pathways {
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteSchool = /* GraphQL */ `
  mutation DeleteSchool(
    $input: DeleteSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    deleteSchool(input: $input, condition: $condition) {
      id
      name
      address
      code
      domain
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      SchoolStudents {
        nextToken
        startedAt
      }
      SchoolTeachers {
        nextToken
        startedAt
      }
      SchoolCaseManagers {
        nextToken
        startedAt
      }
      Pathways {
        nextToken
        startedAt
      }
    }
  }
`;
export const createTeacher = /* GraphQL */ `
  mutation CreateTeacher(
    $input: CreateTeacherInput!
    $condition: ModelTeacherConditionInput
  ) {
    createTeacher(input: $input, condition: $condition) {
      id
      name
      email
      is_active
      verification_id
      schoolID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Progresses {
        nextToken
        startedAt
      }
      Pathways {
        nextToken
        startedAt
      }
      Assignments {
        nextToken
        startedAt
      }
      badges {
        nextToken
        startedAt
      }
      Students {
        nextToken
        startedAt
      }
    }
  }
`;
export const updateTeacher = /* GraphQL */ `
  mutation UpdateTeacher(
    $input: UpdateTeacherInput!
    $condition: ModelTeacherConditionInput
  ) {
    updateTeacher(input: $input, condition: $condition) {
      id
      name
      email
      is_active
      verification_id
      schoolID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Progresses {
        nextToken
        startedAt
      }
      Pathways {
        nextToken
        startedAt
      }
      Assignments {
        nextToken
        startedAt
      }
      badges {
        nextToken
        startedAt
      }
      Students {
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteTeacher = /* GraphQL */ `
  mutation DeleteTeacher(
    $input: DeleteTeacherInput!
    $condition: ModelTeacherConditionInput
  ) {
    deleteTeacher(input: $input, condition: $condition) {
      id
      name
      email
      is_active
      verification_id
      schoolID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Progresses {
        nextToken
        startedAt
      }
      Pathways {
        nextToken
        startedAt
      }
      Assignments {
        nextToken
        startedAt
      }
      badges {
        nextToken
        startedAt
      }
      Students {
        nextToken
        startedAt
      }
    }
  }
`;
export const createBadge = /* GraphQL */ `
  mutation CreateBadge(
    $input: CreateBadgeInput!
    $condition: ModelBadgeConditionInput
  ) {
    createBadge(input: $input, condition: $condition) {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      BadgeTeachers {
        nextToken
        startedAt
      }
      BadgeCaseManagers {
        nextToken
        startedAt
      }
      BadgeStudents {
        nextToken
        startedAt
      }
    }
  }
`;
export const updateBadge = /* GraphQL */ `
  mutation UpdateBadge(
    $input: UpdateBadgeInput!
    $condition: ModelBadgeConditionInput
  ) {
    updateBadge(input: $input, condition: $condition) {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      BadgeTeachers {
        nextToken
        startedAt
      }
      BadgeCaseManagers {
        nextToken
        startedAt
      }
      BadgeStudents {
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteBadge = /* GraphQL */ `
  mutation DeleteBadge(
    $input: DeleteBadgeInput!
    $condition: ModelBadgeConditionInput
  ) {
    deleteBadge(input: $input, condition: $condition) {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      BadgeTeachers {
        nextToken
        startedAt
      }
      BadgeCaseManagers {
        nextToken
        startedAt
      }
      BadgeStudents {
        nextToken
        startedAt
      }
    }
  }
`;
export const createCaseManager = /* GraphQL */ `
  mutation CreateCaseManager(
    $input: CreateCaseManagerInput!
    $condition: ModelCaseManagerConditionInput
  ) {
    createCaseManager(input: $input, condition: $condition) {
      id
      name
      email
      is_active
      verification_id
      schoolID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      IEPGoals {
        nextToken
        startedAt
      }
      Progresses {
        nextToken
        startedAt
      }
      Pathways {
        nextToken
        startedAt
      }
      Assignments {
        nextToken
        startedAt
      }
      Students {
        nextToken
        startedAt
      }
    }
  }
`;
export const updateCaseManager = /* GraphQL */ `
  mutation UpdateCaseManager(
    $input: UpdateCaseManagerInput!
    $condition: ModelCaseManagerConditionInput
  ) {
    updateCaseManager(input: $input, condition: $condition) {
      id
      name
      email
      is_active
      verification_id
      schoolID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      IEPGoals {
        nextToken
        startedAt
      }
      Progresses {
        nextToken
        startedAt
      }
      Pathways {
        nextToken
        startedAt
      }
      Assignments {
        nextToken
        startedAt
      }
      Students {
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteCaseManager = /* GraphQL */ `
  mutation DeleteCaseManager(
    $input: DeleteCaseManagerInput!
    $condition: ModelCaseManagerConditionInput
  ) {
    deleteCaseManager(input: $input, condition: $condition) {
      id
      name
      email
      is_active
      verification_id
      schoolID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      IEPGoals {
        nextToken
        startedAt
      }
      Progresses {
        nextToken
        startedAt
      }
      Pathways {
        nextToken
        startedAt
      }
      Assignments {
        nextToken
        startedAt
      }
      Students {
        nextToken
        startedAt
      }
    }
  }
`;
export const createPathway = /* GraphQL */ `
  mutation CreatePathway(
    $input: CreatePathwayInput!
    $condition: ModelPathwayConditionInput
  ) {
    createPathway(input: $input, condition: $condition) {
      id
      name
      is_approved
      category
      description
      LearningOutcome
      owner
      schoolID
      categoryID
      teacherID
      casemanagerID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Modules {
        nextToken
        startedAt
      }
      Assignments {
        nextToken
        startedAt
      }
      PathwayContents {
        nextToken
        startedAt
      }
    }
  }
`;
export const updatePathway = /* GraphQL */ `
  mutation UpdatePathway(
    $input: UpdatePathwayInput!
    $condition: ModelPathwayConditionInput
  ) {
    updatePathway(input: $input, condition: $condition) {
      id
      name
      is_approved
      category
      description
      LearningOutcome
      owner
      schoolID
      categoryID
      teacherID
      casemanagerID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Modules {
        nextToken
        startedAt
      }
      Assignments {
        nextToken
        startedAt
      }
      PathwayContents {
        nextToken
        startedAt
      }
    }
  }
`;
export const deletePathway = /* GraphQL */ `
  mutation DeletePathway(
    $input: DeletePathwayInput!
    $condition: ModelPathwayConditionInput
  ) {
    deletePathway(input: $input, condition: $condition) {
      id
      name
      is_approved
      category
      description
      LearningOutcome
      owner
      schoolID
      categoryID
      teacherID
      casemanagerID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Modules {
        nextToken
        startedAt
      }
      Assignments {
        nextToken
        startedAt
      }
      PathwayContents {
        nextToken
        startedAt
      }
    }
  }
`;
export const createContent = /* GraphQL */ `
  mutation CreateContent(
    $input: CreateContentInput!
    $condition: ModelContentConditionInput
  ) {
    createContent(input: $input, condition: $condition) {
      id
      name
      type
      description
      upload_file {
        bucket
        region
        key
      }
      article_input
      resource_link
      typeID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      pathways {
        nextToken
        startedAt
      }
    }
  }
`;
export const updateContent = /* GraphQL */ `
  mutation UpdateContent(
    $input: UpdateContentInput!
    $condition: ModelContentConditionInput
  ) {
    updateContent(input: $input, condition: $condition) {
      id
      name
      type
      description
      upload_file {
        bucket
        region
        key
      }
      article_input
      resource_link
      typeID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      pathways {
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteContent = /* GraphQL */ `
  mutation DeleteContent(
    $input: DeleteContentInput!
    $condition: ModelContentConditionInput
  ) {
    deleteContent(input: $input, condition: $condition) {
      id
      name
      type
      description
      upload_file {
        bucket
        region
        key
      }
      article_input
      resource_link
      typeID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      pathways {
        nextToken
        startedAt
      }
    }
  }
`;
export const createAssignment = /* GraphQL */ `
  mutation CreateAssignment(
    $input: CreateAssignmentInput!
    $condition: ModelAssignmentConditionInput
  ) {
    createAssignment(input: $input, condition: $condition) {
      id
      due_date
      completedDate
      pathwayID
      studentID
      teacherID
      casemanagerID
      work_progress
      is_completed
      completed_modules
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAssignment = /* GraphQL */ `
  mutation UpdateAssignment(
    $input: UpdateAssignmentInput!
    $condition: ModelAssignmentConditionInput
  ) {
    updateAssignment(input: $input, condition: $condition) {
      id
      due_date
      completedDate
      pathwayID
      studentID
      teacherID
      casemanagerID
      work_progress
      is_completed
      completed_modules
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAssignment = /* GraphQL */ `
  mutation DeleteAssignment(
    $input: DeleteAssignmentInput!
    $condition: ModelAssignmentConditionInput
  ) {
    deleteAssignment(input: $input, condition: $condition) {
      id
      due_date
      completedDate
      pathwayID
      studentID
      teacherID
      casemanagerID
      work_progress
      is_completed
      completed_modules
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createPathwayContent = /* GraphQL */ `
  mutation CreatePathwayContent(
    $input: CreatePathwayContentInput!
    $condition: ModelPathwayContentConditionInput
  ) {
    createPathwayContent(input: $input, condition: $condition) {
      id
      pathwayID
      contentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      pathway {
        id
        name
        is_approved
        category
        description
        LearningOutcome
        owner
        schoolID
        categoryID
        teacherID
        casemanagerID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      content {
        id
        name
        type
        description
        article_input
        resource_link
        typeID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updatePathwayContent = /* GraphQL */ `
  mutation UpdatePathwayContent(
    $input: UpdatePathwayContentInput!
    $condition: ModelPathwayContentConditionInput
  ) {
    updatePathwayContent(input: $input, condition: $condition) {
      id
      pathwayID
      contentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      pathway {
        id
        name
        is_approved
        category
        description
        LearningOutcome
        owner
        schoolID
        categoryID
        teacherID
        casemanagerID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      content {
        id
        name
        type
        description
        article_input
        resource_link
        typeID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deletePathwayContent = /* GraphQL */ `
  mutation DeletePathwayContent(
    $input: DeletePathwayContentInput!
    $condition: ModelPathwayContentConditionInput
  ) {
    deletePathwayContent(input: $input, condition: $condition) {
      id
      pathwayID
      contentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      pathway {
        id
        name
        is_approved
        category
        description
        LearningOutcome
        owner
        schoolID
        categoryID
        teacherID
        casemanagerID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      content {
        id
        name
        type
        description
        article_input
        resource_link
        typeID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const createBadgeTeacher = /* GraphQL */ `
  mutation CreateBadgeTeacher(
    $input: CreateBadgeTeacherInput!
    $condition: ModelBadgeTeacherConditionInput
  ) {
    createBadgeTeacher(input: $input, condition: $condition) {
      id
      badgeID
      teacherID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      teacher {
        id
        name
        email
        is_active
        verification_id
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      badge {
        id
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateBadgeTeacher = /* GraphQL */ `
  mutation UpdateBadgeTeacher(
    $input: UpdateBadgeTeacherInput!
    $condition: ModelBadgeTeacherConditionInput
  ) {
    updateBadgeTeacher(input: $input, condition: $condition) {
      id
      badgeID
      teacherID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      teacher {
        id
        name
        email
        is_active
        verification_id
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      badge {
        id
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteBadgeTeacher = /* GraphQL */ `
  mutation DeleteBadgeTeacher(
    $input: DeleteBadgeTeacherInput!
    $condition: ModelBadgeTeacherConditionInput
  ) {
    deleteBadgeTeacher(input: $input, condition: $condition) {
      id
      badgeID
      teacherID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      teacher {
        id
        name
        email
        is_active
        verification_id
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      badge {
        id
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const createBadgeCaseManager = /* GraphQL */ `
  mutation CreateBadgeCaseManager(
    $input: CreateBadgeCaseManagerInput!
    $condition: ModelBadgeCaseManagerConditionInput
  ) {
    createBadgeCaseManager(input: $input, condition: $condition) {
      id
      badgeID
      casemanagerID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      badge {
        id
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      casemanager {
        id
        name
        email
        is_active
        verification_id
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateBadgeCaseManager = /* GraphQL */ `
  mutation UpdateBadgeCaseManager(
    $input: UpdateBadgeCaseManagerInput!
    $condition: ModelBadgeCaseManagerConditionInput
  ) {
    updateBadgeCaseManager(input: $input, condition: $condition) {
      id
      badgeID
      casemanagerID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      badge {
        id
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      casemanager {
        id
        name
        email
        is_active
        verification_id
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteBadgeCaseManager = /* GraphQL */ `
  mutation DeleteBadgeCaseManager(
    $input: DeleteBadgeCaseManagerInput!
    $condition: ModelBadgeCaseManagerConditionInput
  ) {
    deleteBadgeCaseManager(input: $input, condition: $condition) {
      id
      badgeID
      casemanagerID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      badge {
        id
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      casemanager {
        id
        name
        email
        is_active
        verification_id
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const createBadgeStudent = /* GraphQL */ `
  mutation CreateBadgeStudent(
    $input: CreateBadgeStudentInput!
    $condition: ModelBadgeStudentConditionInput
  ) {
    createBadgeStudent(input: $input, condition: $condition) {
      id
      badgeID
      studentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      student {
        id
        name
        email
        is_active
        verification_id
        parent_access_code
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      badge {
        id
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateBadgeStudent = /* GraphQL */ `
  mutation UpdateBadgeStudent(
    $input: UpdateBadgeStudentInput!
    $condition: ModelBadgeStudentConditionInput
  ) {
    updateBadgeStudent(input: $input, condition: $condition) {
      id
      badgeID
      studentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      student {
        id
        name
        email
        is_active
        verification_id
        parent_access_code
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      badge {
        id
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteBadgeStudent = /* GraphQL */ `
  mutation DeleteBadgeStudent(
    $input: DeleteBadgeStudentInput!
    $condition: ModelBadgeStudentConditionInput
  ) {
    deleteBadgeStudent(input: $input, condition: $condition) {
      id
      badgeID
      studentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      student {
        id
        name
        email
        is_active
        verification_id
        parent_access_code
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      badge {
        id
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const createParentStudent = /* GraphQL */ `
  mutation CreateParentStudent(
    $input: CreateParentStudentInput!
    $condition: ModelParentStudentConditionInput
  ) {
    createParentStudent(input: $input, condition: $condition) {
      id
      parentID
      studentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      student {
        id
        name
        email
        is_active
        verification_id
        parent_access_code
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      parent {
        id
        name
        email
        is_active
        verification_id
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateParentStudent = /* GraphQL */ `
  mutation UpdateParentStudent(
    $input: UpdateParentStudentInput!
    $condition: ModelParentStudentConditionInput
  ) {
    updateParentStudent(input: $input, condition: $condition) {
      id
      parentID
      studentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      student {
        id
        name
        email
        is_active
        verification_id
        parent_access_code
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      parent {
        id
        name
        email
        is_active
        verification_id
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteParentStudent = /* GraphQL */ `
  mutation DeleteParentStudent(
    $input: DeleteParentStudentInput!
    $condition: ModelParentStudentConditionInput
  ) {
    deleteParentStudent(input: $input, condition: $condition) {
      id
      parentID
      studentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      student {
        id
        name
        email
        is_active
        verification_id
        parent_access_code
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      parent {
        id
        name
        email
        is_active
        verification_id
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const createCaseManagerStudent = /* GraphQL */ `
  mutation CreateCaseManagerStudent(
    $input: CreateCaseManagerStudentInput!
    $condition: ModelCaseManagerStudentConditionInput
  ) {
    createCaseManagerStudent(input: $input, condition: $condition) {
      id
      casemanagerID
      studentID
      is_collaborator
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      student {
        id
        name
        email
        is_active
        verification_id
        parent_access_code
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      casemanager {
        id
        name
        email
        is_active
        verification_id
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateCaseManagerStudent = /* GraphQL */ `
  mutation UpdateCaseManagerStudent(
    $input: UpdateCaseManagerStudentInput!
    $condition: ModelCaseManagerStudentConditionInput
  ) {
    updateCaseManagerStudent(input: $input, condition: $condition) {
      id
      casemanagerID
      studentID
      is_collaborator
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      student {
        id
        name
        email
        is_active
        verification_id
        parent_access_code
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      casemanager {
        id
        name
        email
        is_active
        verification_id
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteCaseManagerStudent = /* GraphQL */ `
  mutation DeleteCaseManagerStudent(
    $input: DeleteCaseManagerStudentInput!
    $condition: ModelCaseManagerStudentConditionInput
  ) {
    deleteCaseManagerStudent(input: $input, condition: $condition) {
      id
      casemanagerID
      studentID
      is_collaborator
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      student {
        id
        name
        email
        is_active
        verification_id
        parent_access_code
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      casemanager {
        id
        name
        email
        is_active
        verification_id
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const createTeacherStudent = /* GraphQL */ `
  mutation CreateTeacherStudent(
    $input: CreateTeacherStudentInput!
    $condition: ModelTeacherStudentConditionInput
  ) {
    createTeacherStudent(input: $input, condition: $condition) {
      id
      teacherID
      studentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      student {
        id
        name
        email
        is_active
        verification_id
        parent_access_code
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      teacher {
        id
        name
        email
        is_active
        verification_id
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateTeacherStudent = /* GraphQL */ `
  mutation UpdateTeacherStudent(
    $input: UpdateTeacherStudentInput!
    $condition: ModelTeacherStudentConditionInput
  ) {
    updateTeacherStudent(input: $input, condition: $condition) {
      id
      teacherID
      studentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      student {
        id
        name
        email
        is_active
        verification_id
        parent_access_code
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      teacher {
        id
        name
        email
        is_active
        verification_id
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteTeacherStudent = /* GraphQL */ `
  mutation DeleteTeacherStudent(
    $input: DeleteTeacherStudentInput!
    $condition: ModelTeacherStudentConditionInput
  ) {
    deleteTeacherStudent(input: $input, condition: $condition) {
      id
      teacherID
      studentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      student {
        id
        name
        email
        is_active
        verification_id
        parent_access_code
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      teacher {
        id
        name
        email
        is_active
        verification_id
        schoolID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
