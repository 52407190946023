/* eslint-disable react/prop-types */
import React from 'react';
import { StudentInfo } from '..';
import { connect } from 'react-redux';
import StudentProfile from '../../Templates/StudentProfile/StudentProfile';

const StudentProfileTab = (props) => {
    const { student } = props;

    return (
        student && (
            <StudentProfile>
                <StudentInfo student={student} />
            </StudentProfile>
        )
    );
};

const mapStateToProps = (state) => {
    return {
        student: state.casemanager.student,
    };
};

export default connect(mapStateToProps)(StudentProfileTab);
