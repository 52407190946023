import * as actionTypes from './actions/actionTypes';
import { updateObject } from '../../utility/utility';

const initialState = {
    pathway: null,
    pathwaysCompleted: 0,
    error: null,
    loading: false,
    student: {},
    studentID: 'b7293d87-ecb5-455c-9504-212584deae35',
};

const fetchStudent = (state, action) => {
    return updateObject(state, {
        student: action.payload,
    });
};

const fetchPathwaysCompletedSuccess = (state, action) => {
    return updateObject(state, {
        pathwaysCompleted: action.payload,
        loading: false,
        error: null,
    });
};

const fetchPathwaysCompletedStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null,
    });
};

const fetchPathwaysCompletedFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
    });
};

const fetchPathwaySuccess = (state, action) => {
    return updateObject(state, {
        pathway: action.payload,
        loading: false,
        error: null,
    });
};

const fetchPathwayStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null,
    });
};

const fetchPathwayFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PATHWAYS_COMPLETED_SUCCESS:
            return fetchPathwaysCompletedSuccess(state, action);
        case actionTypes.FETCH_PATHWAYS_COMPLETED_START:
            return fetchPathwaysCompletedStart(state, action);
        case actionTypes.FETCH_PATHWAYS_COMPLETED_FAIL:
            return fetchPathwaysCompletedFail(state, action);

        case actionTypes.FETCH_PATHWAY_SUCCESS:
            return fetchPathwaySuccess(state, action);
        case actionTypes.FETCH_PATHWAY_START:
            return fetchPathwayStart(state, action);
        case actionTypes.FETCH_PATHWAY_FAIL:
            return fetchPathwayFail(state, action);

        default: {
            return state;
        }
    }
};

export default reducer;
