import * as actionType from '../actionType';

const initialState = {
    authenticated: false,
    assignments: {},
    strategies: {},
    studentData: {},
    input: {},
    studentName: '',
    studentID: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.AUTHENTICATED: {
            return {
                ...state,
                // change authenticated state here
            };
        }
        // add more states here
        case actionType.UPDATE_ASSIGNMENT: {
            //function added because I could not query to get assignment name.
            return {
                ...state,
                assignments: action.assignment,
            };
        }
        case actionType.SETUP_STUDENT: {
            //setup student
            return {
                ...state,
                studentID: action.studentID,
                studentName: action.studentName,
                assignments: {},
                strategies: {},
                studentData: {},
                input: {},
            };
        }
        case actionType.SET_UP_DATA_INSIGHT: {
            let studentId = action.studentId;
            let progressData = {};
            let strategy = {};
            let completeAssignments = {};
            // get neccessary data for progress graph and strategies.
            const progresses = action.progressList;
            let strategyMap = new Map();
            if (progresses.data.listProgresss.items != null) {
                progresses.data.listProgresss.items.forEach((item) => {
                    if (item.studentID === studentId) {
                        progressData[item.createdAt.substring(0, 10)] = item;
                        if (item.success_used_support != null) {
                            item.success_used_support.forEach((support) => {
                                if (strategyMap.has(support)) {
                                    strategyMap.set(
                                        support,
                                        strategyMap.get(support) + 1
                                    );
                                } else {
                                    strategyMap.set(support, 1);
                                }
                            });
                        }
                    }
                });
            }
            let assignmentList = action.assignmentList;
            assignmentList = assignmentList.data.listAssignments.items;
            for (let item of assignmentList) {
                if (item.studentID === studentId && item.completedDate) {
                    completeAssignments[item.completedDate] = item.pathwayID;
                }
            }
            strategyMap = new Map(
                [...strategyMap.entries()].sort((a, b) => b[1] - a[1])
            );
            strategy = Object.fromEntries(strategyMap);
            return {
                ...state,
                studentData: progressData,
                studentId: studentId,
                strategies: strategy,
                assignments: completeAssignments,
            };
        }
        default:
            return state;
    }
};

export default reducer;
