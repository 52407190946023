import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import * as Yup from 'yup';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { API, graphqlOperation } from 'aws-amplify';
import { createProfile, updateStudent } from '../../../graphql/mutations';
import { uploadFile } from '../../../utility/utility';
import { LikertTable, SubmitSurveyModalContent, surveyData } from '.';
import {
    DropDown,
    TextArea,
    CheckBoxes,
    SubmitModal,
    formData,
} from '../ProgressMonitorForm/index';
import {
    Button,
    Container,
    Form,
    Header,
    Grid,
    Segment,
    Ref,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';

const initialValues = {
    studentID: '',
    grade: '',
    postSecondaryPlan: '',
    careerIndustry: '',
    careerInterest: '',
    academicInterest: '',
    personalInterest1: '',
    personalInterest2: '',
    personalInterest3: '',
    workFuture: {},
    personality: {},
    academic: {},
};

const validationSchema = Yup.object({
    studentID: Yup.number().required('Please insert a valid student ID number'),
    grade: Yup.string().required('Please secelt a grade'),
    postSecondaryOptions: Yup.string().required('Please select an option'),
    careerIndustry: Yup.string().required('Required. Please choose an option'),
    careerInterest: Yup.string(),
    academicInterest: Yup.string().required('Required. Please write down a subject'),
    personalInterest1: Yup.string().required(
        'Required. Please write down one of your interests'
    ),
    personalInterest2: Yup.string().required(
        'Required. Please write down one of your interests'
    ),
    personalInterest3: Yup.string().required(
        'Required. Please write down one of your interests'
    ),
    workFuture: Yup.object().test({
        message: 'Please fill out every statement before submitting.',
        test: (obj) => {
            if (obj != null || obj != undefined) {
                return Object.keys(obj).length == 12;
            } else {
                return false;
            }
        },
    }),
    personality: Yup.object().test({
        message: 'Please fill out every statement before submitting.',
        test: (obj) => {
            if (obj != null || obj != undefined) {
                return Object.keys(obj).length == 22;
            } else {
                return false;
            }
        },
    }),
    academic: Yup.object().test({
        message: 'Please fill out every statement before submitting.',
        test: (obj) => {
            if (obj != null || obj != undefined) {
                return Object.keys(obj).length == 21;
            } else {
                return false;
            }
        },
    }),
});

const SuperPowerSurvey = (props) => {
    const { student, setSurveySubmitStatus } = props;
    const [openModal, setOpenModal] = useState(false);
    const history = useHistory();

    // construct the file name in the form of 'SuperPowerSurvey_StudentName.pdf
    const name = student?.name.replace(/\s+/g, '');
    var fileName = 'SuperPowerSurvey_' + name + '.pdf';

    const industryDescriptionRef = useRef({
        textContent:
            'Click on an industry for a short description and examples careers.',
    });

    const PopUpDescription = (description) => {
        industryDescriptionRef.current = {
            textContent: description,
        };
    };

    const onSubmit = async (values, actions) => {
        const S3ObjectOfPDF = await savePDF();
        const submitData = transformSubmitData(values, S3ObjectOfPDF);

        createStudentProfile(submitData);
        actions.setSubmitting(false);

        setOpenModal(true);
        actions.resetForm();
    };

    const transformSubmitData = (values, S3ObjectOfPDF) => {
        const personalInterests = [
            values.personalInterest1,
            values.personalInterest2,
            values.personalInterest3,
        ];

        const transformedData = {
            grade: values.grade,
            post_secondary: values.postSecondaryOptions,
            career_interest: values.careerIndustry,
            interests: JSON.stringify(personalInterests),
            superpower_survey_file: S3ObjectOfPDF,
        };
        return transformedData;
    };

    const createStudentProfile = async (surveyData) => {
        try {
            const profile = await API.graphql(
                graphqlOperation(createProfile, {
                    input: surveyData,
                })
            );

            updateStudentActive(profile);
        } catch (e) {
            throw e;
        }
    };

    const updateStudentActive = async (newProfile) => {
        const studentDetails = {
            id: student.id,
            is_active: true,
            studentProfileId: newProfile.data.createProfile.id,
            _version: student._version,
        };
        try {
            const activeStudent = await API.graphql(
                graphqlOperation(updateStudent, {
                    input: studentDetails,
                })
            );
        } catch (e) {
            throw e;
        }
    };

    // Convert component to a pdf, then a File, and upload it to the backend
    const savePDF = async () => {
        try {
            const generatedPDF = await generatePDF();
            const generatedFile = new File([generatedPDF], fileName);
            const s3Object = await uploadFile(generatedFile, 'test-folder');
            return s3Object;
        } catch (e) {
            throw e;
        }
    };

    const generatePDF = async () => {
        const input = document.getElementById('divToPrint');

        try {
            const canvas = await html2canvas(input);

            // variables used when sizing the html to PDF, and multiple pages
            var HTML_Width = canvas.width;
            var HTML_Height = canvas.height;
            var top_left_margin = 40;
            var PDF_Width = HTML_Width + top_left_margin * 2;
            var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
            var canvas_image_width = HTML_Width;
            var canvas_image_height = HTML_Height;

            var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('portrait', 'mm', [PDF_Width, PDF_Height]);
            pdf.addImage(
                imgData,
                'PNG',
                top_left_margin,
                top_left_margin,
                canvas_image_width,
                canvas_image_height
            );

            for (var i = 1; i <= totalPDFPages; i++) {
                pdf.addPage([PDF_Width, PDF_Height], 'portrait');
                pdf.addImage(
                    imgData,
                    'PNG',
                    top_left_margin,
                    -(PDF_Height * i) + top_left_margin * 2,
                    canvas_image_width,
                    canvas_image_height
                );
            }
            const pdfOutput = pdf.output('blob', { filename: fileName });

            return pdfOutput;
        } catch (e) {
            throw e;
        }
    };

    // Direct the user out of the survey and make sure they don't come back
    const redirectButtonClickHandler = () => {
        setOpenModal(false);
        history.push('/dashboard/profile');
        setSurveySubmitStatus(true);
    };

    return (
        <Container id="divToPrint">
            <SubmitModal
                open={openModal}
                setOpen={setOpenModal}
                onOpen={() => setOpenModal(open)}
                onClose={() => setOpenModal(false)}
                size="small"
                dimmer="blurring"
                closeOnDimmerClick={false}
                data-html2canvas-ignore="true"
            >
                <SubmitSurveyModalContent
                    openModal={openModal}
                    redirectButtonClick={redirectButtonClickHandler}
                />
            </SubmitModal>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {(formikProps) => {
                    return (
                        <Form onSubmit={formikProps.handleSubmit}>
                            <Header
                                as="h1"
                                textAlign="center"
                                content="What's your Super Power?"
                            />

                            <TextArea
                                label="Student ID Number"
                                name="studentID"
                                placeholder="ex: 1234567"
                                type={formData.TEXT_INPUT_TYPE}
                                required
                            />
                            <DropDown
                                label="What is your grade?"
                                name="grade"
                                options={surveyData.gradeOptions}
                                multiple={false}
                                required
                            />

                            <DropDown
                                label="What is your post-secondary plan?"
                                name="postSecondaryOptions"
                                placeholder="Choose a plan..."
                                options={surveyData.postSecondaryOptions}
                                multiple={false}
                                required
                            />

                            <Grid verticalAlign="middle">
                                <Grid.Column width="8">
                                    <CheckBoxes
                                        label="3. What career industry interests you? Select one option below:"
                                        name="careerIndustry"
                                        options={surveyData.careerIndustryOptions}
                                        type={formData.RADIO_TYPE}
                                        canHover={true}
                                        onMouseOver={PopUpDescription}
                                        required
                                    />
                                </Grid.Column>

                                {/*TODO: make the job description update/rerender on hover instead of click, possibly with useEFfect*/}
                                <Grid.Column
                                    width="8"
                                    data-html2canvas-ignore="true"
                                >
                                    <React.Fragment>
                                        <Ref innerRef={industryDescriptionRef}>
                                            <Segment secondary>
                                                {industryDescriptionRef.current !=
                                                    null &&
                                                    industryDescriptionRef.current
                                                        .textContent}
                                            </Segment>
                                        </Ref>
                                    </React.Fragment>
                                </Grid.Column>
                            </Grid>

                            <TextArea
                                label="What career are you interested in at this point? (If you’re unsure, write “unsure”)"
                                name="careerInterest"
                                placeholder="ex: basketball"
                                type={formData.TEXT_INPUT_TYPE}
                            />

                            <Header
                                as="h2"
                                textAlign="center"
                                content="Personal Strengths"
                            />

                            <TextArea
                                label="What is your favorite subject in school?"
                                name="academicInterest"
                                type={formData.TEXT_INPUT_TYPE}
                                required
                            />

                            <TextArea
                                label="What are your top three interests outside of school?"
                                name="personalInterest1"
                                placeholder="Interest 1"
                                type={formData.TEXT_INPUT_TYPE}
                                required
                            />

                            <TextArea
                                label=" "
                                name="personalInterest2"
                                placeholder="Interest 2"
                                type={formData.TEXT_INPUT_TYPE}
                            />

                            <TextArea
                                label=" "
                                name="personalInterest3"
                                placeholder="Interest 3"
                                type={formData.TEXT_INPUT_TYPE}
                            />

                            <Header
                                as="h2"
                                textAlign="center"
                                content="Work Future"
                                subheader=" Rate the following statements based on how much you
                                agree/disagree with them."
                            />

                            <LikertTable
                                label="Work Future Questions"
                                name="workFuture"
                                scale={surveyData.argreementScale}
                                questions={surveyData.WorkFutureQuestions}
                                formProps={formikProps}
                            />

                            <Header
                                as="h2"
                                textAlign="center"
                                content="Personality"
                                subheader="Rate the following statements based on how much you
                                agree/disagree with them."
                            />
                            <LikertTable
                                label="Personality Questions"
                                name="personality"
                                scale={surveyData.argreementScale}
                                questions={surveyData.PersonalityQuestions}
                                formProps={formikProps}
                            />

                            <Header
                                as="h2"
                                textAlign="center"
                                content="Academic"
                                subheader="Rate yourself from 1-5 in the following skill categories (1 being
                                one of your weaknesses, 5 being one of your strengths)"
                            />
                            <LikertTable
                                label="Academic Questions"
                                name="academic"
                                scale={surveyData.numericalScale}
                                questions={surveyData.AcademicQuestions}
                                formProps={formikProps}
                            />

                            <Button
                                type="submit"
                                primary
                                disabled={
                                    !formikProps.isValid ||
                                    formikProps.isSubmitting ||
                                    !formikProps.dirty
                                }
                                loading={formikProps.isSubmitting}
                                data-html2canvas-ignore="true"
                            >
                                Submit
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
};

export default SuperPowerSurvey;

SuperPowerSurvey.propTypes = {
    student: PropTypes.object,
    history: PropTypes.object,
    setSurveySubmitStatus: PropTypes.func,
};
