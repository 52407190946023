export const getStudent = /* GraphQL */ `
    query GetStudent($id: ID!) {
        getStudent(id: $id) {
            id
            name
            email
            verification_id
            parent_access_code
            is_active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            IEPGoals {
                items {
                    id
                    name
                    description
                    createdAt
                    _version
                    updatedAt
                }
            }
            Progresses {
                items {
                    _version
                    academic_strengths
                    attendance_frequency
                    academic_weaknesses
                    createdAt
                    extra_input
                    id
                    iepgoalID
                    improve_area
                    meet_daily_objectives
                    observation
                    progress_toward_iep
                    school_class
                    success_used_support
                }
                nextToken
                startedAt
            }
            Profile {
                id
                grade
                post_secondary
                interests
                career_interest
                skill_strengths
                skill_weaknesses
                is_completed
                completedDate
                superpower_survey_file {
                    key
                }
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
            }
            Assignments {
                nextToken
                startedAt
            }
            badges {
                nextToken
                startedAt
            }
            parents {
                nextToken
                startedAt
            }
            casemanagers {
                nextToken
                startedAt
            }
            teachers {
                nextToken
                startedAt
            }
        }
    }
`;

export const getParent = /* GraphQL */ `
    query GetParent($id: ID!) {
        getParent(id: $id) {
            id
            name
            email
            is_active
            verification_id
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            ParentStudents {
                items {
                    id
                    studentID
                    parentID
                }
            }
        }
    }
`;
