export const listStudentsWithProfiles = /* GraphQL */ `
    query ListStudents(
        $filter: ModelStudentFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                verification_id
                name
                email
                schoolID
                is_active
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                Profile {
                    id
                    grade
                    post_secondary
                    interests
                    career_interest
                    skill_strengths
                    skill_weaknesses
                }
                casemanagers {
                    items {
                        casemanagerID
                        is_collaborator
                    }
                }
            }
            nextToken
            startedAt
        }
    }
`;
