import React from 'react';
import { Tab } from 'semantic-ui-react';
import { Route, NavLink } from 'react-router-dom';
import StudentsList from '../../Templates/StudentProfileCards/StudentsList';
import ProtectedRoute from '../../Templates/Authentication/ProtectedRoute';
import { Role } from '../..';

/* 
    Single Tab object will be combine with other tabs into a pane array.
    The pane will be inserted into ContentBox component as prop
*/

const CLTab = () => {
    return (
        <Tab.Pane>
            <StudentsList isCollaborator={false} />
        </Tab.Pane>
    );
};
const CaseLoadTab = {
    menuItem: {
        as: NavLink,
        id: 'caseload',
        content: 'Case Load',
        to: '/dashboard/caseload',
        exact: true,
        key: 'cl',
    },
    pane: (
        <ProtectedRoute
            key="cl"
            exact
            path="/dashboard/caseload"
            component={CLTab}
        />
    ),
};
export default CaseLoadTab;
