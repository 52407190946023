import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const BreadcrumbPathway = ({ pathway, history }) => (
    <Breadcrumb
        style={{ paddingLeft: '1.5rem' }}
        icon="right angle"
        sections={[
            {
                key: 'Home',
                content: 'Home',
                link: true,
                onClick: () => {
                    history.goBack();
                },
            },
            {
                key: pathway.id,
                content: pathway.name,
                link: false,
            },
        ]}
    />
);

export default BreadcrumbPathway;
BreadcrumbPathway.propTypes = {
    pathway: PropTypes.object,
    history: PropTypes.object,
};
