import * as actionTypes from './actions/actionTypes';
import { updateObject } from '../../utility/utility';

const initialState = {
    authenticatedUser: null, // Cognito logged-in user information
    isAuthenticated: false, // Authentication status
    userData: null,
    loading: false, // Enter loading state when data is fetching
    error: null, // Error dispatching authenticated redux actions
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // Manage Signup states
        case actionTypes.SIGNUP_SUCCESS:
            return SignUpSuccess(state, action);
        case actionTypes.SIGNUP_START:
            return SignUpStart(state, action);
        case actionTypes.SIGNUP_FAIL:
            return SignUpFail(state, action);

        // Manage Signin states
        case actionTypes.SIGNIN_SUCCESS:
            return signInSuccess(state, action);
        case actionTypes.SIGNIN_START:
            return signInStart(state, action);
        case actionTypes.SIGNIN_FAIL:
            return signInFail(state, action);

        // Manage Signout states
        case actionTypes.SIGNOUT_SUCCESS:
            return signOutSuccess(state, action);
        case actionTypes.SIGNOUT_START:
            return signOutStart(state, action);
        case actionTypes.SIGNOUT_FAIL:
            return signOutFail(state, action);

        
         // Manage Reset Password states
         case actionTypes.RESET_PASSWORD_SUCCESS:
            return resetPasswordSuccess(state, action);
        case actionTypes.RESET_PASSWORD_START:
            return resetPasswordStart(state, action);
        case actionTypes.RESET_PASSWORD_FAIL:
            return resetPasswordFail(state, action);

        default: {
            return state;
        }
    }
};

export default reducer;

// SIGN UP
const SignUpSuccess = (state, action) => {
    return updateObject(state, {
        authenticatedUser: null,
        isAuthenticated: false,
        loading: false,
        error: null,
    });
};
const SignUpStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null,
    });
};
const SignUpFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
    });
};

// SIGN IN
const signInSuccess = (state, action) => {
    return updateObject(state, {
        authenticatedUser: action.payload,
        isAuthenticated: true,
        loading: false,
        error: null,
    });
};
const signInStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null,
    });
};
const signInFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
    });
};

// SIGN OUT
const signOutSuccess = (state, action) => {
    return updateObject(state, {
        authenticatedUser: null,
        isAuthenticated: false,
        loading: false,
        error: null,
    });
};
const signOutStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null,
    });
};
const signOutFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
    });
};


// SIGN IN
const resetPasswordSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
    });
};
const resetPasswordStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null,
    });
};
const resetPasswordFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
    });
};
