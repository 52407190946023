import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, TextArea, Button, Grid } from 'semantic-ui-react';

class Project extends Component {
    render() {
        return (
            <Grid.Column width={5}>
                <Grid.Row style={{ height: '12vh' }} verticalAlign="top" stretched>
                    <h2>Project Title</h2>
                    <Input
                        name="name"
                        onChange={this.props.formProps.handleChange}
                        value={this.props.formProps.values.name || ''}
                        placeholder="Title..."
                        onBlur={this.props.formProps.handleBlur}
                    />
                    <p style={{ color: 'red' }}>
                        {this.props.formProps.errors.name}
                    </p>
                </Grid.Row>
                <Grid.Row style={{ height: '18vh' }} verticalAlign="top" stretched>
                    <h3>Add a Description</h3>
                    <TextArea
                        name="description"
                        onChange={this.props.formProps.handleChange}
                        value={this.props.formProps.values.description || ''}
                        placeholder="Enter a description"
                        style={{ minHeight: 100, minWidth: 800 }}
                    />
                    <p style={{ color: 'red' }}>
                        {this.props.formProps.errors.description}
                    </p>
                </Grid.Row>
                <Grid.Row
                    columns={2}
                    style={{ height: '20vh' }}
                    verticalAlign="top"
                    stretched
                >
                    <Grid.Column>
                        <h3>Upload Assignment</h3>
                        <p>supported extension: .pdf</p>
                        <p>maximum size: 1GB</p>
                        <Input
                            name="file"
                            id="file"
                            type="file"
                            onChange={(event) => {
                                this.props.formProps.setFieldValue(
                                    'file',
                                    event.currentTarget.files[0]
                                );
                            }}
                        />
                        <p style={{ color: 'red' }}>
                            {this.props.formProps.errors.file}
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <h5>You may also upload an example</h5>
                    <p>supported extension: .pdf</p>
                    <p>maximum size: 1GB</p>
                    <Input
                        name="example"
                        id="example"
                        type="file"
                        onChange={(event) => {
                            this.props.formProps.setFieldValue(
                                'example',
                                event.currentTarget.files[0]
                            );
                        }}
                    />
                    <p style={{ color: 'red' }}>
                        {this.props.formProps.errors.example}
                    </p>
                </Grid.Row>
            </Grid.Column>
        );
    }
}

Project.propTypes = {
    formProps: PropTypes.object,
};

export default Project;
