import React, { useState } from 'react';
import { Input, Icon, Segment } from 'semantic-ui-react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
export const SearchBox = (props) => {
    const { searchHandler, placeholder, size, loading } = props;
    const [value, setValue] = useState('');

    const onChange = (e, data) => {
        setValue(data.value);
    };

    const onClick = (e, data) => {
        data.resetForm();
        searchHandler(value);
    };
    const onHitingEnter = (e, data) => {
        data.resetForm();
        if (e.key === 'Enter') searchHandler(value);
    };

    return (
        <Segment basic>
            <Field>
                {({ form }) => (
                    <Input
                        onKeyDown={(e) => onHitingEnter(e, form)}
                        style={{ width: '100%' }}
                        size={size}
                        disabled={loading}
                        icon={
                            <Icon
                                disabled={loading}
                                name="search"
                                inverted={!loading}
                                circular
                                link
                                onClick={(e) => onClick(e, form)}
                            />
                        }
                        placeholder={placeholder}
                        onChange={onChange}
                        value={value}
                    />
                )}
            </Field>
        </Segment>
    );
};

export default SearchBox;

SearchBox.propTypes = {
    searchHandler: PropTypes.func,
    placeholder: PropTypes.string,
    size: PropTypes.string,
    loading: PropTypes.bool,
    formProps: PropTypes.object,
};
