import React from 'react';
import RichTextBox from '../../Atoms/RichTextEditor/RichTextBox';
import PropTypes from 'prop-types';
import { Input, Grid } from 'semantic-ui-react';

export default function Article(props) {
    return (
        <React.Fragment>
            <Grid.Row style={{ height: '12vh' }} verticalAlign="top">
                <h3>Article Title</h3>
                <Input
                    name="name"
                    onChange={props.formProps.handleChange}
                    value={props.formProps.values.name || ''}
                    placeholder="Title..."
                    onBlur={props.formProps.handleBlur}
                    style={{ width: '100%' }}
                />
                <p style={{ color: 'red' }}>{props.formProps.errors.name}</p>
            </Grid.Row>
            <Grid.Row style={{ height: '30vh' }} verticalAlign="top">
                <RichTextBox formProps={props.formProps} />
            </Grid.Row>
            <Grid.Row verticalAlign="bottom">
                <p style={{ color: 'red' }}>{props.formProps.errors.articleInput}</p>
            </Grid.Row>
        </React.Fragment>
    );
}

Article.propTypes = {
    formProps: PropTypes.object,
};
