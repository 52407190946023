import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    Button,
    Form,
    Grid,
    Header,
    Icon,
    Message,
    Segment,
    Transition,
    Item,
} from 'semantic-ui-react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { signUpUser } from '../../../store';
import { redirectUser } from '../../../utility/utility';
import { Role } from '../../';
import Verification from './Verification';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

const initialValues = {
    school: '',
    role: '',
    verificationID: '',
    accessCode: '',
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    error: '',
};

const validationSchema = Yup.object({
    school: Yup.string().required(),
    role: Yup.string().required('Role is required'),
    verificationID: Yup.string().required('ID is required'),
    accessCode: Yup.string().when('role', {
        is: Role.PARENT,
        then: Yup.string().required('Access code is required'),
    }),
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Not a valid email.').required('Email is required'),
    password: Yup.string().required('Password is required.'),
    confirmPassword: Yup.string()
        .required('Comfirm password required.')
        .oneOf([Yup.ref('password'), null], 'Passwords must match.'),
});

const SignupForm = (props) => {
    const { authenticatedUser, signUpUser, loading, authErr } = props;
    const [verified, setVerified] = useState(null);
    const [verifiedUser, setVerifiedUser] = useState();

    const history = useHistory();
    const { role } = useParams();
    const onSubmit = async (values, actions) => {
        // Create a new user in AWS Cognito User Pool
        // Then create and save the user info in DynamoDB
        if (authenticatedUser)
            actions.setFieldError(
                'error',
                'You are logged in. Please logout before creating a new account'
            );
        // Check if any authenticateion error. If not, redirect to welcome page
        else
            try {
                if (role === Role.STUDENT) {
                    const randomCode = uuidv4().split('-').join('');
                    values.accessCode = randomCode.substr(randomCode.length - 6);
                }
                values.verifiedUser = verifiedUser;
                await signUpUser(values);
                history.push('/welcome');
            } catch (err) {
                if (err.message) actions.setFieldError('error', err.message);
            }
    };

    useEffect(() => {
        if (authenticatedUser) {
            redirectUser(authenticatedUser.attributes['custom:role'], history);
        }
    }, [authenticatedUser]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {(formikProps) => {
                const {
                    handleSubmit,
                    handleReset,
                    errors,
                    touched,
                    isValid,
                    dirty,
                    values,
                } = formikProps;

                return (
                    <Grid
                        textAlign="center"
                        style={{ height: '80vh' }}
                        verticalAlign="middle"
                    >
                        <Grid.Column style={{ maxWidth: 450 }}>
                            <Header as="h2" textAlign="center">
                                <Icon name="globe" />{' '}
                                {role?.charAt(0).toUpperCase() + role?.slice(1)}{' '}
                                Registration
                            </Header>
                            <Form
                                size="large"
                                onReset={handleReset}
                                onSubmit={handleSubmit}
                                error
                            >
                                <Segment
                                    stacked
                                    align="left"
                                    color={
                                        isValid && dirty
                                            ? 'green'
                                            : touched && !isValid
                                            ? 'red'
                                            : null
                                    }
                                >
                                    <Field
                                        as={Message}
                                        error
                                        name="error"
                                        content={errors.error}
                                    />

                                    <Verification
                                        {...formikProps}
                                        verified={verified}
                                        setVerified={setVerified}
                                        verifiedUser={verifiedUser}
                                        setVerifiedUser={setVerifiedUser}
                                    />

                                    <Transition
                                        visible={!!values.school && verified}
                                        animation="slide down"
                                        duration={500}
                                    >
                                        <Item>
                                            <Field
                                                name="name"
                                                fluid
                                                icon="user outline"
                                                iconPosition="left"
                                                placeholder="Enter your name"
                                                error={
                                                    touched.name && errors.name
                                                        ? {
                                                              content: errors.name,
                                                              pointing: 'above',
                                                          }
                                                        : null
                                                }
                                                as={Form.Input}
                                            />

                                            <Field
                                                name="password"
                                                fluid
                                                icon="privacy"
                                                iconPosition="left"
                                                placeholder="Password"
                                                type="password"
                                                error={
                                                    touched.password &&
                                                    errors.password
                                                        ? {
                                                              content:
                                                                  errors.password,
                                                              pointing: 'above',
                                                          }
                                                        : null
                                                }
                                                as={Form.Input}
                                            />
                                            <Field
                                                name="confirmPassword"
                                                fluid
                                                icon="privacy"
                                                iconPosition="left"
                                                placeholder="Confirm Password"
                                                type="password"
                                                error={
                                                    touched.confirmPassword &&
                                                    errors.confirmPassword
                                                        ? {
                                                              content:
                                                                  errors.confirmPassword,
                                                              pointing: 'above',
                                                          }
                                                        : null
                                                }
                                                as={Form.Input}
                                            />

                                            <Button
                                                loading={loading}
                                                disabled={loading}
                                                primary
                                                fluid
                                                size="large"
                                                type="submit"
                                            >
                                                Sign Up
                                            </Button>
                                        </Item>
                                    </Transition>
                                </Segment>
                            </Form>
                            <Message>
                                <a href="#" onClick={() => history.push('/login')}>
                                    Already have an account?
                                </a>
                                {' | '}
                                <a
                                    href="#"
                                    onClick={() => history.push('/reset-password')}
                                >
                                    Forgot Password?
                                </a>
                            </Message>
                        </Grid.Column>
                    </Grid>
                );
            }}
        </Formik>
    );
};

const mapStateToProps = (state) => {
    return {
        authenticatedUser: state.auth.authenticatedUser,
        isAuthenticated: state.auth.isAuthenticated,
        loading: state.auth.loading,
        authErr: state.auth.error,
    };
};
export default connect(mapStateToProps, { signUpUser })(SignupForm);

SignupForm.propTypes = {
    authenticatedUser: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    signUpUser: PropTypes.func,
    loading: PropTypes.bool,
    authErr: PropTypes.object,
};
