import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as actionTypes from './actionTypes';
import {
    listStudents,
    listAssignments,
    syncPathwayContents,
} from '../../../graphql/queries';
import * as queries from './queries';

export const fetchTotalPages = (id) => {
    return async (dispatch) => {
        try {
            const filter = {};
            filter.pathwayID = { eq: id };
            const result = await API.graphql(
                graphqlOperation(queries.syncPathwayContents, { filter: filter })
            );
            return {
                type: actionTypes.FETCH_PATHWAY_SUCCESS,
                payload: result,
            };
        } catch (error) {
            return 'error';
        }
    };
};

export const fetchPathwaysCompletedStart = () => {
    return {
        type: actionTypes.FETCH_PATHWAYS_COMPLETED_START,
    };
};
export const fetchPathwaysCompletedFail = (error) => {
    return {
        type: actionTypes.FETCH_PATHWAYS_COMPLETED_FAIL,
        error: error,
    };
};
export const fetchPathwaysCompletedSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_PATHWAYS_COMPLETED_SUCCESS,
        payload: payload,
    };
};
export const fetchPathwaysCompleted = () => {
    return async (dispatch) => {
        await dispatch(fetchPathwaysCompletedStart());
        let ownerEmail = '';
        try {
            // Retrieve current logged in user
            const user = await Auth.currentAuthenticatedUser({
                bypassCache: false,
            });

            ownerEmail = user.attributes.email;
            const filter = {
                email: { eq: ownerEmail },
            };

            const result = await API.graphql({
                query: queries.listStudents,
                variables: { filter: filter },
            });
            const student = result.data.listStudents.items;
            const filter2 = {
                and: [
                    { studentID: { eq: student[0].id } },
                    { completedDate: { ne: null } },
                ],
            };

            const assignmentResult = await API.graphql({
                query: queries.listAssignments,
                variables: { filter: filter2 },
            });
            await dispatch(
                fetchPathwaysCompletedSuccess(
                    assignmentResult.data.listAssignments.items.length
                )
            );
        } catch (error) {
            await dispatch(fetchPathwaysCompletedFail(error));
        }
    };
};

export const fetchPathwayStudentProfile = () => {
    return async (dispatch) => {
        try {
            const filter = {};
            const user = await Auth.currentAuthenticatedUser({
                bypassCache: false,
            });
            filter.studentID = { eq: user.username };
            const result = await API.graphql(
                graphqlOperation(listAssignments, { filter: filter })
            );
            return {
                type: actionTypes.FETCH_PATHWAY_SUCCESS,
                payload: result,
            };
        } catch (error) {
            return 'error';
        }
    };
};

export const fetchPathwayStart = () => {
    return {
        type: actionTypes.FETCH_PATHWAY_START,
    };
};
export const fetchPathwayFail = (error) => {
    return {
        type: actionTypes.FETCH_PATHWAY_FAIL,
        error: error,
    };
};
export const fetchPathwaySuccess = (payload) => {
    return {
        type: actionTypes.FETCH_PATHWAY_SUCCESS,
        payload: payload,
    };
};

export const fetchPathway = (pathwayID) => {
    return async (dispatch) => {
        await dispatch(fetchPathwayStart());
        try {
            const user = await Auth.currentAuthenticatedUser({
                bypassCache: false,
            });
            const filter = { studentID: { eq: user.username } };
            const result = await API.graphql(
                graphqlOperation(queries.getPathway, {
                    id: pathwayID,
                    filter: filter,
                })
            );
            const pathway = result.data.getPathway;
            await dispatch(fetchPathwaySuccess(pathway));
        } catch (error) {
            await dispatch(fetchPathwayFail(error));
        }
    };
};
