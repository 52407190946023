import React, { useState, useEffect } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { Menu, Button, Segment, Sidebar, Icon, Label } from 'semantic-ui-react';
import { Media } from './AppMedia';
import { signOutUser } from '../../../store';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import logo from '../../../static/elpis.png';
import { Role } from '../../';

/**
 * The component display a fixed top navigation bar on large screen
 * devices and a slide side bar on mobile or small screen devices.
 * Logged in user will see protected menu items. Navbar can be change
 * into dark mode by switching darkMode props
 *
 * @param {{
 *  isAuthenticated: string,
 *  authenticatedUser: object
 *  signOutUser: Function
 *  darkMode: boolean
 * }} props
 * @returns Fixed top navigation bar and and sliding side bar
 */
const NavBar = (props) => {
    const { isAuthenticated, authenticatedUser, signOutUser, darkMode } = props;
    const [role, setRole] = useState();
    const [openSidebar, setOpenSidebar] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (authenticatedUser) {
            setRole(authenticatedUser.attributes?.['custom:role']);
        }
    }, [authenticatedUser]);

    const logoutHandler = async () => {
        try {
            await signOutUser();
            // Redirect to index page
            history.push('/login');
        } catch (err) {
            throw new Error(err.message);
        }
    };

    const authButton = (isAuthenticated && authenticatedUser && (
        <Button color="red" onClick={logoutHandler}>
            Log out
        </Button>
    )) || (
        <Button primary onClick={() => history.push('/login')}>
            Log in
        </Button>
    );
    const protectedMenuItems =
        (isAuthenticated && authenticatedUser && (
            <>
                {role === Role.CASE_MANAGER && (
                    <Menu.Item
                        as={NavLink}
                        to={{ pathname: '/dashboard/caseload' }}
                        onClick={() => setOpenSidebar(false)}
                    >
                        Case Load
                    </Menu.Item>
                )}

                {(role === Role.CASE_MANAGER || role === Role.TEACHER) && (
                    <Menu.Item
                        as={NavLink}
                        to={{ pathname: '/dashboard/progress-monitor' }}
                        onClick={() => setOpenSidebar(false)}
                    >
                        Progress Moniter
                    </Menu.Item>
                )}

                {role !== Role.PARENT && (
                    <Menu.Item
                        as={NavLink}
                        to={{ pathname: '/dashboard/library' }}
                        onClick={() => setOpenSidebar(false)}
                    >
                        Library
                    </Menu.Item>
                )}

                {role !== Role.PARENT && role !== Role.STUDENT && (
                    <Menu.Item
                        as={NavLink}
                        to={{ pathname: '/dashboard/my-school' }}
                        onClick={() => setOpenSidebar(false)}
                    >
                        My School
                    </Menu.Item>
                )}
            </>
        )) ||
        null;

    const publicMenuItems = (
        <>
            <Label
                as={Menu.Item}
                onClick={() => history.push('/')}
                style={{
                    fontSize: '1.2rem',
                    fontFamily: 'Georgia',
                }}
            >
                Elpis
            </Label>
            <Menu.Item
                as={NavLink}
                to={{ pathname: '/home' }}
                onClick={() => setOpenSidebar(false)}
            >
                Home
            </Menu.Item>
        </>
    );

    const desktopNavBar = (
        <Segment inverted={darkMode} basic style={{ padding: '0' }}>
            <Menu id="navbar" inverted={darkMode} stackable pointing secondary>
                <Label
                    as={Menu.Item}
                    onClick={() => history.push('/')}
                    style={{
                        fontSize: '1.2rem',
                        fontFamily: 'Georgia',
                        fontWeight: 'bold',
                    }}
                >
                    Elpis
                </Label>
                <Menu.Item position="right">
                    <span style={{ paddingRight: '1rem' }}>
                        {authenticatedUser?.attributes?.['custom:name']}
                    </span>
                    {authButton}
                </Menu.Item>
            </Menu>
        </Segment>
    );

    const mobileNavBar = (
        <Sidebar.Pushable>
            <Sidebar
                as={Menu}
                animation="overlay"
                inverted={darkMode}
                vertical
                visible={openSidebar}
            >
                {publicMenuItems}
                {protectedMenuItems}
            </Sidebar>
            <Sidebar.Pusher
                dimmed={openSidebar}
                onClick={() => (openSidebar ? setOpenSidebar(false) : null)}
                style={{ minHeight: '91vh' }}
            >
                <Segment
                    inverted={darkMode}
                    textAlign="center"
                    vertical
                    basic
                    style={{ padding: 0 }}
                >
                    <Menu inverted={darkMode} pointing secondary size="large">
                        <Menu.Item onClick={() => setOpenSidebar(!openSidebar)}>
                            <Icon name="sidebar" />
                        </Menu.Item>

                        <Menu.Item position="right">{authButton}</Menu.Item>
                    </Menu>
                </Segment>
                {props.children}
            </Sidebar.Pusher>
        </Sidebar.Pushable>
    );

    return (
        <>
            <Media greaterThanOrEqual="computer">{desktopNavBar}</Media>
            <Media lessThan="computer">{mobileNavBar}</Media>
        </>
    );
};

const mapStateToProps = (state) => ({
    authenticatedUser: state.auth.authenticatedUser,
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { signOutUser })(NavBar);

NavBar.propTypes = {
    signOutUser: PropTypes.func,
    authenticatedUser: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    children: PropTypes.object,
    darkMode: PropTypes.bool,
};
