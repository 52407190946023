import React, { useState } from 'react';
import { Grid, Message, Form } from 'semantic-ui-react';
import { API } from 'aws-amplify';
import { createAssignment } from '../../../graphql/mutations';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { DatePicker } from '../../Atoms';
import { PathwayInfo, SearchStudents } from '../../Molecules';
import { ModalButtons as AssignPathwayButtons } from '..';

export const AssignStudentPathwayContent = (props) => {
    const { pathway, setOpen } = props;
    const [alert, setAlert] = useState();
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState();
    const initialValues = {
        students: [],
        pathwayID: '',
        dueDate: '',
    };

    const validationSchema = Yup.object({
        students: Yup.array()
            .min(1, 'Please Select at least 1 student')
            .required('Required'),
        pathwayID: Yup.string(),
        dueDate: Yup.string().required('Required'),
    });

    const searchHandler = async (value) => {
        const listStudentsWithAssignments = /* GraphQL */ `
            query ListStudents(
                $filter: ModelStudentFilterInput
                $limit: Int
                $nextToken: String
            ) {
                listStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
                    items {
                        id
                        verification_id
                        name
                        email
                        is_active
                        _version
                        _deleted
                        _lastChangedAt
                        createdAt
                        updatedAt
                        Assignments {
                            items {
                                id
                                pathwayID
                            }
                        }
                    }
                    nextToken
                    startedAt
                }
            }
        `;
        let filter = {
            name: {
                contains: value,
            },
        };
        setLoading(true);
        // fetch all students filtered by name
        try {
            const data = await API.graphql({
                query: listStudentsWithAssignments,
                variables: { filter: filter },
            });
            // List all students and their assignments
            // in each student's asssignments find the assignment
            // that has this selected pathway ID
            // remove that student from the students list
            // to make sure he/she is not added
            const filteredStudents = data.data.listStudents.items.filter(
                (student) =>
                    !student.Assignments.items.find(
                        (a) => a.pathwayID === pathway.id
                    )
            );

            setStudents(filteredStudents);

            setLoading(false);
        } catch (err) {
            // console.log(err);
        }
    };

    const assignPathway = async (data) => {
        const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        try {
            await API.graphql({
                query: createAssignment,
                variables: { input: data },
            });

            setAlert({
                message: 'Successfully assigned pathway to student',
                status: 'success',
            });
            wait(5000).then(() => setAlert(null));
        } catch (err) {
            setAlert({
                message: 'Fail to assign pathway to student',
                status: 'fail',
            });
            wait(5000).then(() => setAlert(null));
            throw err;
        }
    };

    const onSubmit = async (values, actions) => {
        actions.setSubmitting(false);
        values.pathwayID = pathway.id;

        const data = values.students.map((student) => ({
            studentID: student,
            pathwayID: values.pathwayID,
            due_date: values.dueDate,
        }));
        try {
            for (let item of data) {
                if (item) await assignPathway(item);
            }
        } catch (err) {
            // console.log(err);
        }
        // filter out students has been assigned and remove from search result
        let unassignedStudents = students.filter(
            (student) => !values.students.includes(student.id)
        );
        setStudents(unassignedStudents);
        actions.resetForm();
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {(formProps) => {
                const {
                    handleReset,
                    handleSubmit,
                    isValid,
                    isSubmitting,
                    dirty,
                    setFieldValue,
                } = formProps;
                return (
                    <Form onReset={handleReset} onSubmit={handleSubmit}>
                        {alert ? (
                            <Message
                                positive={alert.status === 'success'}
                                negative={alert.status === 'fail'}
                                style={{ width: '100%' }}
                                onDismiss={() => setAlert(null)}
                            >
                                {alert.message}
                            </Message>
                        ) : null}

                        <Grid columns={2} relaxed stackable divided>
                            <Grid.Row stretched>
                                <Grid.Column>
                                    <SearchStudents
                                        formProps={formProps}
                                        students={students}
                                        loading={loading}
                                        searchHandler={searchHandler}
                                    />
                                </Grid.Column>

                                <Grid.Column verticalAlign="top">
                                    <Grid.Row>
                                        <DatePicker
                                            title="Due Date"
                                            name="dueDate"
                                            setFieldValue={setFieldValue}
                                        />
                                    </Grid.Row>
                                    <Grid.Row>
                                        <PathwayInfo pathway={pathway} />
                                    </Grid.Row>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row columns={1} stretched>
                                <Grid.Column>
                                    <AssignPathwayButtons
                                        setOpen={setOpen}
                                        isValid={isValid}
                                        isSubmitting={isSubmitting}
                                        dirty={dirty}
                                    >
                                        Assign Pathway
                                    </AssignPathwayButtons>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default AssignStudentPathwayContent;

AssignStudentPathwayContent.propTypes = {
    pathway: PropTypes.object,
    setOpen: PropTypes.func,
};
