import React from 'react';
import PropTypes from 'prop-types';

import { Pie } from 'react-chartjs-2';

export default class PieChart extends React.Component {
    render() {
        return (
            <div>
                {/* render a pie chart based on specified data and options */}
                <Pie data={this.props.data} options={this.props.options} />
            </div>
        );
    }
}

PieChart.propTypes = {
    data: PropTypes.shape({
        labels: PropTypes.array.isRequired,
        datasets: PropTypes.array.isRequired,
    }),
    options: PropTypes.shape({}),
};
