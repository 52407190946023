import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchStudents, fetchStudent } from '../../../store/';
import { Card } from 'semantic-ui-react';
import { ProfileCard } from '../../Molecules/';
import { Media } from '../../Organism/NavBar/AppMedia';
// renders case load page
class StudentProfileCards extends Component {
    state = {
        selectedStudent: {},
        isCollaborator: false,
    };

    // when component is mounted
    // fetch CM students
    async componentDidMount() {
        this.setState({
            isCollaborator: this.props.isCollaborator,
        });
        if (this.props.authenticatedUser)
            await this.props.fetchStudents(this.props.isCollaborator);
    }

    async componentDidUpdate(prevProps) {
        if (
            this.props.authenticatedUser &&
            this.props.authenticatedUser !== prevProps.authenticatedUser
        )
            await this.props.fetchStudents(this.props.isCollaborator);
    }

    setStudent = (selectedStudent) => {
        this.setState({ selectedStudent: selectedStudent });
        this.props.fetchStudent(selectedStudent.id);
    };

    render() {
        // construct profile card components based on students global state
        let data = [];
        if (this.props.isCollaborator) {
            if (this.props.collaborateStudents.length > 0)
                data = this.props.collaborateStudents.map((student) =>
                    JSON.parse(JSON.stringify(student))
                );
        } else {
            if (this.props.caseloadStudents.length > 0)
                data = this.props.caseloadStudents.map((student) =>
                    JSON.parse(JSON.stringify(student))
                );
        }
        let students = null;
        if (data.length > 0) {
            students = data.map((student) => {
                let goals = [];
                if ('IEPGoals' in student) {
                    goals = student.IEPGoals.items.map((goal) => ({
                        key: goal.name,
                        text: goal.name,
                        value: goal.name,
                    }));
                }
                return (
                    <ProfileCard
                        key={student.id}
                        student={student}
                        setStudent={this.setStudent}
                        openWorkSpace={this.props.openWorkSpace}
                        setOpenWorkSpace={this.props.setOpenWorkSpace}
                    />
                );
            });
        }

        return (
            <>
                <Media greaterThanOrEqual="computer">
                    <Card.Group itemsPerRow={2}>{students}</Card.Group>
                </Media>
                <Media lessThan="computer">
                    <Card.Group itemsPerRow={1}>{students}</Card.Group>
                </Media>
            </>
        );
    }
}

// get access to desired global states
const mapStateToProps = (state) => {
    return {
        authenticatedUser: state.auth.authenticatedUser,
        caseloadStudents: state.casemanager.caseloadStudents,
        collaborateStudents: state.casemanager.collaborateStudents,
        loading: state.casemanager.loading,
        error: state.casemanager.error,
    };
};

StudentProfileCards.propTypes = {
    caseloadStudents: PropTypes.array,
    collaborateStudents: PropTypes.array,
    fetchStudents: PropTypes.func,
    fetchStudent: PropTypes.func,
    isCollaborator: PropTypes.bool,
    authenticatedUser: PropTypes.object,
    setOpenWorkSpace: PropTypes.func,
    openWorkSpace: PropTypes.bool,
};

export default connect(mapStateToProps, {
    fetchStudents,
    fetchStudent,
})(StudentProfileCards);
