import React, { Component } from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as roleTypes from '../../../store/Auth/roleTypes';

import { fetchPathwaysCreated, fetchPathwaysCompleted } from '../../../store/';

class Badges extends Component {
    async componentDidMount() {
        if (
            (this.props.authenticatedUser &&
                this.props.authenticatedUser.attributes['custom:role'] ===
                    roleTypes.CASE_MANAGER) ||
            (this.props.authenticatedUser &&
                this.props.authenticatedUser.attributes['custom:role'] ===
                    roleTypes.TEACHER)
        ) {
            await this.props.fetchPathwaysCreated();
        } else if (
            this.props.authenticatedUser &&
            this.props.authenticatedUser.attributes['custom:role'] ===
                roleTypes.STUDENT
        ) {
            await this.props.fetchPathwaysCompleted();
        }
    }

    render() {
        let badges = [];

        if (
            (this.props.authenticatedUser &&
                this.props.authenticatedUser.attributes['custom:role'] ===
                    roleTypes.CASE_MANAGER) ||
            (this.props.authenticatedUser &&
                this.props.authenticatedUser.attributes['custom:role'] ===
                    roleTypes.TEACHER)
        ) {
            let pathways = this.props.pathwaysCreated;
            let index = 0;

            while (pathways > 2) {
                badges.push(<Icon key={'badge' + index} name="trophy" size="large" />);
                pathways -= 3;
                index++;
            }
        } else if (
            this.props.authenticatedUser &&
            this.props.authenticatedUser.attributes['custom:role'] ===
                roleTypes.STUDENT
        ) {
            let assignments = this.props.pathwaysCompleted;
            let index = 0;

            while (assignments > 0) {
                badges.push(<Icon key={'badge' + index} name="trophy" size="big" />);
                assignments--;
                index++;
            }
        }

        return <Grid padded>{badges}</Grid>;
    }
}

// get access to desired global states
const mapStateToProps = (state) => {
    return {
        pathwaysCreated: state.casemanager.pathwaysCreated,
        pathwaysCompleted: state.student.pathwaysCompleted,
        authenticatedUser: state.auth.authenticatedUser,
    };
};

Badges.propTypes = {
    pathwaysCreated: PropTypes.number,
    pathwaysCompleted: PropTypes.number,
    fetchPathwaysCreated: PropTypes.func,
    authenticatedUser: PropTypes.object,
    fetchPathwaysCompleted: PropTypes.func,
};

export default connect(mapStateToProps, {
    fetchPathwaysCreated,
    fetchPathwaysCompleted,
})(Badges);
