import React from 'react';
import { Grid } from 'semantic-ui-react';

const StrategyList = (props) => {
    const { progresses } = props;
    let strategyMap = new Map();
    if (progresses) {
        progresses.forEach((item) => {
            if (item.success_used_support != null) {
                item.success_used_support.forEach((support) => {
                    if (strategyMap.has(support)) {
                        strategyMap.set(support, strategyMap.get(support) + 1);
                    } else {
                        strategyMap.set(support, 1);
                    }
                });
            }
        });
    }
    strategyMap = new Map([...strategyMap.entries()].sort((a, b) => b[1] - a[1]));
    let strategies = Object.fromEntries(strategyMap);
    let strategyKey;
    const strategyList = [];
    if (strategies) {
        // console.log('STRATEGY', strategies);
        strategyKey = Object.keys(strategies);
        for (let i = 0; i < Math.min(strategyKey.length, 5); i++) {
            strategyList.push(
                <Grid.Column key={i}>
                    {strategyKey[i] +
                        ' (' +
                        strategies[strategyKey[i]] +
                        '/' +
                        progresses.length +
                        ')'}
                </Grid.Column>
            );
        }
    }
    return strategyList;
};

export default StrategyList;
