import React from 'react';
import { Tab, Grid } from 'semantic-ui-react';
import StudentsList from '../../Templates/StudentProfileCards/StudentsList';
import { Route, NavLink } from 'react-router-dom';
import ProtectedRoute from '../../Templates/Authentication/ProtectedRoute';
import { Role } from '../..';

/*
    Single Tab object will be combine with other tabs into a pane array.
    The pane will be inserted into ContentBox component as prop
*/

export const PMTab = () => (
    <Tab.Pane key="pm">
        <StudentsList isCollaborator={true} />
    </Tab.Pane>
);
const ProgressMonitorTab = {
    menuItem: {
        as: NavLink,
        id: 'pm',
        content: 'Progress Monitor',
        to: '/dashboard/progress-monitor',
        exact: true,
        key: 'pm',
    },
    pane: (
        <ProtectedRoute
            key="pm"
            exact
            path="/dashboard/progress-monitor"
            component={PMTab}
        />
    ),
};
export default ProgressMonitorTab;
