import React from 'react';
import { useHistory } from 'react-router-dom';
import {
    Button,
    Form,
    Grid,
    Header,
    Icon,
    Message,
    Segment,
} from 'semantic-ui-react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { resetPassword } from '../../../store';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const initialValues = {
    email: '',
    error: '',
};

const validationSchema = Yup.object({
    email: Yup.string().email('Not a valid email.').required('Email is required'),
});

const ForgotPassword = (props) => {
    const { resetPassword, loading, authErr } = props;
    const history = useHistory();
    const onSubmit = async (values, actions) => {
        const { email } = values;
        try {
            await resetPassword(email);
            history.push(`/confirm-reset-password/${email}`)
        } catch (err) {
            if (err.message) actions.setFieldError('error', err.message);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {(formikProps) => {
                const {
                    handleSubmit,
                    handleReset,
                    errors,
                    isValid,
                    touched,
                    dirty,
                } = formikProps;

                return (
                    <Grid
                        textAlign="center"
                        style={{ height: '80vh' }}
                        verticalAlign="middle"
                    >
                        <Grid.Column style={{ maxWidth: 450 }}>
                            <Header as="h2" textAlign="center">
                                <Icon name="globe" />Forgot Password
                            </Header>
                            <Form
                                size="large"
                                onReset={handleReset}
                                onSubmit={handleSubmit}
                                error
                            >
                                <Segment
                                    stacked
                                    align="left"
                                    color={
                                        isValid && dirty
                                            ? 'green'
                                            : touched && !isValid
                                            ? 'red'
                                            : null
                                    }
                                >
                                    <Field
                                        as={Message}
                                        error
                                        name="error"
                                        content={errors.error}
                                    />
                                    <Field
                                        type="email"
                                        name="email"
                                        fluid
                                        icon="user"
                                        iconPosition="left"
                                        placeholder="E-mail address"
                                        error={
                                            touched.email && errors.email
                                                ? {
                                                      content: errors.email,
                                                      pointing: 'above',
                                                  }
                                                : null
                                        }
                                        as={Form.Input}
                                    />

                                    <Button
                                        loading={loading}
                                        disabled={loading || !isValid}
                                        primary
                                        fluid
                                        size="large"
                                        type="submit"
                                    >
                                        Send Verification Code
                                    </Button>
                                </Segment>
                            </Form>
                            <Message>
                              
                                <a
                                    href="#"
                                    onClick={() => history.push('/register/student')}
                                >
                                    New student? 
                                </a>
                                {' | '}
                                <a href="#" onClick={() => history.push('/login')}>
                                    Already have an account?
                                </a>
                            </Message>
                        </Grid.Column>
                    </Grid>
                );
            }}
        </Formik>
    );
};

const mapStateToProps = (state) => ({
    authErr: state.auth.error,
    loading: state.auth.loading,
});

export default connect(mapStateToProps, { resetPassword })(ForgotPassword);

ForgotPassword.propTypes = {
    resetPassword: PropTypes.func,
    authErr: PropTypes.object,
    loading: PropTypes.bool,
};
