import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Header, Container, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Role } from '..';
import PropTypes from 'prop-types';
// This page is under developing, will be replaced
const Home = (props) => {
    const { authenticatedUser, isAuthenticated, authErr } = props;
    const history = useHistory();
    const loggedInUserEmail =
        (authenticatedUser && (
            <div>
                <p>
                    Your are logged in as:{' '}
                    <span style={{ color: 'green' }}>
                        {authenticatedUser.attributes.email}
                    </span>
                </p>
                {authenticatedUser.attributes &&
                    authenticatedUser.attributes['custom:role'] === Role.STUDENT && (
                        <Link
                            to={{
                                pathname: '/pathway',
                                state: {
                                    pathwayID:
                                        '4ae6d63d-0fc2-4d0e-9b2b-1682d2322b34',
                                },
                            }}
                        >
                            link to student pathway
                        </Link>
                    )}
            </div>
        )) ||
        null;
    const loginMessage =
        (!authenticatedUser && !isAuthenticated && (
            <p>
                Please{' '}
                <a href="#" onClick={() => history.push('/login')}>
                    Log in
                </a>
            </p>
        )) ||
        null;
    return (
        <Segment basic>
            <Container>
                <Header>
                    <Header.Content>Home Page!</Header.Content>
                    <h3 style={{ color: 'teal' }}>This page is being developed!</h3>
                </Header>
                {loginMessage}
                {loggedInUserEmail}
            </Container>
        </Segment>
    );
};
const mapStateToProps = (state) => ({
    authenticatedUser: state.auth.authenticatedUser,
    isAuthenticated: state.auth.isAuthenticated,
    authErr: state.auth.error,
});
export default connect(mapStateToProps)(Home);
Home.propTypes = {
    authenticatedUser: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    authErr: PropTypes.object,
};
