import React from 'react';
import { Grid, Button, Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as roleTypes from '../../../store/Auth/roleTypes';

import { useHistory } from 'react-router';

const Pathway = (props) => {
    const { setOpenModal, setPathway, pathway, src, id } = props;
    const authenticatedUser = useSelector((state) => state.auth.authenticatedUser);

    const clickedHandler = () => {
        if (
            authenticatedUser &&
            (authenticatedUser.attributes['custom:role'] ===
                roleTypes.CASE_MANAGER ||
                authenticatedUser.attributes['custom:role'] === roleTypes.TEACHER)
        ) {
            openModalHandler();
        } else if (
            authenticatedUser &&
            authenticatedUser.attributes['custom:role'] === roleTypes.STUDENT
        ) {
            openPathway();
        }
    };

    const openModalHandler = () => {
        setPathway(pathway);
        setOpenModal(true);
    };

    const history = useHistory();
    const openPathway = () => {
        history.push({
            pathname: '/pathway',
            state: { pathwayID: id },
        });
    };

    return (
        <Grid.Column>
            <Button style={{ padding: '5px' }} onClick={clickedHandler}>
                <Image src={src} />
            </Button>
        </Grid.Column>
    );
};

export default Pathway;

Pathway.propTypes = {
    src: PropTypes.string,
    setPathway: PropTypes.func,
    pathway: PropTypes.object,
    setOpenModal: PropTypes.func,
    id: PropTypes.string,
};
