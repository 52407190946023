import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as roleTypes from '../../../store/Auth/roleTypes';

import { PathwayModal, Pathway, AddPathwayModal } from '../../Molecules';
import { Grid, Segment, Header, Divider } from 'semantic-ui-react';
import { fetchPathways } from '../../../store';
import AddButton from '../StudentProfileCards/TabButton/TabButton';

class Library extends Component {
    state = {
        openModal: false,
        pathway: null,
        openAddPathwayModal: false,
        mounted: false,
        listTopics: [],
    };

    async componentDidMount() {
        await this.props.fetchPathways();
        this.setState({ mounted: true });
    }

    addPathwayClickedHandler = () => {
        this.setState({ openAddPathwayModal: true });
    };

    modalChangedHandler = (open) => {
        this.setState({ openModal: open });
    };

    addPathwayModalChangedHandler = (open) => {
        this.setState({ openAddPathwayModal: open });
    };

    setPathwayHandler = (pathway) => {
        this.setState({ pathway: pathway });
    };

    render() {
        const pathways = Object.keys(this.props.pathways).map((topic, idx) => (
            <Segment style={{paddingTop: 0}} basic key={idx}>
                <Header>{topic}</Header>
                <Divider />
                <Grid doubling columns={5}>
                    {this.props.pathways[topic].map((pathway) => (
                        // To-do: replace link with {pathway.src}
                        <Pathway
                            src="https://react.semantic-ui.com/images/wireframe/image-text.png"
                            id={pathway.id}
                            key={pathway.id}
                            setOpenModal={(open) => this.modalChangedHandler(open)}
                            setPathway={(pathway) => this.setPathwayHandler(pathway)}
                            pathway={pathway}
                        />
                    ))}
                </Grid>
            </Segment>
        ));

        let addPathwayModal = null;
        if (this.state.mounted) {
            addPathwayModal = (
                <AddPathwayModal
                    open={this.state.openAddPathwayModal}
                    setOpen={(open) => this.addPathwayModalChangedHandler(open)}
                    title="Add Pathway"
                />
            );
        }

        const show =
            this.props.authenticatedUser &&
            (this.props.authenticatedUser.attributes['custom:role'] ===
                roleTypes.CASE_MANAGER ||
                this.props.authenticatedUser.attributes['custom:role'] ===
                    roleTypes.TEACHER);

        return (
            <React.Fragment>
                <Segment basic clearing style={{ padding: 0 }}>
                    <AddButton
                        show={show}
                        color="teal"
                        onClick={this.addPathwayClickedHandler}
                    >
                        Add Pathway
                    </AddButton>
                </Segment>
                <Segment basic style={{ padding: 0 }}>
                    {pathways}
                </Segment>
                {addPathwayModal}
                <PathwayModal
                    open={this.state.openModal}
                    setOpen={(open) => this.modalChangedHandler(open)}
                    pathway={this.state.pathway}
                    title="Assign Students To Pathway"
                />
            </React.Fragment>
        );
    }
}

// get access to desired global states
const mapStateToProps = (state) => {
    return {
        pathways: state.casemanager.allPathways,
        authenticatedUser: state.auth.authenticatedUser,
    };
};

Library.propTypes = {
    pathways: PropTypes.shape({}),
    fetchPathways: PropTypes.func,
    authenticatedUser: PropTypes.object,
};

export default connect(mapStateToProps, { fetchPathways })(Library);
