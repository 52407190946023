import { Button, Container } from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';

const ModalButtons = (props) => {
    const {
        setOpen,
        isValid,
        isSubmitting,
        dirty,
        children,
        closeButton = true,
    } = props;

    return (
        <Container fluid textAlign="right" style={{ paddingRight: '1rem' }}>
            <Button
                type="submit"
                disabled={!isValid || isSubmitting || !dirty}
                labelPosition={dirty && isValid ? 'right' : null}
                icon={dirty && isValid ? 'checkmark' : null}
                positive
                content={children}
            />
            {closeButton && (
                <Button color="black" onClick={() => setOpen(false)}>
                    Close
                </Button>
            )}
        </Container>
    );
};
export default ModalButtons;
ModalButtons.propTypes = {
    setOpen: PropTypes.func,
    isValid: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    dirty: PropTypes.bool,
    children: PropTypes.string,
    closeButton: PropTypes.bool,
};
