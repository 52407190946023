import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { API } from 'aws-amplify';
import { StudentResult } from '../../Molecules/';
import { SearchBox, Loader } from '../../Atoms';

export const SearchStudents = (props) => {
    const { formProps, searchHandler, students, loading } = props;

    const studentsSearchResult = loading ? (
        <Loader />
    ) : (
        <StudentResult students={students} formProps={formProps} />
    );

    return (
        <div>
            <SearchBox
                searchHandler={searchHandler}
                placeholder="Search Students..."
                size="large"
                loading={loading}
            />
            {studentsSearchResult}
        </div>
    );
};

export default SearchStudents;

SearchStudents.propTypes = {
    formProps: PropTypes.object,
    students: PropTypes.array,
    loading: PropTypes.bool,
    searchHandler: PropTypes.func,
};
