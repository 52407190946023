import React from 'react';
import PropTypes from 'prop-types';
import { Button, Container } from 'semantic-ui-react';

export const PathwayNameButtons = (props) => {
    const { setOpen, isValid, isSubmitting, dirty } = props;

    return (
        <Container fluid textAlign="right" style={{ paddingRight: '1rem' }}>
            <Button
                type="submit"
                disabled={!isValid || isSubmitting || !dirty}
                positive
            >
                Add New Module
            </Button>
            <Button color="black" onClick={() => setOpen(false)}>
                Close
            </Button>
        </Container>
    );
};

export default PathwayNameButtons;

PathwayNameButtons.propTypes = {
    setOpen: PropTypes.func,
    isValid: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    dirty: PropTypes.bool,
};
