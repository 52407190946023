import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Message, Form } from 'semantic-ui-react';
import { API } from 'aws-amplify';
import { createCaseManagerStudent } from '../../../graphql/mutations';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { SearchStudents } from '..';
import { ModalButtons as AddStudentButtons } from '..';
import { connect } from 'react-redux';
import { listStudentsWithProfiles } from './queries';
import { fetchStudents } from '../../../store';

const AddStudentContent = (props) => {
    const { setOpen, authUser, fetchStudents } = props;
    const [alert, setAlert] = useState();
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState();
    const initialValues = {
        students: [],
    };

    const validationSchema = Yup.object({
        students: Yup.array()
            .min(1, 'Please Select at least 1 student')
            .required('Required'),
    });

    useEffect(() => {
        fetchStudents(false);
    }, [students]);

    const searchHandler = async (value) => {
        let filter = {
            schoolID: { eq: authUser?.schoolID },
            name: {
                contains: value,
            },

            is_active: {
                eq: true,
            },
        };
        setLoading(true);
        // fetch all students filtered by name
        try {
            const data = await API.graphql({
                query: listStudentsWithProfiles,
                variables: { filter: filter },
            });
        
            const filteredStudents = data.data.listStudents.items.filter(
                (student) =>
                    student.Profile && student.casemanagers.items.length === 0
            );
            setStudents(filteredStudents);
            setLoading(false);
        } catch (err) {
            // console.log(err);
        }
    };

    const addStudentToCaseLoad = async (data) => {
        try {
            await API.graphql({
                query: createCaseManagerStudent,
                variables: { input: data },
            });
        } catch (err) {
            // console.log(err);
        }
    };

    const onSubmit = async (values, actions) => {
        const data = values.students.map((student) => ({
            studentID: student,
            casemanagerID: authUser?.username,
            is_collaborator: false,
        }));
        try {
            for (let item of data) {
                if (item) await addStudentToCaseLoad(item);
            }
        } catch (err) {
            // console.log(err);
        }

        // filter out students has been assigned and remove from search result
        let unassignedStudents = students.filter(
            (student) => !values.students.includes(student.id)
        );
        setStudents(unassignedStudents);
        actions.setSubmitting(false);
        actions.resetForm();
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {(formProps) => {
                const {
                    handleReset,
                    handleSubmit,
                    isValid,
                    isSubmitting,
                    dirty,
                    setFieldValue,
                } = formProps;
                return (
                    <Form onReset={handleReset} onSubmit={handleSubmit}>
                        {alert ? (
                            <Message
                                positive={alert.status === 'success'}
                                negative={alert.status === 'fail'}
                                style={{ width: '100%' }}
                                onDismiss={() => setAlert(null)}
                            >
                                {alert.message}
                            </Message>
                        ) : null}

                        <Grid columns={1} relaxed stackable divided>
                            <Grid.Row stretched>
                                <Grid.Column>
                                    <SearchStudents
                                        formProps={formProps}
                                        students={students}
                                        loading={loading}
                                        searchHandler={searchHandler}
                                    />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row columns={1} stretched>
                                <Grid.Column>
                                    <AddStudentButtons
                                        setOpen={setOpen}
                                        isValid={isValid}
                                        isSubmitting={isSubmitting}
                                        dirty={dirty}
                                    >
                                        Add Student
                                    </AddStudentButtons>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

const mapStateToProps = (state) => ({
    authUser: state.auth.authenticatedUser,
});

export default connect(mapStateToProps, { fetchStudents })(AddStudentContent);

AddStudentContent.propTypes = {
    setOpen: PropTypes.func,
    casemanagerID: PropTypes.string,
    authUser: PropTypes.object,
    fetchStudents: PropTypes.func,
};
