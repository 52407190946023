import React, { useState } from 'react';
import { Button, Container } from 'semantic-ui-react';
import { Role } from '../../';
import PropTypes from 'prop-types';
const RoleSwitch = (props) => {
    const { role, setRole, setCollaborators, formProps } = props;
    const handleClick = () => {
        setCollaborators([]);
        formProps.resetForm();
        if (role === Role.CASE_MANAGER) setRole(Role.TEACHER);
        else setRole(Role.CASE_MANAGER);
    };
    return (
        <Container textAlign="center">
            <Button
                toggle
                type="button"
                active={role === Role.CASE_MANAGER}
                onClick={handleClick}
            >
                Case Manager
            </Button>
            <Button
                toggle
                type="button"
                active={role === Role.TEACHER}
                onClick={handleClick}
            >
                Teacher
            </Button>
        </Container>
    );
};

export default RoleSwitch;
RoleSwitch.propTypes = {
    role: PropTypes.string,
    setRole: PropTypes.func,
    setCollaborators: PropTypes.func,
    formProps: PropTypes.object,
};
