/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
    Divider,
    Grid,
    Header,
    Icon,
    Segment,
    Button,
    Popup,
} from 'semantic-ui-react';
import { StudentInfo, ProgressMonitorForm, AddIEPGoal } from '..';
import { BreadCrumb, WorkSpaceMenu } from '.';
import { DataInsights } from '../../Templates';
import { connect } from 'react-redux';
import { workspace } from './menuItems';
import { CollaborationContent } from '../../Molecules';
import DataLog from '../../Templates/DataLog';

const WorkSpace = (props) => {
    const { student, studentID, openWorkSpace, setOpenWorkSpace, loading, ...rest } =
        props;
    const [activeItem, setActiveItem] = useState(workspace.PROGRESS_MONITOR);

    const openProgressMonitor = student && activeItem === workspace.PROGRESS_MONITOR;
    const openInsight = student && activeItem === workspace.INSIGHTS;
    const openCollaborate = student && activeItem === workspace.COLLABORATE;
    const openDataLog = student && activeItem === workspace.DATA_LOG;

    const studentInfo = student ? (
        <Grid.Row>
            <StudentInfo
                student={student}
                showStudentInfo
                showPowerSurveyInfo
                showParentCode
            />
        </Grid.Row>
    ) : null;

    const addGoals = openProgressMonitor ? (
        <Grid.Row>
            <Divider horizontal>
                <Header as="h4">
                    <Icon name="plus square outline" />
                    Add IEP Goals
                </Header>
            </Divider>
            <Grid.Row>
                <AddIEPGoal student={student} />
            </Grid.Row>
        </Grid.Row>
    ) : null;

    const progressForm = openProgressMonitor ? (
        <Grid.Row>
            <Divider horizontal>
                <Header as="h4">
                    <Icon name="edit outline" />
                    Progress Monitor Form
                </Header>
            </Divider>
            <Grid.Row>
                <ProgressMonitorForm student={student} />
            </Grid.Row>
        </Grid.Row>
    ) : null;

    const dataInsights = openInsight ? (
        <Grid.Row>
            <Divider horizontal>
                <Header as="h4">
                    <Icon name="edit outline" />
                    Data Insights
                </Header>
            </Divider>
            <Grid.Row>
                <DataInsights student={student} />
            </Grid.Row>
        </Grid.Row>
    ) : null;
    const breadCrumb = student && (
        <BreadCrumb
            student={student}
            setOpenWorkSpace={setOpenWorkSpace}
            {...rest}
        />
    );

    const collaboration = openCollaborate && (
        <CollaborationContent student={student} open={true} setOpen={() => {}} />
    );

    const dataLog =
        student && openDataLog ? (
            <Grid.Row>
                <Divider horizontal>
                    <Header as="h4">
                        <Icon name="edit outline" />
                        Data Log
                    </Header>
                </Divider>
                <Grid.Row>
                    <DataLog studentID={studentID} />
                </Grid.Row>
            </Grid.Row>
        ) : null;

    return (
        openWorkSpace &&
        (student && !loading ? (
            <Grid columns={2} stackable>
                <Grid.Row>
                    <Grid.Column>{breadCrumb}</Grid.Column>
                    <Grid.Column textAlign="right">
                        <Popup
                            content={`Close ${student.name} workspace`}
                            mouseEnterDelay={600}
                            mouseLeaveDelay={500}
                            on="hover"
                            trigger={
                                <Button
                                    basic
                                    icon
                                    circular
                                    size="tiny"
                                    onClick={() => setOpenWorkSpace(false)}
                                >
                                    <Icon name="close" />
                                </Button>
                            }
                            style = {{
                                borderRadius: '5px',
                                color: 'grey'
                                
                              }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <WorkSpaceMenu
                            activeItem={activeItem}
                            setActiveItem={setActiveItem}
                            {...rest}
                        />
                    </Grid.Column>
                    <Grid.Column width={12}>
                        {studentInfo}
                        {addGoals}
                        {progressForm}
                        {dataInsights}
                        {collaboration}
                        {dataLog}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        ) : (
            <Segment padded loading style={{ height: '20rem' }} />
        ))
    );
};

const mapStateToProps = (state) => {
    return {
        role: state.auth.authenticatedUser?.attributes?.['custom:role'],
        student: state.casemanager.student,
        loading: state.casemanager.loading,
        error: state.casemanager.error,
    };
};
export default connect(mapStateToProps)(WorkSpace);
