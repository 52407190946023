import React from 'react';
import { Tab } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import DataInsight from '../../Templates/DataInsight/DataInsight';
import ProtectedRoute from '../../Templates/Authentication/ProtectedRoute';
import { Role } from '../..';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/*
    Single Tab object will be combine with other tabs into a pane array.
    The pane will be inserted into ContentBox component as prop
*/

//TODO: make sure the student prop is gets into data insights, when i have a connection
const mapStateToProps = (state) => ({
    student: state.parent.student,
});
const DITab = connect(mapStateToProps)(({ student }) => {
    return (
        <Tab.Pane key="di">{student && <DataInsight student={student} />}</Tab.Pane>
    );
});

const DataInsightTab = {
    menuItem: {
        as: NavLink,
        id: 'di',
        content: 'Data Insights',
        to: '/dashboard/data-insights',
        exact: true,
        key: 'di',
    },
    pane: (
        <ProtectedRoute
            key="di"
            exact
            path="/dashboard/data-insights"
            component={DITab}
        />
    ),
};

export default DataInsightTab;

DITab.propTypes = {
    student: PropTypes.object,
};
