import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export default function ContentTypeDropdown(props) {
    const options = [];

    for (let i = 0; i < props.optionsList.length; i++) {
        options.push({
            key: props.optionsList[i],
            value: props.optionsList[i],
            text: props.optionsList[i],
        });
    }

    return (
        <Dropdown
            placeholder="Select Content Type"
            fluid
            search
            selection
            onChange={(e, { value }) => props.setValue(value)}
            options={options}
        />
    );
}

ContentTypeDropdown.propTypes = {
    optionsList: PropTypes.array,
    setValue: PropTypes.func,
};
