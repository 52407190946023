import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Button,
    Form,
    Grid,
    Header,
    Icon,
    Message,
    Segment,
} from 'semantic-ui-react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { signInUser } from '../../../store';
import { connect } from 'react-redux';
import { redirectUser } from '../../../utility/utility';
import PropTypes from 'prop-types';

const initialValues = {
    email: '',
    password: '',
    error: '',
    message: '',
};

const validationSchema = Yup.object({
    email: Yup.string().email('Not a valid email.').required('Email is required'),
    password: Yup.string().required('Password is required.'),
});

const LoginForm = (props) => {
    const { signInUser, loading, authenticatedUser, authErr } = props;
    const history = useHistory();

    if (
        history.location?.state?.action === 'reset-password' &&
        history.location?.state?.status === 'success'
    )
        initialValues.message = history.location?.state?.message;

    const onSubmit = async (values, actions) => {
        const { email, password } = values;
        try {
            if (authenticatedUser)
                actions.setFieldError('error', 'User is already logged in!');
            else {
                // get authenticatedUser after signing in
                const user = await signInUser(email, password);

                // Redirct to /dashboard
                redirectUser(user.attributes['custom:role'], history);
            }
        } catch (err) {
            if (err.code === 'UserNotConfirmedException')
                setTimeout(() => history.push('/confirm-signup'), 3000);
            if (err.message) actions.setFieldError('error', err.message);
        }
    };

    useEffect(() => {
        if (authenticatedUser) {
            redirectUser(authenticatedUser.attributes['custom:role'], history);
        } else {
            (async function () {
                const user = await signInUser();
                // console.log(user);
            })();
        }
    }, [authenticatedUser]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {(formikProps) => {
                const {
                    handleSubmit,
                    handleReset,
                    errors,
                    isValid,
                    touched,
                    dirty,
                    values,
                } = formikProps;

                return (
                    <Grid
                        textAlign="center"
                        style={{ height: '80vh' }}
                        verticalAlign="middle"
                    >
                        <Grid.Column style={{ maxWidth: 450 }}>
                            <Header as="h2" textAlign="center">
                                <Icon name="globe" /> Log-in to your account
                            </Header>
                            <Message
                                as={Message}
                                success
                                name="message"
                                content={values.message}
                                style={{ display: !values.message && 'none' }}
                            />
                            <Form
                                size="large"
                                onReset={handleReset}
                                onSubmit={handleSubmit}
                                error
                            >
                                <Segment
                                    stacked
                                    align="left"
                                    color={
                                        isValid && dirty
                                            ? 'green'
                                            : touched && !isValid
                                            ? 'red'
                                            : null
                                    }
                                >
                                    <Field
                                        as={Message}
                                        error
                                        name="error"
                                        content={errors.error}
                                    />

                                    <Field
                                        type="email"
                                        name="email"
                                        fluid
                                        icon="user"
                                        iconPosition="left"
                                        placeholder="E-mail address"
                                        error={
                                            touched.email && errors.email
                                                ? {
                                                      content: errors.email,
                                                      pointing: 'above',
                                                  }
                                                : null
                                        }
                                        as={Form.Input}
                                    />
                                    <Field
                                        name="password"
                                        fluid
                                        icon="lock"
                                        iconPosition="left"
                                        placeholder="Password"
                                        type="password"
                                        error={
                                            touched.password && errors.password
                                                ? {
                                                      content: errors.password,
                                                      pointing: 'above',
                                                  }
                                                : null
                                        }
                                        as={Form.Input}
                                    />

                                    <Button
                                        loading={loading}
                                        disabled={loading}
                                        primary
                                        fluid
                                        size="large"
                                        type="submit"
                                    >
                                        Login
                                    </Button>
                                </Segment>
                            </Form>
                            <Message>
                                <a
                                    href="#"
                                    onClick={() => history.push('/register/student')}
                                >
                                    New student?
                                </a>
                                {' | '}
                                <a
                                    href="#"
                                    onClick={() => history.push('/reset-password')}
                                >
                                    Forgot Password?
                                </a>
                            </Message>
                        </Grid.Column>
                    </Grid>
                );
            }}
        </Formik>
    );
};

const mapStateToProps = (state) => ({
    authErr: state.auth.error,
    loading: state.auth.loading,
    authenticatedUser: state.auth.authenticatedUser,
});

export default connect(mapStateToProps, { signInUser })(LoginForm);

LoginForm.propTypes = {
    signInUser: PropTypes.func,
    authErr: PropTypes.object,
    loading: PropTypes.bool,
    authenticatedUser: PropTypes.object,
};
