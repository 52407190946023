import React from 'react';
import { Tab, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Route, NavLink, Switch } from 'react-router-dom';
/* 
   ContentBox component include all combined tabs.
   All required tabs will be combined to a pane array and pass in as prop
*/

const ContentBox = (props) => {
    const { panes, width, ...rest } = props;
    // Todo (Duc): need responsive tabs on small screen device
    // Use flex-wrap...
    return (
        <Grid.Column width={width}>
            <Tab
                menu={{ fluid: true, attached: true, tabular: true }}
                panes={panes}
                {...rest}
            />
        </Grid.Column>
    );
};

ContentBox.propTypes = {
    width: PropTypes.number,
    panes: PropTypes.array,
};

export default ContentBox;
