import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import sanitizeHtml from 'sanitize-html';

import { Grid, Form } from 'semantic-ui-react';
import { NewModuleButtons } from '..';
import ContentTypeDropdown from '../../Atoms/ContentTypeDropdown/ContentTypeDropdown';
import Video from '../../Atoms/ModuleContentTypes/Video';
import Article from '../../Atoms/ModuleContentTypes/Article';
import ResourceLink from '../../Atoms/ModuleContentTypes/ResourceLink';
import Project from '../../Atoms/ModuleContentTypes/Project';

class NewModule extends Component {
    state = {
        type: '',
        contentOptions: ['Video', 'Article', 'Resource Link', 'Project'],
        next: false,
        complete: false,
        error: '',
    };

    typeChangedHandler = (value) => {
        this.setState({ type: value });
    };

    newModuleClickedHandler = () => {
        this.setState({ next: true });
    };

    completeClickedHandler = () => {
        this.setState({ complete: true });
    };

    initialValues = {
        type: '',
        name: '',
        description: '',
        articleInput: '',
        link: '',
        file: null,
        example: null,
    };

    videoFormats = ['video/mp4', 'video/quicktime'];

    fileFormats = ['application/pdf', 'image/png', 'image/jpeg'];

    moduleSchema = () => {
        if (this.state.type === 'Resource Link') {
            return Yup.object({
                name: Yup.string().min(1).required('Required'),
                description: Yup.string().min(1).required('Required'),
                link: Yup.string()
                    .matches(
                        /(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                        'Invalid URL'
                    )
                    .required('Required'),
            });
        } else if (this.state.type === 'Article') {
            return Yup.object({
                name: Yup.string().required('Required'),
                articleInput: Yup.string().required('Required'),
            });
        } else if (this.state.type === 'Video') {
            return Yup.object().shape({
                name: Yup.string().required('Required'),
                description: Yup.string().min(1).required('Required'),
                file: Yup.mixed()
                    .required('Video required')
                    .test(
                        'fileSize',
                        'File too large',
                        (value) => value && value.size <= 1000000000 // 1GB
                    )
                    .test(
                        'fileFormat',
                        'Unsupported Format',
                        (value) => value && this.videoFormats.includes(value.type)
                    ),
            });
        } else {
            return Yup.object().shape({
                name: Yup.string().required('Required'),
                description: Yup.string().min(1).required('Required'),
                file: Yup.mixed()
                    .required('Assignment required')
                    .test(
                        'fileSize',
                        'File too large',
                        (value) => value && value.size <= 1000000000 // 1GB
                    )
                    .test('fileFormat', 'Unsupported Format', (value) => {
                        if (value !== null) {
                            return value && this.fileFormats.includes(value.type);
                        } else {
                            return true;
                        }
                    }),
                example: Yup.mixed()
                    .test('fileSize', 'File too large', (value) => {
                        if (value !== null) {
                            return value && value.size <= 1000000000; // 1GB
                        } else {
                            return true;
                        }
                    })
                    .test('fileFormat', 'Unsupported Format', (value) => {
                        if (value !== null) {
                            return value && this.fileFormats.includes(value.type);
                        } else {
                            return true;
                        }
                    }),
            });
        }
    };

    // check if module name already exists within current pathway
    duplicateName = (name) => {
        return (
            this.props.contents.filter((content) => content.name === name).length > 0
        );
    };

    onSubmit = (values, actions) => {
        actions.setSubmitting(false);
        if (this.duplicateName(values.name)) {
            this.setState({ error: 'Module name already exists' });
        } else {
            const content = Object.assign({}, values);
            content.type = this.state.type;

            if (values.articleInput !== '') {
                content.articleInput = sanitizeHtml(values.articleInput);
            }

            if (values.link !== '') {
                content.link = 'http://' + content.link;
            }

            if (values.file !== null) {
                content.file = values.file;
            }

            if (values.example !== null) {
                content.example = values.example;
            }

            actions.resetForm({
                values: {
                    ...this.initialValues,
                },
            });

            if (this.state.next) {
                this.props.next(content);
                this.setState({ next: false });
            } else if (this.state.complete) {
                this.props.complete(content);
            }
        }
    };

    render() {
        return (
            <Formik
                initialValues={this.initialValues}
                validationSchema={this.moduleSchema}
                onSubmit={this.onSubmit}
                enableReinitialize
            >
                {(formProps) => {
                    const {
                        handleReset,
                        handleSubmit,
                        handleChange,
                        setFieldValue,
                        handleBlur,
                        errors,
                        values,
                        isValid,
                        isSubmitting,
                        dirty,
                    } = formProps;
                    return (
                        <Form onReset={handleReset} onSubmit={handleSubmit}>
                            <Grid relaxed stackable>
                                <Grid.Row columns={1} stretched>
                                    <Grid.Column>
                                        <h1>Module {this.props.index}</h1>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={1} stretched>
                                    <Grid.Column>
                                        <ContentTypeDropdown
                                            setValue={(value) =>
                                                this.typeChangedHandler(value)
                                            }
                                            optionsList={this.state.contentOptions}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row
                                    style={{ height: '50vh' }}
                                    verticalAlign="top"
                                    columns={1}
                                    stretched
                                >
                                    <Grid.Column
                                        style={{ height: '100%', overflow: 'auto' }}
                                    >
                                        <p style={{ color: 'red' }}>
                                            {this.state.error}
                                        </p>
                                        {
                                            {
                                                Video: (
                                                    <Video formProps={formProps} />
                                                ),
                                                Article: (
                                                    <Article formProps={formProps} />
                                                ),
                                                'Resource Link': (
                                                    <ResourceLink
                                                        formProps={formProps}
                                                    />
                                                ),
                                                Project: (
                                                    <Project formProps={formProps} />
                                                ),
                                            }[this.state.type]
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                                {/* form goes here */}
                                <Grid.Row columns={1} stretched>
                                    <Grid.Column>
                                        <NewModuleButtons
                                            setOpen={this.props.setOpen}
                                            next={this.newModuleClickedHandler}
                                            complete={this.completeClickedHandler}
                                            isValid={isValid}
                                            isSubmitting={isSubmitting}
                                            dirty={dirty}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}

NewModule.propTypes = {
    setOpen: PropTypes.func,
    next: PropTypes.func,
    topic: PropTypes.string,
    complete: PropTypes.func,
    index: PropTypes.number,
    contents: PropTypes.array,
};

export default NewModule;
