import React from 'react';
import PropTypes from 'prop-types';
import { Button, Container } from 'semantic-ui-react';

export const NewModuleButtons = (props) => {
    const { setOpen, isValid, isSubmitting, dirty, next, complete } = props;

    return (
        <Container fluid textAlign="right" style={{ paddingRight: '1rem' }}>
            <Button
                type="submit"
                disabled={!isValid || isSubmitting || !dirty}
                color="yellow"
                onClick={complete}
            >
                I&apos;m Done
            </Button>
            <Button
                type="submit"
                disabled={!isValid || isSubmitting || !dirty}
                positive
                onClick={next}
            >
                Add Another Module
            </Button>
            <Button color="black" onClick={() => setOpen(false)}>
                Close
            </Button>
        </Container>
    );
};

export default NewModuleButtons;

NewModuleButtons.propTypes = {
    setOpen: PropTypes.func,
    isValid: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    dirty: PropTypes.bool,
    next: PropTypes.func,
    complete: PropTypes.func,
};
