import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProfileCard from '../../Molecules/ProfileCard/ProfileCard';
import {
    fetchPathwayStudentProfile,
    fetchTotalPages,
    fetchStudent,
} from '../../../store/';
import { Grid, Progress, Header, Divider } from 'semantic-ui-react';
import { Pathway } from '../../Molecules';
import { API, graphqlOperation } from 'aws-amplify';
import { getStudent } from '../../../store/CaseManager/actions/queries';
import { contentTypes } from '../../Molecules/WorkSpace/menuItems';
// renders case load page
class StudentProfile extends Component {
    state = {
        student: {},
        openModal: false,
        pathway: null,
        openAddPathwayModal: false,
        mounted: false,
        listTopics: [],
    };

    async componentDidMount() {
        try {
            let student = await API.graphql(
                graphqlOperation(getStudent, { id: this.props.authUser?.username })
            );
            this.setState({ student: student.data.getStudent });
            let value = await this.props.fetchPathwayStudentProfile();
            value = value.payload.data.listAssignments.items;
            for (let i = 0; i < value.length; i++) {
                let pages = await this.props.fetchTotalPages(value[i].pathwayID);
                value[i].total = pages.payload.data.syncPathwayContents.items.length;
                const all_modules = new Set();
                all_modules.add(contentTypes.SYLLABUS);
                pages.payload.data.syncPathwayContents.items.forEach((item) => {
                    all_modules.add(item?.content?.type);
                });
                value[i].name =
                    pages.payload.data.syncPathwayContents.items[0]?.pathway?.name;
                value[i].modules = [...all_modules];
            }
            this.setState({ mounted: true });
            this.setState({ pathway: value });
        } catch (error) {}
    }

    render() {
        if (this.state.pathway && this.state.pathway.length > 0) {
            let pathwayList = [];

            this.state.pathway.map((pathway) => {
                if (
                    !pathway.completedDate &&
                    pathway.pathwayID &&
                    pathway.completed_modules.length > 0
                ) {
                    // let completedRatio = !pathway.work_progress
                    //     ? 0
                    //     : (pathway.work_progress * 100) / pathway.total;
                    let completedRatio = (
                        (pathway.completed_modules.length * 100) /
                        pathway.modules.length
                    ).toFixed(0);
                    let color = '';
                    if (completedRatio < 35) {
                        color = 'red';
                    } else if (completedRatio < 65) {
                        color = 'yellow';
                    } else {
                        color = 'green';
                    }

                    pathwayList.push(
                        <Grid.Column key={pathway.id}>
                            <Pathway
                                src="https://react.semantic-ui.com/images/wireframe/image-text.png"
                                id={pathway.pathwayID}
                                setOpenModal={(open) =>
                                    this.modalChangedHandler(open)
                                }
                                setPathway={(pathway) =>
                                    this.setPathwayHandler(pathway)
                                }
                                pathway={pathway}
                            />
                            {pathway.name && <span>{pathway.name}</span>}
                            <br />
                            {completedRatio === 100 && pathway.is_completed && (
                                <span style={{ color: 'green' }}>Submitted</span>
                            )}
                            <br />
                            <Progress
                                percent={completedRatio}
                                progress="percent"
                                color={color}
                                size="small"
                            />
                        </Grid.Column>
                    );
                }
            });
            return (
                <div key="In Progress">
                    <Header>In Progress</Header>
                    <Divider />
                    <Grid columns="5">
                        <Grid.Row>{pathwayList}</Grid.Row>
                    </Grid>
                    <Grid.Row>{this.props.children}</Grid.Row>
                </div>
            );
        } else {
            return <div></div>;
        }
    }
}

// get access to desired global states
const mapStateToProps = (state) => {
    return {
        pathways: state.studentUser.pathways,
        authUser: state.auth.authenticatedUser,
    };
};

StudentProfile.propTypes = {
    pathways: PropTypes.object,
    fetchPathwayStudentProfile: PropTypes.func,
    fetchTotalPages: PropTypes.func,
    fetchStudent: PropTypes.func,
    children: PropTypes.element,
    authUser: PropTypes.object,
};

export default connect(mapStateToProps, {
    fetchPathwayStudentProfile,
    fetchTotalPages,
    fetchStudent,
})(StudentProfile);
