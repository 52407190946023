import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Button,
    Form,
    Grid,
    Header,
    Icon,
    Message,
    Segment,
} from 'semantic-ui-react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';

const initialValues = {
    email: '',
    code: '',
    error: '',
};

const validationSchema = Yup.object({
    email: Yup.string().email('Not a valid email.').required('Email is required'),
    code: Yup.string().required('Comfirm code is required.'),
});

const ConfirmSignupForm = () => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const onSubmit = async (values, actions) => {
        const { email, code } = values;
        try {
            setLoading(true);
            await Auth.confirmSignUp(email, code);
            setLoading(false);
            history.push('/login'); // Redirect to login after confirm
        } catch (err) {
            setLoading(false);
            if (err.message) actions.setFieldError('error', err.message);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {(formikProps) => {
                const {
                    handleSubmit,
                    handleReset,
                    errors,
                    touched,
                    isValid,
                    dirty,
                } = formikProps;

                return (
                    <Grid
                        textAlign="center"
                        style={{ height: '80vh' }}
                        verticalAlign="middle"
                    >
                        <Grid.Column style={{ maxWidth: 450 }}>
                            <Header as="h2" textAlign="center">
                                <Icon name="globe" />
                                Confirm Your Account
                            </Header>
                            <Form
                                size="large"
                                onReset={handleReset}
                                onSubmit={handleSubmit}
                                error
                            >
                                <Segment
                                    stacked
                                    align="left"
                                    color={
                                        isValid && dirty
                                            ? 'green'
                                            : touched && !isValid
                                            ? 'red'
                                            : null
                                    }
                                >
                                    <Message>
                                        <p style={{ color: 'green' }}>
                                            You have successfully created a new
                                            account.
                                        </p>
                                        <p>
                                            We&apos;ve sent you an email with the
                                            comfirmation code.
                                        </p>
                                    </Message>
                                    <Field
                                        as={Message}
                                        error
                                        name="error"
                                        content={errors.error}
                                    />

                                    <Field
                                        type="email"
                                        name="email"
                                        fluid
                                        icon="mail outline"
                                        iconPosition="left"
                                        placeholder="E-mail address"
                                        error={
                                            touched.email && errors.email
                                                ? {
                                                      content: errors.email,
                                                      pointing: 'above',
                                                  }
                                                : null
                                        }
                                        as={Form.Input}
                                    />
                                    <Field
                                        name="code"
                                        fluid
                                        icon="barcode"
                                        iconPosition="left"
                                        placeholder="Comfirm code"
                                        type="code"
                                        error={
                                            touched.code && errors.code
                                                ? {
                                                      content: errors.code,
                                                      pointing: 'above',
                                                  }
                                                : null
                                        }
                                        as={Form.Input}
                                    />

                                    <Button
                                        loading={loading}
                                        disabled={loading}
                                        primary
                                        fluid
                                        size="large"
                                        type="submit"
                                    >
                                        Confirm
                                    </Button>
                                </Segment>
                            </Form>
                        </Grid.Column>
                    </Grid>
                );
            }}
        </Formik>
    );
};

export default ConfirmSignupForm;
