import React from 'react';
import { Container, Header, Divider } from 'semantic-ui-react';
import { useCountDown } from '../../../utility/utility';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Role } from '../../';

const Welcome = (props) => {
    const { authenticatedUser } = props;
    let welcomeContent;
    let count;
    if (authenticatedUser && authenticatedUser.attributes) {
        // redirect to caseload
        count = useCountDown(5, '/dashboard/caseload');
        //TODO:figure out if need to delete this
        //count = useCountDown(3, '/caseload');
        welcomeContent = (
            <>
                <p style={{ color: 'green' }}>Your account is verified</p>
                <p>
                    You are logged in as:{' '}
                    <span style={{ color: 'green' }}>
                        {authenticatedUser.attributes['custom:name']}
                    </span>
                </p>

                {authenticatedUser.attributes['custom:role'] ===
                    Role.CASE_MANAGER && (
                    <p>
                        You are Case Manager.
                        <br />
                        You will be redirect to Case Load page in{' '}
                        <span
                            style={{
                                color: 'red',
                                fontWeight: 'bold',
                                fontSize: '1.3rem',
                            }}
                        >
                            {count}{' '}
                        </span>
                        seconds
                    </p>
                )}
            </>
        );
    } else {
        // redirect to signup confirm
        count = useCountDown(3, '/confirm-signup');
        welcomeContent = (
            <>
                <p style={{ color: 'green' }}>
                    You have successfully created a new account.
                </p>
                <p>We&apos;ve sent you an email with the comfirmation code.</p>
                <p>
                    You will be redirect to confirmation page in{' '}
                    <span
                        style={{
                            color: 'red',
                            fontWeight: 'bold',
                            fontSize: '1.3rem',
                        }}
                    >
                        {count}{' '}
                    </span>
                    seconds
                </p>
            </>
        );
    }

    return (
        <Container>
            <Header color="teal">Welcome!</Header>
            <Divider />
            {welcomeContent}
        </Container>
    );
};

const mapStateToProps = (state) => ({
    authenticatedUser: state.auth.authenticatedUser,
});

export default connect(mapStateToProps)(Welcome);

Welcome.propTypes = {
    authenticatedUser: PropTypes.object,
};
