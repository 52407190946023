import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, TextArea, Grid } from 'semantic-ui-react';

class Video extends Component {
    render() {
        return (
            <Grid.Column width={5}>
                <Grid.Row style={{ height: '12vh' }} verticalAlign="top">
                    <h3>Video Title</h3>
                    <Input
                        name="name"
                        onChange={this.props.formProps.handleChange}
                        value={this.props.formProps.values.name || ''}
                        placeholder="Title..."
                        onBlur={this.props.formProps.handleBlur}
                    />
                    <p style={{ color: 'red' }}>
                        {this.props.formProps.errors.name}
                    </p>
                </Grid.Row>
                <Grid.Row style={{ height: '18vh' }} verticalAlign="top">
                    <h3>Add a Description</h3>
                    <TextArea
                        name="description"
                        onChange={this.props.formProps.handleChange}
                        value={this.props.formProps.values.description || ''}
                        placeholder="Enter a description"
                        style={{ minHeight: 100, minWidth: 800 }}
                        onBlur={this.props.formProps.handleBlur}
                    />
                    <p style={{ color: 'red' }}>
                        {this.props.formProps.errors.description}
                    </p>
                </Grid.Row>
                <Grid.Row>
                    <h3>Select Video</h3>
                    <p>supported extension: .mp4, .mov</p>
                    <p>maximum size: 1GB</p>
                    <Input
                        name="file"
                        id="file"
                        type="file"
                        onChange={(event) => {
                            this.props.formProps.setFieldValue(
                                'file',
                                event.currentTarget.files[0]
                            );
                        }}
                    />
                    <p style={{ color: 'red' }}>
                        {this.props.formProps.errors.file}
                    </p>
                </Grid.Row>
            </Grid.Column>
        );
    }
}

export default Video;

Video.propTypes = {
    formProps: PropTypes.object,
};
