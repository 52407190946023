import React, { Component } from 'react';
import { Segment, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
class ResourceLink extends Component {
    render() {
        const resourceLinks = this.props.content?.map(
            (item, index) =>
                item?.content && (
                    <Segment.Group key={index} raised>
                        <Segment secondary>
                            <Header as="h3" textAlign="center">
                                {item.content.name}
                                <Header.Subheader>
                                    {item.content.description}
                                </Header.Subheader>
                            </Header>
                        </Segment>
                        <Segment>
                            <a
                                href={item.content.resource_link}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {item.content.resource_link}
                            </a>
                        </Segment>
                    </Segment.Group>
                )
        );

        return <>{resourceLinks}</>;
    }
}

export default ResourceLink;

ResourceLink.propTypes = {
    content: PropTypes.array,
};
