import React, { useState, useEffect } from 'react';
import { Button } from 'semantic-ui-react';
import { contentTypes as types } from '../menuItems';
import { updateAssignment, createAssignment } from '../../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import PropTypes from 'prop-types';

const CompleteButton = (props) => {
    const {
        activeItem,
        setActiveItem,
        modules,
        assignment,
        setAssignment,
        setIsCompleted,
        loading,
        studentID,
        pathwayID,
    } = props;
    const [disabled, setDisabled] = useState(false);
    const [completedModules, setCompletedModules] = useState(
        assignment?.completed_modules || []
    );
    const progresses = [...modules];

    const isModuleCompleted = completedModules.includes(activeItem);
    const isOnSyllabus = activeItem === types.SYLLABUS;
    const isStarted = completedModules.includes(types.SYLLABUS);
    const label = isOnSyllabus
        ? !isModuleCompleted
            ? 'Start Pathway'
            : 'Pathway Started'
        : !isModuleCompleted
        ? `Complete ${activeItem}`
        : `${activeItem} Completed`;

    useEffect(() => {
        setCompletedModules(assignment?.completed_modules || []);
    }, [assignment]);

    useEffect(() => {
        const nextItem = progresses.find((item) => !completedModules.includes(item));
        if (nextItem) {
            setActiveItem(nextItem);
        } else {
            // console.log('All modules completed! Ready to submit!');
            setDisabled(true);
            setIsCompleted(true);
        }
    }, [completedModules]);

    const completeModuleHandler = async () => {
        const newCompletedModules = [...completedModules, activeItem];
        await updateCompletedModules(newCompletedModules);
        setCompletedModules(newCompletedModules);
    };
    const resetModuleHandler = async () => {
        await updateCompletedModules([]);
        setDisabled(false);
        setIsCompleted(false);
    };
    const updateCompletedModules = async (completedModules) => {
        let data = {
            completed_modules: completedModules,
            is_completed: false,
        };
        let result;
        try {
            if (assignment) {
                data = {
                    ...data,
                    id: assignment.id,
                    _version: assignment._version,
                };

                result = await API.graphql(
                    graphqlOperation(updateAssignment, {
                        input: data,
                    })
                );
                setAssignment(result.data.updateAssignment);
            } else {
                data = {
                    ...data,
                    studentID: studentID,
                    pathwayID: pathwayID,
                };
                result = await API.graphql(
                    graphqlOperation(createAssignment, {
                        input: data,
                    })
                );
                setAssignment(result.data.createAssignment);
            }
        } catch (error) {
            // Todo: (Duc) built global alert
        }
    };

    return (
        <Button.Group floated="right">
            <Button
                secondary
                onClick={resetModuleHandler}
                disabled={!isStarted || !!assignment?.completedDate}
                loading={loading}
            >
                Reset
            </Button>
            <Button
                primary={!isModuleCompleted}
                color="green"
                floated="right"
                onClick={completeModuleHandler}
                disabled={
                    disabled || isModuleCompleted || (!isStarted && !isOnSyllabus)
                }
                loading={loading}
            >
                {label}
            </Button>
        </Button.Group>
    );
};

export default CompleteButton;

CompleteButton.propTypes = {
    activeItem: PropTypes.string,
    setActiveItem: PropTypes.func,
    modules: PropTypes.object,
    assignment: PropTypes.object,
    setAssignment: PropTypes.func,
    setIsCompleted: PropTypes.func,
    loading: PropTypes.bool,
    studentID: PropTypes.string,
    pathwayID: PropTypes.string,
};
