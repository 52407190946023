/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Pagination } from 'semantic-ui-react';

const NavigateButtons = (props) => {
    const { activeItem, setActiveItem, modules } = props;
    const pages = [...modules];

    const moduleChangeHandler = (e, { activePage }) => {
        setActiveItem(pages[activePage - 1]);
    };
    return (
        <Pagination
            activePage={pages.indexOf(activeItem) + 1}
            onPageChange={moduleChangeHandler}
            totalPages={pages.length}
            pageItem={activeItem}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={0}
            boundaryRange={0}
            floated="right"
            size="mini"
            secondary
            pointing
        />
    );
};

export default NavigateButtons;
