import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { API, graphqlOperation } from 'aws-amplify';
import { connect } from 'react-redux';

import {
    Grid,
    TextArea,
    Icon,
    Button,
    Transition,
    Segment,
    Card,
    Message,
    Input,
    Form,
} from 'semantic-ui-react';
import { createIEPGoal } from '../../../graphql/mutations';
import * as actionType from '../../../store/actionType';
import {
    fetchStudent,
    fetchStudents,
} from '../../../store/CaseManager/actions/actions';

class AddIEPGoal extends Component {
    state = {
        openEdit: false,
        disableEditBtn: false,
        disableSaveBtn: true,
        disableAddBtn: true,
        goals: [],
        errors: [],
        goal: {
            name: '',
            description: '',
        },
        message: '',
    };

    // if Edit Goals button is clicked, open edit section, disable Edit Goals button, clear success message
    editGoalsClickedHandler = () => {
        this.setState({
            openEdit: true,
            disableEditBtn: true,
            message: '',
        });
    };

    // if Add button is clicked, store goal, enable Save button, disable Add button
    addGoalHandler = () => {
        this.setState((prev) => {
            return {
                ...prev,
                goals: [prev.goal, ...prev.goals],
                goal: {
                    name: '',
                    description: '',
                },
                disableSaveBtn: false,
                disableAddBtn: true,
            };
        });
    };

    // if goal name is changed, store it
    nameChangedHandler = (event) => {
        this.setState((prev) => {
            return {
                ...prev,
                goal: {
                    ...prev.goal,
                    name: event.target.value,
                },
            };
        });

        // if both name and description are filled out, enable Add button
        if (event.target.value !== '' && this.state.goal.description !== '') {
            this.setState({ disableAddBtn: false });
        } else {
            this.setState({ disableAddBtn: true });
        }
    };

    // if goal description is changed, store it
    descChangedHandler = (event) => {
        this.setState((prev) => {
            return {
                ...prev,
                goal: {
                    ...prev.goal,
                    description: event.target.value,
                },
            };
        });

        // if both name and description are filled out, enable Add button
        if (event.target.value !== '' && this.state.goal.name !== '') {
            this.setState({ disableAddBtn: false });
        } else {
            this.setState({ disableAddBtn: true });
        }
    };

    // if remove goal is clicked, remove goal object from goals array
    removeGoalHandler = (index) => {
        let newGoals = [...this.state.goals];
        let newErrors = [...this.state.errors];
        newGoals.splice(index, 1);
        newErrors.splice(index, 1);

        this.setState({
            goals: newGoals,
            errors: newErrors,
        });

        // disable Save button if goals array is empty
        if (this.state.goals.length === 1) {
            this.setState({ disableSaveBtn: true });
        }
    };

    closeClickedHandler = () => {
        this.setState({
            openEdit: false,
            disableEditBtn: false,
        });
    };

    // if Save button is clicked, check if new goals names already exist.
    // if not, add each goal to database, update student state in redux, update caseloadStudents state in redux, set success message
    saveClickedHandler = async () => {
        let errors = [];
        for (let i = 0; i < this.state.goals.length; i++) {
            const existGoal = this.props.student.IEPGoals.items.filter(
                (goal) => goal.name === this.state.goals[i].name
            );
            if (existGoal.length === 0) {
                errors = [...errors, ''];
            } else {
                errors = [...errors, 'This goal already exists'];
            }
        }

        this.setState({ errors: errors });
        if (!errors.includes('This goal already exists')) {
            for (let i = 0; i < this.state.goals.length; i++) {
                const newGoal = {
                    name: this.state.goals[i].name,
                    description: this.state.goals[i].description,
                    studentID: this.props.student.id,
                    casemanagerID: this.props.casemanagerID,
                };

                const progress = await API.graphql(
                    graphqlOperation(createIEPGoal, { input: newGoal })
                );

                await this.props.fetchStudent(this.props.student.id);
                await this.props.fetchStudents(this.props.casemanagerID, false);
            }

            this.setState({
                openEdit: false,
                disableEditBtn: false,
                disableSaveBtn: true,
                goals: [],
                errors: [],
                goal: '',
                message: 'Goals are added successfully',
            });
        }
    };

    render() {
        let cards = null;
        if (this.state.goals.length > 0) {
            cards = this.state.goals.map((goal, index) => (
                <React.Fragment key={index}>
                    <Card fluid>
                        <Card.Content>
                            <Icon
                                name="remove circle"
                                style={{ float: 'right' }}
                                link
                                onClick={() => this.removeGoalHandler(index)}
                            />
                            <Card.Header>{goal.name}</Card.Header>
                            <Card.Description>{goal.description}</Card.Description>
                        </Card.Content>
                        {this.state.errors.length > 0 &&
                        this.state.errors[index] !== '' ? (
                            <Card.Content extra>
                                <Message negative attached="bottom">
                                    {this.state.errors[index]}
                                </Message>
                            </Card.Content>
                        ) : null}
                    </Card>
                </React.Fragment>
            ));
        }

        return (
            <Segment basic>
                <Grid>
                    <Grid.Row>
                        <Grid.Column floated="left" width={5}>
                            <Button
                                disabled={this.state.disableEditBtn}
                                icon
                                color="green"
                                labelPosition="right"
                                onClick={this.editGoalsClickedHandler}
                            >
                                Add Goal
                                <Icon name="edit" />
                            </Button>
                        </Grid.Column>
                        {this.state.openEdit ? (
                            <Grid.Column floated="right" width={5}>
                                <Button
                                    icon
                                    color="red"
                                    labelPosition="right"
                                    onClick={this.closeClickedHandler}
                                >
                                    Close
                                    <Icon name="window close" />
                                </Button>
                            </Grid.Column>
                        ) : null}
                    </Grid.Row>
                    {this.state.message !== '' ? (
                        <Grid.Row>
                            <Message positive attached="bottom">
                                {this.state.message}
                            </Message>
                        </Grid.Row>
                    ) : null}
                </Grid>

                <Transition.Group animation="slide down" duration={500}>
                    {this.state.openEdit ? (
                        <Grid columns={2} divided stackable relaxed>
                            <Grid.Column>
                                <Form>
                                    <Button
                                        icon
                                        fluid
                                        color="yellow"
                                        labelPosition="right"
                                        onClick={this.addGoalHandler}
                                        style={{ marginBottom: '10px' }}
                                        disabled={this.state.disableAddBtn}
                                    >
                                        Add
                                        <Icon name="arrow right" />
                                    </Button>
                                    <Form.Field>
                                        <label>Goal Name</label>
                                        <Input
                                            fluid
                                            placeholder="e.g. Writing Goal"
                                            onChange={this.nameChangedHandler}
                                            value={this.state.goal.name || ''}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>IEP Goal</label>
                                        <TextArea
                                            onChange={this.descChangedHandler}
                                            value={this.state.goal.description}
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                            <Grid.Column>
                                <Button
                                    fluid
                                    disabled={
                                        this.state.disableSaveBtn ||
                                        this.props.loading
                                    }
                                    loading={this.props.loading}
                                    icon
                                    color="blue"
                                    labelPosition="right"
                                    style={{ marginBottom: '10px' }}
                                    onClick={this.saveClickedHandler}
                                >
                                    Save
                                    <Icon name="download" />
                                </Button>
                                <Card.Group>{cards}</Card.Group>
                            </Grid.Column>
                        </Grid>
                    ) : null}
                </Transition.Group>
            </Segment>
        );
    }
}

AddIEPGoal.propTypes = {
    student: PropTypes.object,
    casemanagerID: PropTypes.string,
    fetchStudent: PropTypes.func,
    fetchStudents: PropTypes.func,
    loading: PropTypes.bool,
};

// get access to desired global states
const mapStateToProps = (state) => {
    return {
        casemanagerID: state.casemanager.id,
        loading: state.casemanager.loading,
    };
};

export default connect(mapStateToProps, { fetchStudent, fetchStudents })(AddIEPGoal);
