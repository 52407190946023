import React, { Component } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { PropTypes } from 'prop-types';
import parse, { convertNodeToElement } from 'html-react-parser';
import RoleSwitch from '../../../Atoms/Switch/RoleSwitch';
class Article extends Component {
    render() {
        //map items in content array to article array
        //need key prop to uniquely identify each content item in the item array
        //index is the index of array
        const article = this.props.content?.map(
            (item, index) =>
                item?.content && (
                    <Segment.Group key={index} raised>
                        <Segment secondary>
                            <Header as="h3" textAlign="center">
                                {item.content.name}
                                <Header.Subheader as="h3" textAlign="center">
                                    {item.content.description}
                                </Header.Subheader>
                            </Header>
                        </Segment>

                        <Segment>{parse(item.content.article_input)}</Segment>
                    </Segment.Group>
                )
        );

        return <>{article}</>;
    }
}

Article.propTypes = {
    content: PropTypes.array,
};

export default Article;
