/* eslint-disable react/prop-types */

import React, { useState } from 'react';
import { Table, Message, Form } from 'semantic-ui-react';
import LikertScale from './LikertScale';
import PropTypes from 'prop-types';
import { Field as FormikField, ErrorMessage } from 'formik';

const LikertTable = (props) => {
    // scale is the rating scale used to rate the questions
    const { label, name, scale, questions, formProps, ...rest } = props;

    // scaleValues is the array of all of the [questions]: rating
    const [scaleValues, setScaleValues] = useState({});

    const scaleChange = (value) => {
        setScaleValues(value);
        formProps.setFieldValue(name, value);
    };

    const ratingScale = scale.map((item) => (
        <Table.HeaderCell key={item}>{item}</Table.HeaderCell>
    ));
    const formattedQuestions = questions.map((item) => (
        <LikertScale
            key={item}
            label=""
            question={item}
            options={scale}
            scaleValues={scaleValues}
            setScaleValues={(value) => scaleChange(value)}
        />
    ));

    return (
        <>
            <Form.Field required={rest.required}>
                <label></label>
            </Form.Field>
            <ErrorMessage
                name={name}
                component={(errMsg) => (
                    <Message negative>
                        <p>{errMsg.children}</p>
                    </Message>
                )}
            />
            <FormikField name={name} {...rest}>
                {(formikProps) => {
                    const { field, form } = formikProps;

                    return (
                        <Table
                            definition
                            striped
                            textAlign="center"
                            verticalAlign="middle"
                            value={scaleValues}
                        >
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell />
                                    {ratingScale}
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>{formattedQuestions}</Table.Body>
                        </Table>
                    );
                }}
            </FormikField>
        </>
    );
};

LikertTable.propTypes = {
    scale: PropTypes.array.isRequired,
    questions: PropTypes.array.isRequired,
    formProps: PropTypes.object,
};

export default LikertTable;
