import React, { useState } from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';

const DatePicker = (props) => {
    const { title, setFieldValue } = props;
    const [currentDate, setNewDate] = useState(null);
    const onChange = (event, data) => {
        setNewDate(data.value);
        setFieldValue(data.name, data.value);
    };
    return (
        <Segment basic>
            {title}
            <span style={{ color: 'red', margin: '0 0.5rem' }}>*</span>
            <SemanticDatepicker
                name="dueDate"
                icon="calendar alternate outline"
                onChange={onChange}
            />
        </Segment>
    );
};

export default DatePicker;

DatePicker.propTypes = {
    setFieldValue: PropTypes.func,
    title: PropTypes.string,
};
