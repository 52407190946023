/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Menu } from 'semantic-ui-react';

const WorkSpaceMenu = (props) => {
    const { modules, activeItem, setActiveItem } = props;

    const handleItemClick = (e, { name }) => {
        setActiveItem(name);
    };

    const menuItems = [];
    modules &&
        modules.forEach((item) => {
            menuItems.push(
                <Menu.Item
                    key={item}
                    name={item}
                    active={activeItem === item}
                    onClick={handleItemClick}
                />
            );
        });

    return (
        <Menu vertical fluid>
            {menuItems}
        </Menu>
    );
};

export default WorkSpaceMenu;
