import * as actionTypes from './actions/actionTypes';
import { updateObject } from '../../utility/utility';

const initialState = {
    id: '60edc881-8523-4c66-9116-a048296c1330',
    caseloadStudents: [],
    collaborateStudents: [],
    unassignedStudents: [],
    student: null,
    error: null,
    loading: false,
    pathwaysCreated: 0,
    allPathways: {},
};

//FETCH STUDENTS
const fetchCaseLoadStudentsSuccess = (state, action) => {
    return updateObject(state, {
        caseloadStudents: action.payload,
        loading: false,
        error: null,
    });
};

const fetchCollaborateStudentsSuccess = (state, action) => {
    return updateObject(state, {
        collaborateStudents: action.payload,
        loading: false,
        error: null,
    });
};

const fetchUnAssignedStudentsSuccess = (state, action) => {
    return updateObject(state, {
        unassignedStudents: action.payload,
        loading: false,
        error: null,
    });
};

const fetchStudentsStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null,
    });
};

const fetchStudentsFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
    });
};

const fetchStudentSuccess = (state, action) => {
    return updateObject(state, {
        student: action.payload,
        loading: false,
        error: null,
    });
};

const fetchStudentStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null,
    });
};

const fetchStudentFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
    });
};

const fetchPathwaysCreatedSuccess = (state, action) => {
    return updateObject(state, {
        pathwaysCreated: action.payload,
        loading: false,
        error: null,
    });
};

const fetchPathwaysCreatedStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null,
    });
};

const fetchPathwaysCreatedFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
    });
};

const fetchPathwaysSuccess = (state, action) => {
    return updateObject(state, {
        allPathways: action.payload,
        loading: false,
        error: null,
    });
};

const fetchPathwaysStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null,
    });
};

const fetchPathwaysFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
    });
};

const signOutSuccess = (state, action) => {
    return updateObject(state, {
        caseloadStudents: [],
        collaborateStudents: [],
        unassignedStudents: [],
        student: null,
        error: null,
        loading: false,
        pathwaysCreated: 0,
        allPathways: {},
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CASELOAD_STUDENTS_SUCCESS:
            return fetchCaseLoadStudentsSuccess(state, action);
        case actionTypes.FETCH_COLLABORATE_STUDENTS_SUCCESS:
            return fetchCollaborateStudentsSuccess(state, action);
        case actionTypes.FETCH_UNASSIGNED_STUDENTS_SUCCESS:
            return fetchUnAssignedStudentsSuccess(state, action);
        case actionTypes.FETCH_STUDENTS_START:
            return fetchStudentsStart(state, action);
        case actionTypes.FETCH_STUDENTS_FAIL:
            return fetchStudentsFail(state, action);

        case actionTypes.FETCH_STUDENT_SUCCESS:
            return fetchStudentSuccess(state, action);
        case actionTypes.FETCH_STUDENT_START:
            return fetchStudentStart(state, action);
        case actionTypes.FETCH_STUDENT_FAIL:
            return fetchStudentFail(state, action);

        case actionTypes.FETCH_PATHWAYS_CREATED_SUCCESS:
            return fetchPathwaysCreatedSuccess(state, action);
        case actionTypes.FETCH_PATHWAYS_CREATED_START:
            return fetchPathwaysCreatedStart(state, action);
        case actionTypes.FETCH_PATHWAYS_CREATED_FAIL:
            return fetchPathwaysCreatedFail(state, action);

        case actionTypes.FETCH_PATHWAYS_SUCCESS:
            return fetchPathwaysSuccess(state, action);
        case actionTypes.FETCH_PATHWAYS_START:
            return fetchPathwaysStart(state, action);
        case actionTypes.FETCH_PATHWAYS_FAIL:
            return fetchPathwaysFail(state, action);

        case actionTypes.SIGN_OUT_SUCCESS:
            return signOutSuccess(state, action);

        default: {
            return state;
        }
    }
};

export default reducer;
