import React from 'react';
import { Grid, Button, Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const Assignment = (props) => {
    const { setOpenModal, setPathway, pathway, src } = props;

    const openModalHandler = () => {
        setPathway(pathway);
        setOpenModal(true);
    };
    const overDue = new Date(pathway.dueDate).getDate() - new Date().getDate();
    let color = null;
    if (overDue < 0) color = 'red';
    else if (overDue <= 1) color = 'orange';
    return (
        <Grid.Column>
            <Grid.Row>
                <Button
                    fluid
                    style={{ padding: '1px' }}
                    color={color}
                    onClick={openModalHandler}
                >
                    <Image src={src} />
                </Button>
            </Grid.Row>
            <Grid.Row>
                <p style={{ color: color }}>
                    Due {new Date(pathway.dueDate).toLocaleDateString()}
                </p>
            </Grid.Row>
        </Grid.Column>
    );
};

export default Assignment;

Assignment.propTypes = {
    setOpenModal: PropTypes.func,
    setPathway: PropTypes.func,
    pathway: PropTypes.object,
    src: PropTypes.string,
};
