import React from 'react';
import { Field as FormikField, ErrorMessage } from 'formik';
import { Message, Form, TextArea as TextAreaUI, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { formData } from '..';

/*
 * TextArea could be use as Text Area (muliple line text) or Input (single line input)
 * pass in type 'input' or 'text area' as prop
 */

const TextArea = (props) => {
    const { label, name, type, ...rest } = props;
    return (
        <div style={{ margin: '1rem' }}>
            <Form.Field required={rest.required}>
                <label>{label}</label>
            </Form.Field>
            <ErrorMessage
                name={name}
                component={(errMsg) => (
                    <Message negative>
                        <p>{errMsg.children}</p>
                    </Message>
                )}
            />
            <FormikField name={name} {...rest}>
                {(props) => {
                    const { field } = props;
                    return (
                        <Form.Field
                            {...field}
                            {...rest}
                            control={
                                type === formData.TEXT_AREA_TYPE ? TextAreaUI : Input
                            }
                        />
                    );
                }}
            </FormikField>
        </div>
    );
};

TextArea.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    field: PropTypes.object,
    type: PropTypes.string.isRequired,
};

export default TextArea;
