import React from 'react';
import { Dimmer, Loader as LoaderUI, Segment } from 'semantic-ui-react';

const Loader = () => (
    <Segment basic>
        <Dimmer active inverted>
            <LoaderUI size="medium">Loading...</LoaderUI>
        </Dimmer>
    </Segment>
);

export default Loader;
