import React from 'react';
import { Segment, Breadcrumb as BC } from 'semantic-ui-react';
import PropTypes from 'prop-types';
export const BreadCrumb = (props) => {
    const { student, setOpenWorkSpace, isCollaborator } = props;

    const sections = [
        {
            key: 'CaseLoad',
            content: `${!isCollaborator ? 'CaseLoad' : 'Progress Monitor'} Students`,
            link: true,
            onClick: () => {
                setOpenWorkSpace(false);
            },
        },
        { key: student.id, content: student.name, link: false },
    ];

    return <BC icon="right angle" sections={sections} />;
};

export default BreadCrumb;
BreadCrumb.propTypes = {
    student: PropTypes.object,
    setOpenWorkSpace: PropTypes.func,
    isCollaborator: PropTypes.bool,
};
