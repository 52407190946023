import React from 'react';
import { Tab } from 'semantic-ui-react';

import { Badges } from '../../Templates/';

/* 
    Single Tab object will be combine with other tabs into a pane array.
    The pane will be inserted into ContentBox component as prop
*/

const BTab = () => (
    <Tab.Pane>
        <Badges />
    </Tab.Pane>
);
const BadgeTab = {
    menuItem: { key: 'badge', content: 'Badges' },
    render: BTab,
};

export default BadgeTab;
