import React, { useEffect } from 'react';
import { Modal, Icon, Button, Grid, Transition, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const CongratsModal = (props) => {
    const { open, setOpen } = props;
    const [visible, setVisible] = React.useState(true);

    useEffect(() => {
        setVisible(open);
    }, [open]);

    return (
        <Modal
            closeIcon
            size="tiny"
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            // trigger={<Button>Show Modal</Button>}
        >
            <Modal.Header>Pathway Completed</Modal.Header>
            <Modal.Content>
                <Grid centered stackable padded>
                    <Grid.Row stretched>
                        <Transition
                            animation="jiggle"
                            duration={1000}
                            visible={visible}
                        >
                            <Icon name="winner" color="yellow" size="massive" />
                        </Transition>
                    </Grid.Row>
                    <Grid.Row>
                        <Header as="h1" color="orange" content="Congratulations!" />
                    </Grid.Row>
                    <Modal.Description style={{ color: 'grey' }}>
                        <h3>
                            <Icon name="star" color="yellow" />
                            Way to go! You have earned a new badge for completing a
                            learning pathway!
                            <Icon name="star" color="yellow" />
                        </h3>
                    </Modal.Description>
                </Grid>
            </Modal.Content>
        </Modal>
    );
};

export default CongratsModal;

CongratsModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
};
