import * as actionType from '../actionType';

// add states here
const initialState = {
    authenticated: false,
    students: [],
    pathways: {},
    // career interests with grade breakdown: {interest1: {9: 1, 10: 2, 11: 1}, interest2: {9: 2, 10: 1, 11: 1}, ...}
    interests: {},
    // post-secondary goals with grade breakdown
    postSecondaryGoals: {},
    id: '60edc881-8523-4c66-9116-a048296c1330', // To-do: remove hard-coded id
    student: {},
};

// reducer dispatches
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.AUTHENTICATED: {
            return {
                ...state,
                // change authenticated state here
            };
        }
        // add a student object to students state
        case actionType.ADD_STUDENT: {
            // in current students state, create a copy of each student object, and place it in the new array
            const newStudents = state.students.map((student) => {
                return Object.assign({}, student);
            });
            // console.log(student);
            return {
                ...state,
                // extract all elements in newStudents array, and place them with the new student object in students array
                students: [
                    ...newStudents,
                    {
                        ...action.student,
                        Profile: { ...action.student.Profile },
                        Progresses: { ...action.student.Progresses },
                    },
                ],
            };
        }
        // add a pathway object to pathways state
        case actionType.ADD_PATHWAY: {
            // create a copy of pathways object
            let newPathways = JSON.parse(JSON.stringify(state.pathways));

            // add pathway object to pathways copy
            if (action.pathway.category in newPathways) {
                newPathways[action.pathway.category] = [
                    ...newPathways[action.pathway.category],
                    action.pathway,
                ];
            } else {
                newPathways[action.pathway.category] = [action.pathway];
            }

            return {
                ...state,
                // set pathways copy as pathways state
                pathways: newPathways,
            };
        }
        // add career interest data
        case actionType.POPULATE_MY_SCHOOL: {
            // create a copy of career interests object
            let newInterests = JSON.parse(JSON.stringify(state.interests));
            let newGoals = JSON.parse(JSON.stringify(state.postSecondaryGoals));
            const interest =
                action.profile &&
                action.profile.career_interest &&
                action.profile.career_interest.toLowerCase();
            const goal =
                action.profile &&
                action.profile.post_secondary &&
                action.profile.post_secondary.toLowerCase();

            if (interest in newInterests) {
                if (action.profile.grade in newInterests[interest]) {
                    newInterests[interest][action.profile.grade] =
                        newInterests[interest][action.profile.grade] + 1;
                } else {
                    newInterests[interest][action.profile.grade] = 1;
                }
            } else {
                newInterests[interest] = {};
                newInterests[interest][action.profile.grade] = 1;
            }

            if (goal in newGoals) {
                if (action.profile.grade in newGoals[goal]) {
                    newGoals[goal][action.profile.grade] =
                        newGoals[goal][action.profile.grade] + 1;
                } else {
                    newGoals[goal][action.profile.grade] = 1;
                }
            } else {
                newGoals[goal] = {};
                newGoals[goal][action.profile.grade] = 1;
            }

            return {
                ...state,
                // extract all elements in newStudents array, and place them with the new student object in students array
                interests: newInterests,
                postSecondaryGoals: newGoals,
            };
        }
        // add more dispatches here
        case actionType.SELECT_STUDENT: {
            return {
                ...state,
                student: action.student,
            };
        }
        default: {
            return state;
        }
    }
};

export default reducer;
