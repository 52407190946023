/* eslint-disable react/prop-types */
import React from 'react';
import { Line } from 'react-chartjs-2';
import { progressTowardIEPOptions } from '../../Molecules/ProgressMonitorForm/formData';

const LineChart = (props) => {
    const { progresses } = props;
    const createdDates = progresses?.map((p) => {
        const date = new Date(p.createdAt);
        return `${date.getMonth()}-${date.getDate()}-${date.getFullYear()}`;
    });

    const dataPoints = progresses?.map((progress) =>
        progressTowardIEPOptions.findIndex(
            (option) => option.value === progress.progress_toward_iep
        )
    );
    const graphData = {
        labels: createdDates,
        datasets: [
            {
                label: 'Student Progress',
                fill: false,
                lineTension: 0.5,
                backgroundColor: 'rgba(75,192,192,1)',
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 2,
                data: dataPoints,
            },
        ],
    };

    const graphOptions = {
        plugins: {
            title: {
                display: true,
                text: 'Progress Monitoring',
            },
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const { raw, dataIndex } = context;
                        return [
                            progressTowardIEPOptions[raw].value,
                            progresses[dataIndex].observation,
                        ];
                    },
                },
            },
        },
        scales: {
            y: {
                min: 0,
                max: 4,
                ticks: {
                    // Convert the axis from numeric to the corresponding string value.
                    callback: (value, index, values) => {
                        return value - Math.floor(value) !== 0
                            ? ''
                            : progressTowardIEPOptions[Math.floor(value)].value;
                    },
                },
            },
        },
    };

    return <Line data={graphData} options={graphOptions} />;
};

export default LineChart;
