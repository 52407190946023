export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_START = 'SIGNUP_START';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';

export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_START = 'SIGNIN_START';
export const SIGNIN_FAIL = 'SIGNIN_FAIL';

export const SIGNOUT_START = 'SIGNOUT_START';
export const SIGNOUT_FAIL = 'SIGNOUT_FAIL';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';