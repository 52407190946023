import React from 'react';
import PropTypes from 'prop-types';
import { Input, TextArea, Grid } from 'semantic-ui-react';

export default function ResourceLink(props) {
    return (
        <Grid.Column width={5}>
            <Grid.Row style={{ height: '14vh' }} verticalAlign="top" stretched>
                <h3>Resource Title</h3>
                <Input
                    name="name"
                    onChange={props.formProps.handleChange}
                    value={props.formProps.values.name || ''}
                    placeholder="Title..."
                    onBlur={props.formProps.handleBlur}
                    style={{ width: '100%' }}
                />
                <p style={{ color: 'red' }}>{props.formProps.errors.name}</p>
            </Grid.Row>
            <Grid.Row style={{ height: '20vh' }} verticalAlign="top" stretched>
                <h3>Add a Description</h3>
                <TextArea
                    name="description"
                    onChange={props.formProps.handleChange}
                    value={props.formProps.values.description || ''}
                    placeholder="Enter a description"
                    style={{ minHeight: 100, minWidth: 800 }}
                />
                <p style={{ color: 'red' }}>{props.formProps.errors.description}</p>
            </Grid.Row>
            <Grid.Row style={{ height: '10vh' }} verticalAlign="top" stretched>
                <h3>Link</h3>
                <Input
                    name="link"
                    onChange={props.formProps.handleChange}
                    value={props.formProps.values.link || ''}
                    placeholder="Link..."
                    style={{ width: '100%' }}
                    label="http://"
                />
                <p style={{ color: 'red' }}>{props.formProps.errors.link}</p>
            </Grid.Row>
        </Grid.Column>
    );
}

ResourceLink.propTypes = {
    formProps: PropTypes.object,
};
