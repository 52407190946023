import React from 'react';
import { Tab } from 'semantic-ui-react';
import MySchool from '../../Templates/MySchool/MySchool';
import { NavLink, Route } from 'react-router-dom';
import ProtectedRoute from '../../Templates/Authentication/ProtectedRoute';
import { Role } from '../..';

/*
    Single Tab object will be combine with other tabs into a pane array.
    The pane will be inserted into ContentBox component as prop
*/

const MSTab = () => (
    <Tab.Pane>
        <MySchool />
    </Tab.Pane>
);
const MySchoolTab = {
    menuItem: {
        as: NavLink,
        id: 'ms',
        content: 'My School',
        to: '/dashboard/my-school',
        exact: true,
        key: 'ms',
    },
    pane: (
        <ProtectedRoute
            key="ms"
            exact
            path="/dashboard/my-school"
            component={MSTab}
        />
    ),
};

export default MySchoolTab;
