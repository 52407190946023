/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Menu } from 'semantic-ui-react';
import { workspace } from './menuItems';
import { connect } from 'react-redux';
import { Role } from '../../';
const WorkSpaceMenu = (props) => {
    const { activeItem, setActiveItem, authUser, isCollaborator } = props;

    const handleItemClick = (e, { name }) => {
        setActiveItem(name);
    };

    const isCaseManager =
        authUser?.attributes?.['custom:role'] === Role.CASE_MANAGER;

    return (
        <Menu pointing vertical fluid>
            <Menu.Item
                name={workspace.PROGRESS_MONITOR}
                active={activeItem === workspace.PROGRESS_MONITOR}
                onClick={handleItemClick}
            />
            {isCaseManager && !isCollaborator && (
                <Menu.Item
                    name={workspace.COLLABORATE}
                    active={activeItem === workspace.COLLABORATE}
                    onClick={handleItemClick}
                />
            )}
            <Menu.Item
                name={workspace.INSIGHTS}
                active={activeItem === workspace.INSIGHTS}
                onClick={handleItemClick}
            />
            {isCaseManager && !isCollaborator && (
                <Menu.Item
                    name={workspace.DATA_LOG}
                    active={activeItem === workspace.DATA_LOG}
                    onClick={handleItemClick}
                />
            )}
        </Menu>
    );
};
const mapStateToProps = (state) => ({
    authUser: state.auth.authenticatedUser,
});
export default connect(mapStateToProps)(WorkSpaceMenu);
