import React from 'react';
import { Tab } from 'semantic-ui-react';
// import Library from '../../Templates/Library/Library';
import { Route, NavLink } from 'react-router-dom';
import DataLog from '../../Templates/DataLog';

const DataLogTest = () => (
    <Tab.Pane>
        <DataLog />
    </Tab.Pane>
);

const DataLogDemo = {
    menuItem: {
        as: NavLink,
        id: 'datalog',
        content: 'Data Log',
        to: '/datalog',
        exact: true,
        key: 'dl',
    },

    pane: <Route key="dl" path="/datalog" exact component={DataLog} />,
};

export default DataLogDemo;
