import React from 'react';
import { Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { CollaborationContent } from '..';

const SubmitModal = (props) => {
    const { open, setOpen, ...rest } = props;
    return (
        <Modal
            {...rest}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
        >
            <Modal.Content>{rest.children}</Modal.Content>
        </Modal>
    );
};

export default SubmitModal;
SubmitModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
};
