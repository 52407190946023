import React, { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { API, graphqlOperation } from 'aws-amplify';
import { listAssignments, getPathway } from '../../../graphql/queries';

const CompletedPathwayList = (props) => {
    const { student } = props;
    const [assignments, setAssignments] = useState([]);
    const [completedPathways, setCompletedPathways] = useState([]);
    useEffect(() => {
        (async function () {
            try {
                const result = await API.graphql(
                    graphqlOperation(listAssignments, {
                        filter: {
                            studentID: { eq: student.id },
                            is_completed: { eq: true },
                        },
                    })
                );
                setAssignments(result.data.listAssignments.items);
            } catch (e) {
                // console.log(e)
            }
        })();
    }, [student]);

    useEffect(() => {
        (async function () {
            let result;
            for (let i = 0; i < assignments.length; i++) {
                result = await API.graphql(
                    graphqlOperation(getPathway, {
                        id: assignments[i].pathwayID,
                    })
                );
                if (result.data?.getPathway)
                    setCompletedPathways([
                        ...completedPathways,
                        result.data?.getPathway,
                    ]);
            }
        })();
    }, [assignments]);

    const pathwayList = completedPathways.map((item, idx) => (
        <Grid.Column key={idx}>
            {item.name} -{' '}
            {new Date(
                assignments.find((a) => a.pathwayID === item.id).completedDate
            ).toLocaleDateString()}
        </Grid.Column>
    ));
    return pathwayList;
};

export default CompletedPathwayList;
