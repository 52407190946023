import * as actionTypes from './actions/actionTypes';
import { updateObject } from '../../utility/utility';

const initialState = {
    parent: null,
    student: null,
    error: null,
    loading: false,
};

const fetchStudentSuccess = (state, action) => {
    return updateObject(state, {
        student: action.payload,
        loading: false,
        error: null,
    });
};

const fetchStudentStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null,
    });
};

const fetchStudentFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
    });
};

const fetchParentSuccess = (state, action) => {
    return updateObject(state, {
        parent: action.payload,
        loading: false,
        error: null,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_STUDENT_SUCCESS:
            return fetchStudentSuccess(state, action);
        case actionTypes.FETCH_STUDENT_START:
            return fetchStudentStart(state, action);
        case actionTypes.FETCH_STUDENT_FAIL:
            return fetchStudentFail(state, action);
        case actionTypes.FETCH_PARENT_SUCCESS:
            return fetchParentSuccess(state, action);

        default: {
            return state;
        }
    }
};

export default reducer;
