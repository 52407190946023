import React, { useState, useEffect } from 'react';
import { getPathway } from './queries';
import { PathwayInfo } from '../../Molecules';
import { Modal, Loader, Dimmer } from 'semantic-ui-react';
import AssignmentInfo from './AssignmentInfo';
import { fetchData } from './Assignments';
import PropTypes from 'prop-types';

const AssignmentModal = (props) => {
    const { setOpen, open, pathwayID, ...rest } = props;
    const [pathway, setPathway] = useState();

    useEffect(() => {
        if (pathwayID) {
            (async function () {
                const result = await fetchData(
                    getPathway,
                    { id: pathwayID },
                    'getPathway'
                );
                setPathway(result);
            })();
        }
    }, [pathwayID]);
    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size="small"
        >
            <Modal.Header>Assignment Info</Modal.Header>
            <Modal.Content>
                <AssignmentInfo
                    ownerID={pathway && pathway.owner ? pathway.owner : null}
                    setOpen={setOpen}
                    {...rest}
                />
                {pathway ? (
                    <PathwayInfo pathway={pathway} />
                ) : (
                    <Dimmer active inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                )}
            </Modal.Content>
        </Modal>
    );
};

export default AssignmentModal;

AssignmentModal.propTypes = {
    setOpen: PropTypes.func,
    pathwayID: PropTypes.string,
    open: PropTypes.bool,
};
