/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Grid, Message, Form } from 'semantic-ui-react';
import { API } from 'aws-amplify';
import {
    createTeacherStudent,
    createCaseManagerStudent,
} from '../../../graphql/mutations';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
    ModalButtons as CollaborationButtons,
    SearchCollaborators,
} from '../../Molecules';
import { Role } from '../../';

export const CollaborationContent = (props) => {
    const { student, setOpen } = props;
    const [role, setRole] = useState(Role.CASE_MANAGER);
    const [collaborators, setCollaborators] = useState([]);
    const [alert, setAlert] = useState();
    const [loading, setLoading] = useState(false);

    const initialValues = {
        collaborators: [],
        studentID: '',
    };

    const validationSchema = Yup.object({});

    const collaborate = async (data) => {
        const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        let query;
        switch (role) {
            case Role.CASE_MANAGER:
                query = createCaseManagerStudent;
                break;
            case Role.TEACHER:
                query = createTeacherStudent;
                break;
        }
        try {
            // console.log("[SUBMIT DATA]",data);
            // Todo: activate this to send mutations to backend
            await API.graphql({
                query: query,
                variables: { input: data },
            });

            setAlert({
                message: 'Successfully created collaboration ',
                status: 'success',
            });
            wait(5000).then(() => setAlert(null));
        } catch (err) {
            setAlert({
                message: 'Failed to create collaboration',
                status: 'fail',
            });
            wait(5000).then(() => setAlert(null));
            throw err;
        }
    };

    const onSubmit = async (values, actions) => {
        actions.setSubmitting(false);
        const data = values.collaborators.map((id) => {
            switch (role) {
                case Role.CASE_MANAGER:
                    return {
                        casemanagerID: id,
                        studentID: student.id,
                        is_collaborator: false,
                    };
                case Role.TEACHER:
                    return {
                        teacherID: id,
                        studentID: student.id,
                    };
            }
        });

        setLoading(true);
        for (let collaborationData of data) {
            if (collaborationData) await collaborate(collaborationData);
        }
        // filter out collaborator has been added and remove from search result
        let remainingCollaborators = collaborators.filter(
            (collaborator) => !values.collaborators.includes(collaborator.id)
        );
        setCollaborators(remainingCollaborators);
        setLoading(false);

        actions.resetForm();
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {(formProps) => {
                const { handleReset, handleSubmit, isValid, isSubmitting, dirty } =
                    formProps;
                return (
                    <Form onReset={handleReset} onSubmit={handleSubmit}>
                        <Grid columns={1} relaxed stackable>
                            <Grid.Row>
                                <Grid.Column>
                                    {alert ? (
                                        <Message
                                            positive={alert.status === 'success'}
                                            negative={alert.status === 'fail'}
                                            onDismiss={() => setAlert(null)}
                                        >
                                            {alert.message}
                                        </Message>
                                    ) : null}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <SearchCollaborators
                                        formProps={formProps}
                                        studentID={student.id}
                                        role={role}
                                        setRole={setRole}
                                        collaborators={collaborators}
                                        setCollaborators={setCollaborators}
                                        loading={loading}
                                        setLoading={setLoading}
                                    />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row columns={1} stretched>
                                <Grid.Column>
                                    <CollaborationButtons
                                        setOpen={setOpen}
                                        isValid={isValid}
                                        isSubmitting={isSubmitting}
                                        dirty={dirty}
                                        loading={loading}
                                        closeButton={false}
                                    >
                                        Collaborate
                                    </CollaborationButtons>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CollaborationContent;

CollaborationContent.propTypes = {
    pathway: PropTypes.object,
    setOpen: PropTypes.func,
};
