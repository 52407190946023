import React from 'react';
import { Tab } from 'semantic-ui-react';
import { NavLink, Route } from 'react-router-dom';
import { Assignments } from '../../Templates';
import ProtectedRoute from '../../Templates/Authentication/ProtectedRoute';
import { Role } from '../..';

const ATab = () => (
    <Tab.Pane key="assignments">
        <Assignments />
    </Tab.Pane>
);
const AssignmentsTab = {
    menuItem: {
        as: NavLink,
        id: 'assignments',
        content: 'Assignments',
        to: '/dashboard/assignments',
        exact: true,
        key: 'assignments',
    },
    pane: (
        <ProtectedRoute
            key="assignments"
            exact
            path="/dashboard/assignments"
            component={ATab}
        />
    ),
};

export default AssignmentsTab;
