import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { TopicSelectionButtons } from '..';
import TopicSelectionDropdown from '../../Atoms/TopicSelection/TopicSelectionDropdown';

const TopicSelection = (props) => {
    const [topic, setTopic] = useState('');

    // To-do: change topic state to selected topic from dropdown
    // To-do: (Colin) refer to AssignStudentPathwayContent.js for incorporating formik and yup for dropdown
    // then uncomment the props below for TopicSelectionButtons
    return (
        <Grid relaxed stackable>
            <Grid.Row
                style={{ height: '63vh' }}
                verticalAlign="top"
                columns={1}
                stretched
            >
                <Grid.Column>
                    <TopicSelectionDropdown
                        listTopic={props.listTopics}
                        topic={(topic) => setTopic(topic)}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1} stretched>
                <Grid.Column>
                    <TopicSelectionButtons
                        setOpen={props.setOpen}
                        next={() => props.next(topic)}
                        disable={topic === ''}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

TopicSelection.propTypes = {
    setOpen: PropTypes.func,
    next: PropTypes.func,
    listTopics: PropTypes.array,
};

export default TopicSelection;
