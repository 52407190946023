import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Role } from '../../';
import { listAssignments } from '../../../graphql/queries';
import { API } from 'aws-amplify';
import { Grid, Divider, Container, Header } from 'semantic-ui-react';
import AssignmentModal from './AssignmentModal';
import Assignment from './Assignment';
import PropTypes from 'prop-types';

const Assignments = (props) => {
    const { authUser } = props;
    const [assignments, setAssignments] = useState([]);
    const [pathway, setPathway] = useState();
    const [open, setOpen] = useState(false);
    const isStudent =
        authUser &&
        authUser.attributes &&
        authUser.attributes['custom:role'] === Role.STUDENT;
    const studentID = authUser && authUser.username;
    useEffect(() => {
        (async function () {
            const filter = {
                studentID: { eq: studentID },
            };
            const result = await API.graphql({
                query: listAssignments,
                variables: { filter: filter },
            });
            setAssignments(
                result.data.listAssignments.items.filter(
                    (assignment) =>
                        !!assignment.due_date &&
                        assignment.completed_modules?.length === 0
                )
            );
        })();
    }, [studentID]);
    const display = isStudent ? (
        <Grid doubling columns={5}>
            {assignments.map((item) => {
                const pathway = {
                    id: item.pathwayID,
                    dueDate: item.due_date,
                    assignmentID: item.id,
                };
                return (
                    <Assignment
                        key={item.id}
                        setOpenModal={setOpen}
                        setPathway={setPathway}
                        pathway={pathway}
                        src={
                            'https://react.semantic-ui.com/images/wireframe/image-text.png'
                        }
                    />
                );
            })}
        </Grid>
    ) : (
        <p>For Student User Only</p>
    );

    return (
        <Container>
            <AssignmentModal
                open={open}
                setOpen={setOpen}
                pathwayID={pathway && pathway.id}
                assignmentID={pathway && pathway.assignmentID}
                dueDate={pathway && pathway.dueDate}
                assignments={assignments}
                setAssignments={setAssignments}
            />
            <Header>Assignments</Header>
            <Divider />
            {display}
        </Container>
    );
};

export const fetchData = async (query, data, queryName) => {
    let result = await API.graphql({
        query: query,
        variables: data,
    });
    return result.data[queryName];
};

const mapStateToProps = (state) => ({
    authUser: state.auth.authenticatedUser,
});

export default connect(mapStateToProps)(Assignments);

Assignments.propTypes = {
    authUser: PropTypes.object,
};
