import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as actionTypes from './actionTypes';
import * as queries from './queries';
import * as Role from '../../Auth/roleTypes';

export const fetchStudentsStart = () => {
    return {
        type: actionTypes.FETCH_STUDENTS_START,
    };
};

export const fetchCollaborateStudentsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_COLLABORATE_STUDENTS_SUCCESS,
        payload: payload,
    };
};

export const fetchCaseLoadStudentsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_CASELOAD_STUDENTS_SUCCESS,
        payload: payload,
    };
};

export const fetchUnAssignedStudentsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_UNASSIGNED_STUDENTS_SUCCESS,
        payload: payload,
    };
};

export const fetchStudentsFail = (error) => {
    return {
        type: actionTypes.FETCH_STUDENTS_FAIL,
        error: error,
    };
};

export const fetchStudents = (isCollaborator = null) => {
    return async (dispatch) => {
        await dispatch(fetchStudentsStart());
        const user = await Auth.currentAuthenticatedUser({
            bypassCache: false,
        });
        let filter;
        if (user.attributes['custom:role'] === Role.CASE_MANAGER) {
            filter =
                isCollaborator !== null
                    ? {
                          is_collaborator: { eq: isCollaborator },
                      }
                    : {
                          is_collaborator: { ne: true },
                          and: {
                              is_collaborator: { ne: false },
                          },
                      };

            filter.casemanagerID = { eq: user.username };
            try {
                const result = await API.graphql(
                    graphqlOperation(queries.ListStudentsForCM, { filter: filter })
                );
                const payload = result.data.syncCaseManagerStudents.items.map(
                    (item) => {
                        return item.student;
                    }
                );
                if (isCollaborator === true)
                    await dispatch(fetchCollaborateStudentsSuccess(payload));
                else if (isCollaborator === false)
                    await dispatch(fetchCaseLoadStudentsSuccess(payload));
                else dispatch(fetchUnAssignedStudentsSuccess(payload));
            } catch (error) {
                await dispatch(fetchStudentsFail(error));
            }
        } else if (user.attributes['custom:role'] === Role.TEACHER) {
            filter = {
                teacherID: { eq: user.username },
            };

            try {
                const result = await API.graphql(
                    graphqlOperation(queries.syncTeacherStudents, { filter: filter })
                );
                const payload = result.data.syncTeacherStudents.items.map((item) => {
                    return item.student;
                });
                await dispatch(fetchCollaborateStudentsSuccess(payload));
            } catch (error) {
                await dispatch(fetchStudentsFail(error));
            }
        } else
            dispatch(
                fetchStudentsFail(new Error('Access not allowed for this user role'))
            );
    };
};

export const fetchStudentStart = () => {
    return {
        type: actionTypes.FETCH_STUDENT_START,
    };
};
export const fetchStudentFail = (error) => {
    return {
        type: actionTypes.FETCH_STUDENT_FAIL,
        error: error,
    };
};
export const fetchStudentSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_STUDENT_SUCCESS,
        payload: payload,
    };
};
export const fetchStudent = (studentID = '6a32faf1-06d7-4dca-b992-929c060999ba') => {
    return async (dispatch) => {
        await dispatch(fetchStudentStart());
        try {
            const result = await API.graphql(
                graphqlOperation(queries.getStudent, { id: studentID })
            );
            const payload = result.data.getStudent;
            await dispatch(fetchStudentSuccess(payload));
        } catch (error) {
            await dispatch(fetchStudentFail());
        }
    };
};

export const fetchPathwaysCreatedStart = () => {
    return {
        type: actionTypes.FETCH_PATHWAYS_CREATED_START,
    };
};
export const fetchPathwaysCreatedFail = (error) => {
    return {
        type: actionTypes.FETCH_PATHWAYS_CREATED_FAIL,
        error: error,
    };
};
export const fetchPathwaysCreatedSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_PATHWAYS_CREATED_SUCCESS,
        payload: payload,
    };
};
export const fetchPathwaysCreated = () => {
    return async (dispatch) => {
        await dispatch(fetchPathwaysCreatedStart());
        let ownerID = '';
        try {
            // Retrieve current logged in user
            const user = await Auth.currentAuthenticatedUser({
                bypassCache: false,
            });

            ownerID = user.username;
            const filter = {
                owner: { eq: ownerID },
            };
            const result = await API.graphql({
                query: queries.listPathways,
                variables: { filter: filter },
            });
            await dispatch(
                fetchPathwaysCreatedSuccess(result.data.listPathways.items.length)
            );
        } catch (error) {
            await dispatch(fetchPathwaysCreatedFail(error));
        }
    };
};

export const fetchPathwaysStart = () => {
    return {
        type: actionTypes.FETCH_PATHWAYS_START,
    };
};
export const fetchPathwaysFail = (error) => {
    return {
        type: actionTypes.FETCH_PATHWAYS_FAIL,
        error: error,
    };
};
export const fetchPathwaysSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_PATHWAYS_SUCCESS,
        payload: payload,
    };
};
export const fetchPathways = () => {
    return async (dispatch) => {
        await dispatch(fetchPathwaysStart());
        try {
            const result = await API.graphql({ query: queries.listPathways2 });
            let pathways = {};
            result.data.listPathways.items.forEach((pathway) => {
                if (pathway.category in pathways) {
                    pathways[pathway.category] = [
                        ...pathways[pathway.category],
                        pathway,
                    ];
                } else {
                    pathways[pathway.category] = [pathway];
                }
            });
            await dispatch(fetchPathwaysSuccess(pathways));
        } catch (error) {
            await dispatch(fetchPathwaysFail(error));
        }
    };
};

export const signOutSuccess = (payload) => {
    return {
        type: actionTypes.SIGN_OUT_SUCCESS,
    };
};
