import React from 'react';
import { contentTypes as types } from '../menuItems';
import PropTypes from 'prop-types';
import VideoUI from '../../../Organism/VideoUI/VideoUI';
import ResourceLink from './ResourceLink/ResourceLink';

import { Syllabus } from '../../../Atoms/';

import Article from '../Article/Article';
/**
 * This component display corresponding content UI
 * for that content type that consume the content
 * and assignment props data
 *
 * @param {{content: object, assignment: object, type: string}} props
 * @returns Content UI populated with content data
 */
export const ModuleContent = (props) => {
    // Pass the content and assignmet props to corresponding UI conponent
    const { content, assignment, type } = props;
    if (content) {
        switch (type) {
            case types.RESOURCE_LINK:
                return <ResourceLink content={content} />;
            case types.ARTICLE:
                return <Article content={content} />;
            case types.PROJECT:
                return <div>Project Component</div>;
            case types.VIDEO:
                return content.map((contentItem, i) => (
                    <VideoUI content={contentItem} key={i} />
                ));

            default:
                return <Syllabus content={content} />;
        }
    }
    // option: Redirect to 404 page
    return <div>Content Not Found</div>;
};
export default ModuleContent;

ModuleContent.propTypes = {
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    assignment: PropTypes.object,
    type: PropTypes.string,
};
