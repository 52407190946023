import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { getTeacher, getCaseManager } from './queries';
import { Grid, Button } from 'semantic-ui-react';
import { fetchData } from './Assignments';
import PropTypes from 'prop-types';

const AssignmentInfo = (props) => {
    const { dueDate, ownerID, assignments, setAssignments, assignmentID, setOpen } =
        props;
    const history = useHistory();
    const [assignor, setAssignor] = useState();
    const overDue = new Date(dueDate).getDate() - new Date().getDate() < 0;
    useEffect(() => {
        if (ownerID) {
            (async function () {
                const caseManager = await fetchData(
                    getCaseManager,
                    { id: ownerID },
                    'getCaseManager'
                );
                if (caseManager) {
                    setAssignor(caseManager);
                } else {
                    const teacher = await fetchData(
                        getTeacher,
                        { id: ownerID },
                        'getTeacher'
                    );
                    setAssignor(teacher);
                }
            })();
        }
    }, [ownerID]);

    const startAssginmentHanlder = () => {
        setAssignments(
            assignments.filter((assignment) => assignment.id !== assignmentID)
        );
        setOpen(false);
        const pathwayID = assignments.find((a) => a.id === assignmentID).pathwayID;
        history.push({
            pathname: '/pathway',
            state: { pathwayID: pathwayID },
        });
    };
    return (
        <Grid columns={2}>
            <Grid.Column>
                <p>
                    <b>Due Date: </b>
                    {new Date(dueDate).toDateString()}
                </p>
                <p>
                    <b>Assigned By: </b>
                    {assignor && assignor.name}
                </p>
            </Grid.Column>
            <Grid.Column align="right">
                <Button
                    color={!overDue ? 'blue' : 'orange'}
                    disabled={overDue}
                    onClick={startAssginmentHanlder}
                >
                    {overDue ? 'Late' : 'Open'}
                </Button>
            </Grid.Column>
        </Grid>
    );
};

export default AssignmentInfo;

AssignmentInfo.propTypes = {
    setOpen: PropTypes.func,
    dueDate: PropTypes.string,
    ownerID: PropTypes.string,
    assignments: PropTypes.array,
    setAssignments: PropTypes.func,
    assignmentID: PropTypes.string,
};
