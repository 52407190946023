import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    Button,
    Form,
    Grid,
    Header,
    Icon,
    Message,
    Segment,
} from 'semantic-ui-react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { resetPassword } from '../../../store';;
import PropTypes from 'prop-types';

const initialValues = {
    email: '',
    code:'',
    password: '',
    confirmPassword: '',
    error: '',
};

const validationSchema = Yup.object({
    email: Yup.string().email('Not a valid email.').required('Email is required'),
    code: Yup.string().required('Verification code is required'),
    password: Yup.string().required('Password is required.'),
    confirmPassword: Yup.string()
        .required('Comfirm password required.')
        .oneOf([Yup.ref('password'), null], 'Passwords must match.'),
});

const ResetPassword = (props) => {
    const { resetPassword, loading, authErr } = props;
    const history = useHistory();
    const { email, code } = useParams();
    const onSubmit = async (values, actions) => {
        // Create a new user in AWS Cognito User Pool
        // Then create and save the user info in DynamoDB
        const { email, code, password } = values;
        try {
            await resetPassword(email, code, password);
            history.push({
                pathname:    '/login',
                state: {
                    action: 'reset-password',
                    status: 'success',
                    message: 'Successfully reset password!'
                }
            });
        } catch (err) {
            if (err.message) actions.setFieldError('error', err.message);
        }
    };
    if (email) initialValues.email = email;
    if (code) initialValues.code = code;

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {(formikProps) => {
                const {
                    handleSubmit,
                    handleReset,
                    errors,
                    touched,
                    isValid,
                    dirty,
                } = formikProps;

                return (
                    <Grid
                        textAlign="center"
                        style={{ height: '80vh' }}
                        verticalAlign="middle"
                    >
                        <Grid.Column style={{ maxWidth: 450 }}>
                            <Header as="h2" textAlign="center">
                                <Icon name="globe" />{' '}
                                Reset Password Confirmation
                            </Header>
                            <Form
                                size="large"
                                onReset={handleReset}
                                onSubmit={handleSubmit}
                                error
                            >
                                <Segment
                                    stacked
                                    align="left"
                                    color={
                                        isValid && dirty
                                            ? 'green'
                                            : touched && !isValid
                                            ? 'red'
                                            : null
                                    }
                                >
                                    <Field
                                        as={Message}
                                        error
                                        name="error"
                                        content={errors.error}
                                    />

                                    <Field
                                        type="email"
                                        name="email"
                                        fluid
                                        icon="mail outline"
                                        iconPosition="left"
                                        placeholder={'Email'}
                                        error={
                                            touched.email && errors.email
                                                ? {
                                                      content: errors.email,
                                                      pointing: 'above',
                                                  }
                                                : null
                                        }
                                        as={Form.Input}
                                        readOnly={!!email}
                                        style={{display: !!email && 'none'}}

                                    />

                                    <Field
                                        name="code"
                                        fluid
                                        icon="barcode"
                                        iconPosition="left"
                                        placeholder={'Verification Code'}
                                        error={
                                            touched.code && errors.code
                                                ? {
                                                      content: errors.code,
                                                      pointing: 'above',
                                                  }
                                                : null
                                        }
                                        as={Form.Input}
                                        readOnly={!!code}
                                        style={{display: !!code && 'none'}}
                                    />

                                    <Field
                                        name="password"
                                        fluid
                                        icon="privacy"
                                        iconPosition="left"
                                        placeholder="New Password"
                                        type="password"
                                        error={
                                            touched.password && errors.password
                                                ? {
                                                      content: errors.password,
                                                      pointing: 'above',
                                                  }
                                                : null
                                        }
                                        as={Form.Input}
                                    />
                                    <Field
                                        name="confirmPassword"
                                        fluid
                                        icon="privacy"
                                        iconPosition="left"
                                        placeholder="Confirm New Password"
                                        type="password"
                                        error={
                                            touched.confirmPassword &&
                                            errors.confirmPassword
                                                ? {
                                                      content:
                                                          errors.confirmPassword,
                                                      pointing: 'above',
                                                  }
                                                : null
                                        }
                                        as={Form.Input}
                                    />

                                    <Button
                                        loading={loading}
                                        disabled={loading || !isValid}
                                        primary
                                        fluid
                                        size="large"
                                        type="submit"
                                    >
                                        Confirm Reset Password
                                    </Button>
                                </Segment>
                            </Form>
                            
                        </Grid.Column>
                    </Grid>
                );
            }}
        </Formik>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        authErr: state.auth.error,
    };
};
export default connect(mapStateToProps, { resetPassword })(ResetPassword);

ResetPassword.propTypes = {
    resetPassword: PropTypes.func,
    loading: PropTypes.bool,
    authErr: PropTypes.object,
};
