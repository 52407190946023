import { Auth, API, graphqlOperation } from 'aws-amplify';
import {
    createCaseManager,
    createTeacher,
    createStudent,
    createParent,
    createParentStudent,
} from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';
import * as actionTypes from './actionTypes';
import * as Role from '../roleTypes';
import { signOutSuccess as signOut } from '../../CaseManager/actions/actions';

// SIGN UP
const signUpStart = () => {
    return {
        type: actionTypes.SIGNUP_START,
    };
};
const signUpFail = (error) => {
    return {
        type: actionTypes.SIGNUP_FAIL,
        error: error,
    };
};
const signUpSuccess = (payload) => {
    return {
        type: actionTypes.SIGNUP_SUCCESS,
        payload: payload,
    };
};

/**
 * Create a DynamoDB user base on user role
 * @param {{
 * id: string,
 * school: string
 * name: string,
 * role: string,
 * email: string,
 * is_active: boolean
 * verifiedUser: object
 * }} data
 * @param {string} role casemanager, teacher, student or parent
 * @return Promise to create
 */
export const createUser = async (data) => {
    const { role, verifiedUser, ...userData } = data;
    let mutation, relationshipMutation, relationshipData, result;
    try {
        if (role !== Role.PARENT && !userData.schoolID) {
            throw new Error('Must provide school ID');
        }
        if (role === Role.PARENT && !userData.verification_id) {
            throw new Error('Must provide student ID');
        }

        // if (userData.schoolID) {
        //     relationshipData = {
        //         schoolID: userData.schoolID,
        //     };
        // }
        if (role === Role.CASE_MANAGER) {
            mutation = createCaseManager;
            // relationshipMutation = createSchoolCaseManager;
            // relationshipData.casemanagerID = userData.id;
        } else if (role === Role.TEACHER) {
            mutation = createTeacher;
            // relationshipMutation = createSchoolTeacher;
            // relationshipData.teacherID = userData.id;
        } else if (role === Role.STUDENT) {
            mutation = createStudent;
            // relationshipMutation = createSchoolStudent;
            // relationshipData.studentID = userData.id;
        } else if (role === Role.PARENT) {
            mutation = createParent;
            relationshipMutation = createParentStudent;
            relationshipData = {
                parentID: userData.id,
                studentID: verifiedUser.id,
            };
        }

        if (mutation) {
            //Create user
            result = await API.graphql(
                graphqlOperation(mutation, { input: userData })
            );
            if (result?.errors) throw new Error(`Failed to create ${role} user`);
            if (relationshipMutation)
                result = await API.graphql(
                    graphqlOperation(relationshipMutation, {
                        input: relationshipData,
                    })
                );
            if (result?.errors)
                throw new Error(
                    role !== Role.PARENT
                        ? `Failed to connect ${role} user account to school`
                        : `Failed to assign ${role} user account to children`
                );
        } else throw new Error('Please specify correct user info.');
    } catch (err) {
        throw err;
    }
};

/**
 * This function create a new user in Cognito User Pool then create
 * in DynamoDB user with the Cognito user credentials
 * @param {string} role casemanager, teacher, student or parent
 * @param {{name: string, email: string, password: string, role: string}} userData
 *
 * @author Duc
 */
export const signUpUser = (userData) => {
    return async (dispatch) => {
        await dispatch(signUpStart());
        try {
            const {
                name,
                role,
                email,
                password,
                school,
                verificationID,
                accessCode,
                verifiedUser,
            } = userData;
            // Create new user in aws Cognito User Pool
            const newCognitoUser = await Auth.signUp({
                username: email,
                password: password,
                attributes: {
                    email: email,
                    'custom:name': name,
                    'custom:role': role,
                },
            });
            // TODO: (Duc) Add user to the correct Groups.
            const newUserData = {
                id: newCognitoUser.userSub,
                name: name,
                email: email,
                is_active: true,
                verification_id: verificationID,
                verifiedUser: verifiedUser,
                schoolID: school,
                role: role,
            };
            if (role === Role.STUDENT) {
                newUserData.parent_access_code = accessCode;
            }
            // Create user in DynamoDB with Cognito userSub
            await createUser(newUserData);
            await dispatch(signUpSuccess(newCognitoUser));
        } catch (error) {
            if (error.message) await dispatch(signUpFail(error));
            else
                await dispatch(
                    signUpFail(
                        new Error(
                            'Fail to signup. Please try again later or contact Admins.'
                        )
                    )
                );
            throw error;
        }
    };
};

// SIGN OUT
const signOutStart = () => {
    return {
        type: actionTypes.SIGNOUT_START,
    };
};
const signOutFail = (error) => {
    return {
        type: actionTypes.SIGNOUT_FAIL,
        error: error,
    };
};
export const signOutSuccess = () => {
    return {
        type: actionTypes.SIGNOUT_SUCCESS,
    };
};

/**
 * This function signout user from cognito and
 * reset redux authentication states
 * @author Duc
 */
export const signOutUser = () => {
    return async (dispatch) => {
        await dispatch(signOutStart());
        try {
            await Auth.signOut();
            await dispatch(signOutSuccess());
            await dispatch(signOut());
        } catch (error) {
            if (error.message) await dispatch(signOutFail(error));
            else
                await dispatch(
                    signOutFail(
                        new Error('Fail to sign out, Please try again later')
                    )
                );
            throw error;
        }
    };
};

// SIGN IN
export const signInStart = () => {
    return {
        type: actionTypes.SIGNIN_START,
    };
};
export const signInFail = (error) => {
    return {
        type: actionTypes.SIGNIN_FAIL,
        error: error,
    };
};
export const signInSuccess = (payload) => {
    return {
        type: actionTypes.SIGNIN_SUCCESS,
        payload: payload,
    };
};

/**
 * This function Sign In user to cognito and save logged in user
 * in redux authentication states
 * @param {string} email user email as username
 * @param {string} password user password
 *
 * @author Duc
 */
export const signInUser = (email = null, password = null) => {
    return async (dispatch) => {
        await dispatch(signInStart());
        let payload;
        try {
            if (email && password) {
                payload = await Auth.signIn({
                    username: email,
                    password: password,
                });
            } else {
                const user = await Auth.currentAuthenticatedUser();
                if (user) {
                    const userData = await fetchUser(user);
                    payload = { ...user, ...userData };
                }
            }
            await dispatch(signInSuccess(payload));
            return payload;
        } catch (error) {
            if (error.message) await dispatch(signInFail(error));
            else
                await dispatch(
                    signInFail(new Error('Fail to log in. Please try again later.'))
                );
            throw error;
        }
    };
};


// RESET PASSWORD
export const resetPasswordStart = () => {
    return {
        type: actionTypes.RESET_PASSWORD_START,
    };
};
export const resetPasswordFail = (error) => {
    return {
        type: actionTypes.RESET_PASSWORD_FAIL,
        error: error,
    };
};
export const resetPasswordSuccess = (payload) => {
    return {
        type: actionTypes.RESET_PASSWORD_SUCCESS,
        payload: payload,
    };
};

export const resetPassword = (email, code = null, newPassword = null) => {
    return async (dispatch) => {
        await dispatch(resetPasswordStart());
        let payload;
        try {
            if (email && !code && !newPassword) {
                // console.log("Send Email Reset Password Verification Code");
                payload = await Auth.forgotPassword(email)
            }
            if (email && code && newPassword){
                // console.log("Confirm Reset Password with Code")
                payload = await Auth.forgotPasswordSubmit(email, code, newPassword)
            }
            await dispatch(resetPasswordSuccess(payload));
            return payload;
        } catch (error) {
            if (error.message) await dispatch(resetPasswordFail(error));
            else
                await dispatch(
                    resetPasswordFail(
                        new Error(
                            'Fail to reset password. ' +
                                'Please try again later.'
                        )
                    )
                );
            throw error;
        }
    };
};

export const fetchUser = async (user) => {
    let queryType;
    switch (user.attributes['custom:role']) {
        case Role.CASE_MANAGER:
            queryType = 'getCaseManager';
            break;
        case Role.TEACHER:
            queryType = 'getTeacher';
            break;
        case Role.STUDENT:
            queryType = 'getStudent';
            break;
        case Role.PARENT:
            queryType = 'getParent';
            break;
        default:
            break;
    }
    if (queryType) {
        const query = queries[queryType];
        const result = await API.graphql(
            graphqlOperation(query, { id: user.username })
        );
        return result.data[queryType];
    }
};
