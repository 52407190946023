import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { Form, Checkbox, Message, Card, Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const CheckBox = (props) => {
    const { label, name, options, ...rest } = props;
    return (
        <div style={{ margin: '1rem' }}>
            <ErrorMessage
                name={name}
                component={(errMsg) => (
                    <Message negative>
                        <p>{errMsg.children}</p>
                    </Message>
                )}
            />

            <Field name={name} {...rest}>
                {(props) => {
                    const { field } = props;
                    return (
                        <Card.Group>
                            {options.map(
                                (option) =>
                                    option.id && (
                                        <Card
                                            key={option.id}
                                            link={true}
                                            color={
                                                field.value.includes(option.id)
                                                    ? 'teal'
                                                    : null
                                            }
                                            fluid={true}
                                            onClick={() => {
                                                const cb = document.getElementById(
                                                    option.id
                                                );
                                                cb.click();
                                            }}
                                        >
                                            <Card.Content>
                                                <Image
                                                    floated="right"
                                                    size="mini"
                                                    src={`https://avatars.dicebear.com/api/jdenticon/${option.id}.svg`}
                                                />
                                                <Card.Header>
                                                    {option.name}
                                                </Card.Header>
                                                <Card.Meta>
                                                    {'ID: ' + (option.verification_id || 'Not Found')}
                                                </Card.Meta>
                                                <Card.Meta>
                                                    {'Joined: ' +
                                                        new Date(
                                                            option.createdAt
                                                        ).toDateString()}
                                                </Card.Meta>
                                            </Card.Content>
                                            <Card.Content extra>
                                                <Form.Field
                                                    {...field}
                                                    {...rest}
                                                    name={name}
                                                    control={Checkbox}
                                                    id={option.id}
                                                    value={option.id}
                                                    checked={field.value.includes(
                                                        option.id
                                                    )}
                                                    label={'Select ' + option.name}
                                                />
                                            </Card.Content>
                                        </Card>
                                    )
                            )}
                        </Card.Group>
                    );
                }}
            </Field>
        </div>
    );
};

CheckBox.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.array,
    required: PropTypes.bool,
    description: PropTypes.string,
    field: PropTypes.object,
};

export default CheckBox;
