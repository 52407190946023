import React, { Component } from 'react';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { PropTypes } from 'prop-types';

const styles = {
    cursor: 'pointer',
    position: 'absolute',
    top: '10px',
    right: '8px',
};

export default function ClosePopupCross(props) {
    return (
        <div className="cancel-wrap" style={styles}>
            <CancelOutlinedIcon onClick={props.close} />
        </div>
    );
}

ClosePopupCross.propTypes = {
    close: PropTypes.func,
};
