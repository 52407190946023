import React, { useEffect } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { Segment } from 'semantic-ui-react';

// Controls which route a user sees if they are logged in
const ProtectedRoute = ({ component: Component, hasLogin, loading, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                !hasLogin && !loading ? (
                    <Redirect to="/login" />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};

const mapStateToProps = (state) => ({
    hasLogin: state.auth.isAuthenticated,
    loading: state.auth.loading,
});

export default connect(mapStateToProps)(ProtectedRoute);

ProtectedRoute.propTypes = {
    component: PropTypes.elementType,
    hasLogin: PropTypes.bool,
    loading: PropTypes.bool,
};
