export const listStudents = /* GraphQL */ `
    query ListStudents(
        $filter: ModelStudentFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
            }
            nextToken
            startedAt
        }
    }
`;

export const listAssignments = /* GraphQL */ `
    query ListAssignments(
        $filter: ModelAssignmentFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listAssignments(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
            }
            nextToken
            startedAt
        }
    }
`;

export const getPathway = /* GraphQL */ `
    query GetPathway($id: ID!, $filter: ModelAssignmentFilterInput) {
        getPathway(id: $id) {
            id
            category
            name
            description
            LearningOutcome
            is_approved
            owner
            createdAt
            updatedAt
            PathwayContents {
                items {
                    content {
                        id
                        type
                        name
                        description
                        article_input
                        resource_link
                        upload_file {
                            key
                        }
                        createdAt
                    }
                }
            }
            Assignments(filter: $filter) {
                items {
                    completed_modules
                    due_date
                    createdAt
                    completedDate
                    _version
                    id
                    studentID
                    pathwayID
                    teacherID
                    casemanagerID
                }
            }
        }
    }
`;

export const syncPathwayContents = /* GraphQL */ `
    query SyncPathwayContents(
        $filter: ModelPathwayContentFilterInput
        $limit: Int
        $nextToken: String
        $lastSync: AWSTimestamp
    ) {
        syncPathwayContents(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            lastSync: $lastSync
        ) {
            items {
                id
                pathwayID
                contentID
                content {
                    id
                    type
                }
                pathway {
                    name
                }
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
            }
            nextToken
            startedAt
        }
    }
`;
