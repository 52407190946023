import React, { useState, useEffect } from 'react';
import {
    Image,
    Segment,
    Card,
    Divider,
    Input,
    Icon,
    Button,
    Form,
    Message,
    Transition,
} from 'semantic-ui-react';
import { PowerSurveyInfo } from '../';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { updateStudent } from '../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';

const StudentInfo = (props) => {
    const { student, showParentCode = false } = props;
    const [showAccessCode, setShowAccessCode] = useState();
    const [accessCode, setAccessCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const profile = student.Profile ? student.Profile : null;

    useEffect(() => {
        setAccessCode(student?.parent_access_code || '');
    }, [student]);

    const updateAccessCode = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);
        const code = uuidv4();
        const newAccessCode = code.substr(code.length - 6);
        const studentDetails = {
            id: student.id,
            parent_access_code: newAccessCode,
            _version: student._version,
        };
        try {
            const result = await API.graphql(
                graphqlOperation(updateStudent, {
                    input: studentDetails,
                })
            );
            if (result.data.updateStudent?.parent_access_code) {
                setAccessCode(newAccessCode);
            }
            setShowAccessCode(true);
        } catch (err) {
            setError('Fail to generate code');
        }
        setLoading(false);
    };

    const [visible, setVisible] = useState(true);
    const avatar = `https://avatars.dicebear.com/api/jdenticon/${student?.id}.svg`;
    const studentCard = (
        <Card fluid>
            <Card.Content>
                <Transition.Group duration={500}>
                    {visible ? (
                        <Image
                            floated="right"
                            size="small"
                            src={avatar}
                            style={{ backgroundColor: '#fff' }}
                        />
                    ) : (
                        <Image floated="right" size="mini" src={avatar} />
                    )}
                </Transition.Group>

                <Card.Header>{student.name}</Card.Header>
                <Card.Meta>
                    Student ID:{' '}
                    {student?.verification_id?.toUpperCase() || 'Not Found'}
                </Card.Meta>

                <Transition visible={visible} animation="fade down" duration={500}>
                    <Card.Content>
                        <Divider style={{ marginTop: '0.5rem' }} />
                        <Card.Content>
                            <strong>Grade: </strong> {profile?.grade}
                        </Card.Content>
                        <Card.Content>
                            <strong>Strengths:</strong>
                            <Card.Meta>{profile?.skill_strengths}</Card.Meta>
                        </Card.Content>
                        <Card.Content>
                            <strong>Weaknesses:</strong>
                            <Card.Meta>{profile?.skill_weaknesses}</Card.Meta>
                        </Card.Content>
                        <Card.Content>
                            <strong>Interests:</strong>
                            {(() => {
                                try {
                                    const interests = JSON.parse(
                                        profile?.interests
                                    ).map((i, idx) => <li key={idx}>{i}</li>);
                                    return <ul>{interests}</ul>;
                                } catch (err) {
                                    return profile?.interests;
                                }
                            })()}
                        </Card.Content>
                        <Card.Content extra>
                            <strong>
                                Post-Secondary Goal: {profile?.post_secondary}
                            </strong>
                        </Card.Content>
                    </Card.Content>
                </Transition>
                <Card.Content extra textAlign="center">
                    <Icon
                        link
                        size='large'
                        name={visible ? 'caret up' : 'caret down'}
                        onClick={() => setVisible(!visible)}
                    />
                </Card.Content>
            </Card.Content>

            {showParentCode && (
                <Card.Content>
                    <Form>
                        {error && (
                            <Form.Field>
                                <Message negative>{error}</Message>
                            </Form.Field>
                        )}
                        <Form.Field>
                            <label>Parent Access Code</label>
                            <Input
                                type={showAccessCode ? 'text' : 'password'}
                                icon={
                                    <Icon
                                        name={showAccessCode ? 'eye' : 'eye slash'}
                                        link
                                        onClick={() =>
                                            setShowAccessCode(!showAccessCode)
                                        }
                                    />
                                }
                                value={accessCode}
                            />
                        </Form.Field>

                        <Button
                            type="button"
                            loading={loading}
                            circular
                            basic
                            onClick={updateAccessCode}
                        >
                            Generate New Code
                        </Button>
                    </Form>
                </Card.Content>
            )}
        </Card>
    );
    return <Segment basic>{studentCard}</Segment>;
};

export default StudentInfo;
StudentInfo.propTypes = {
    student: PropTypes.object,
    showPowerSurveyInfo: PropTypes.bool,
    showStudentInfo: PropTypes.bool,
    showParentCode: PropTypes.bool,
};
