import React from 'react';
import { Container, Header, Segment, Icon, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const SubmitSurveyModalContent = (props) => {
    const { openModal, redirectButtonClick } = props;

    return openModal ? (
        <Container>
            <Header textAlign="center">You are Awesome!</Header>
            <Segment basic textAlign="center">
                <p>
                    <Icon name="star" size="huge" color="yellow" />
                </p>
                <p>
                    Thank you for filling out the SuperPower Survey!
                    <br />
                    Your answers have been saved.
                    <br />
                    <br />
                    <Button
                        primary
                        onClick={redirectButtonClick}
                        content="Click here to be directed to your dashbaord!"
                    />
                </p>
            </Segment>
        </Container>
    ) : null;
};

export default SubmitSurveyModalContent;

SubmitSurveyModalContent.propTypes = {
    openModal: PropTypes.bool.isRequired,
    redirectButtonClick: PropTypes.func,
};
