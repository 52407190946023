import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { API } from 'aws-amplify';
import { CollaboratorResult } from '../../Molecules/';
import { SearchBox, Loader, RoleSwitch } from '../../Atoms';
import { Role } from '../../';
import { connect } from 'react-redux';
import { listCaseManagers, listTeachers } from './queries';

export const SearchCollaborators = (props) => {
    const {
        formProps,
        studentID,
        role,
        setRole,
        collaborators,
        setCollaborators,
        loading,
        setLoading,
    } = props;
    const { authUser } = props; // passed in authUser as prop

    const searchHandler = async (value) => {
        let filter = {
            name: {
                contains: value,
            },
            not: { id: { eq: authUser?.username } },
        };
        let query;
        if (role === Role.CASE_MANAGER) {
            query = listCaseManagers;
        } else if (role === Role.TEACHER) {
            query = listTeachers;
        } else query = null;
        setLoading(true);
        // fetch all collaborators filtered by name
        try {
            const data = await API.graphql({
                query: query,
                variables: { filter: filter },
            });

            const collaboratorList =
                role === Role.CASE_MANAGER
                    ? data.data.listCaseManagers.items
                    : role === Role.TEACHER
                    ? data.data.listTeachers.items
                    : null;

            // filtered collaborators who are not assigned to this studentID
            const filteredCollaborators = collaboratorList.filter((collaborator) => {
                const students = collaborator.Students.items;
                return (
                    students.filter((a) => a.studentID === studentID).length === 0
                );
            });

            setCollaborators(filteredCollaborators);
            setLoading(false);
        } catch (err) {
            // console.log(err);
        }
    };

    const collaboratorsSearchResult = loading ? (
        <Loader />
    ) : (
        <CollaboratorResult collaborators={collaborators} formProps={formProps} />
    );

    return (
        <div>
            <RoleSwitch
                role={role}
                setRole={setRole}
                setCollaborators={setCollaborators}
                formProps={formProps}
            />
            <SearchBox
                searchHandler={searchHandler}
                placeholder="Search collaborators..."
                size="large"
                loading={loading}
                formProps={formProps}
            />
            {collaboratorsSearchResult}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.authenticatedUser,
    };
};

export default connect(mapStateToProps)(SearchCollaborators);

SearchCollaborators.propTypes = {
    formProps: PropTypes.object,
    studentID: PropTypes.string,
    role: PropTypes.string,
    setRole: PropTypes.func,
    collaborators: PropTypes.array,
    setCollaborators: PropTypes.func,
    loading: PropTypes.bool,
    setLoading: PropTypes.func,
    authUser: PropTypes.object,
};
