import { Dropdown } from 'semantic-ui-react';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function TopicSelectionDropdown(props) {
    const tempOptions = [...new Set(props.listTopic)]; //to make sure the array of topics possible is removed of duplicates
    const pathwayOptions = [];

    for (let i = 0; i < tempOptions.length; i++) {
        pathwayOptions.push({
            key: tempOptions[i],
            value: tempOptions[i],
            text: tempOptions[i],
        });
    }

    const handleChange = (event, { value }) => {
        props.topic(value);
    };

    return (
        <Dropdown
            placeholder="Select Category"
            fluid
            search
            selection
            options={pathwayOptions}
            onChange={handleChange}
        />
    );
}

TopicSelectionDropdown.propTypes = {
    listTopic: PropTypes.array,
    topic: PropTypes.func,
};
