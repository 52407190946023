import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
    User,
    Home,
    Layout,
    Login,
    Signup,
    SignupConfirm,
    ForgotPassword,
    ResetPassword,
    Welcome,
    NotFound,
} from './UI';
import { mediaStyles } from './UI/Organism/NavBar/AppMedia';
import { connect } from 'react-redux';
import { signInUser } from './store/Auth/actions/actions';
import ProtectedRoute from './UI/Templates/Authentication/ProtectedRoute';
import PropTypes from 'prop-types';
import { PathwayWorkSpace } from './UI/Molecules';

const App = (props) => {
    const registerPath = '/register/:role(casemanager|teacher|student|parent)';
    return (
        <div className="App">
            <style>{mediaStyles}</style>
            <Layout>
                <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/signup" component={Signup} />
                    <Route exact path={registerPath} component={Signup} />
                    <Route exact path="/confirm-signup" component={SignupConfirm} />
                    <Route exact path="/reset-password" component={ForgotPassword} />
                    <Route exact path="/confirm-reset-password/:email?/:code?" component={ResetPassword} />
                    <Route exact path="/welcome" component={Welcome} />
                    <ProtectedRoute path="/dashboard" component={User} />
                    <ProtectedRoute path="/pathway" component={PathwayWorkSpace} />
                    <Route exact path="/404" component={NotFound} />
                    <Redirect to='/login' />
                </Switch>
            </Layout>
        </div>
    );
};

export default connect(null, { signInUser })(App);

App.propTypes = {
    signInUser: PropTypes.func,
};
