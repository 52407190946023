import React from 'react';
import { Tab } from 'semantic-ui-react';
import Library from '../../Templates/Library/Library';
import { NavLink, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import ProtectedRoute from '../../Templates/Authentication/ProtectedRoute';
import { Role } from '../..';

/* 
    Single Tab object will be combine with other tabs into a pane array.
    The pane will be inserted into ContentBox component as prop
*/

const LTab = () => (
    <Tab.Pane>
        <Library />
    </Tab.Pane>
);
const LibraryTab = {
    menuItem: {
        as: NavLink,
        id: 'lib',
        content: 'Library',
        to: '/dashboard/library',
        exact: true,
        key: 'lib',
    },
    pane: (
        <ProtectedRoute key="lib" exact path="/dashboard/library" component={LTab} />
    ),
};

export default LibraryTab;

LibraryTab.propTypes = {
    authenticatedUser: PropTypes.object,
};
