import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listProgresss } from '../../../graphql/queries';
import { Dropdown, Grid, Segment } from 'semantic-ui-react';
import LineChart from './LineChart';
import StrategyList from './StrategyList';
import CompletedPathwayList from './CompletedPathwayList';
import PropTypes from 'prop-types';

const DataInsights = (props) => {
    // List IEP Goal for student
    const { student } = props;
    const [selectedGoalId, setSelectedGoalId] = useState('');
    const [selectedProgresses, setSelectedProgresses] = useState();

    useEffect(() => {
        if (selectedGoalId)
            (async function () {
                let result;
                try {
                    result = await API.graphql(
                        graphqlOperation(listProgresss, {
                            filter: {
                                iepgoalID: { eq: selectedGoalId },
                                studentID: { eq: student.id },
                            },
                        })
                    );
                    setSelectedProgresses(
                        result.data.listProgresss?.items?.sort(
                            (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
                        )
                    );
                } catch (e) {
                    // console.log(e);
                }
            })();
    }, [selectedGoalId]);

    const iepGoalOptions = student?.IEPGoals?.items.map((goal) => ({
        key: goal.id,
        text: goal.name,
        value: goal.id,
    }));

    const selectGoalHandler = (e, data) => {
        setSelectedGoalId(data.value);
    };

    return (
        <Segment basic>
            <Grid>
                <Grid.Row>
                    <Dropdown
                        placeholder="Select IEP goal..."
                        selection
                        options={iepGoalOptions}
                        onChange={selectGoalHandler}
                    />
                </Grid.Row>
                <Grid.Row>
                    <LineChart progresses={selectedProgresses} />
                </Grid.Row>

                <Grid.Row columns={1}>
                    <h5>Top Strategies</h5>
                    <StrategyList progresses={selectedProgresses} />
                </Grid.Row>
                <Grid.Row columns={1}>
                    <h5>Completed pathways</h5>
                    <CompletedPathwayList student={student} />
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

DataInsights.propTypes = {
    student: PropTypes.object,
};

export default DataInsights;
