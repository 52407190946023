export const listCaseManagers = /* GraphQL */ `
    query ListCaseManagers(
        $filter: ModelCaseManagerFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listCaseManagers(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                verification_id
                name
                email
                is_active
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                Students {
                    items {
                        studentID
                    }
                }
            }
            nextToken
            startedAt
        }
    }
`;
export const listTeachers = /* GraphQL */ `
    query ListTeachers(
        $filter: ModelTeacherFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listTeachers(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                verification_id
                name
                email
                is_active
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                Students {
                    items {
                        studentID
                    }
                }
            }
            nextToken
            startedAt
        }
    }
`;
