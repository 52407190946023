export const CASE_MANAGER = 'casemanager';
export const TEACHER = 'teacher';
export const STUDENT = 'student';
export const PARENT = 'parent';

export const options = ['Case Manager', 'Teacher', 'Student', 'Parent'].map(
    (item) => {
        return {
            key: item,
            text: item,
            value: item.split(' ').join('').toLowerCase(),
        };
    }
);
