export const workspace = {
    PROGRESS_MONITOR: 'Progress Monitor',
    INSIGHTS: 'Insights',
    DATA_LOG: 'Data Log',
    COLLABORATE: 'Collaborate',
};
export const contentTypes = {
    RESOURCE_LINK: 'Resource Link',
    ARTICLE: 'Article',
    PROJECT: 'Project',
    VIDEO: 'Video',
    SYLLABUS: 'Syllabus',
};
