import * as actionType from '../actionType';

const initialState = {
    authenticated: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.AUTHENTICATED:
            return {
                ...initialState,
                // change authenticated state here
            };
        // add more states here
        default:
            return state;
    }
};

export default reducer;
