/* eslint-disable react/prop-types */
import React from 'react';
import { CheckBox } from '../../Atoms';
import PropTypes from 'prop-types';

const CollaboratorResult = (props) => {
    const { collaborators } = props;
    return (
        <CheckBox
            label="Search Collaborators"
            name="collaborators"
            options={collaborators}
        />
    );
};

export default CollaboratorResult;
