import React from 'react';
import { Tab } from 'semantic-ui-react';
import { NavLink, Route } from 'react-router-dom';
import { StudentInfo } from '..';
import ProtectedRoute from '../../Templates/Authentication/ProtectedRoute';
import { Role } from '../..';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StudentProfileTab } from '..';
/* 
    Single Tab object will be combine with other tabs into a pane array.
    The pane will be inserted into ContentBox component as prop
*/

const PTab = () => {
    return (
        <Tab.Pane key="profile">
            <StudentProfileTab />
        </Tab.Pane>
    );
};

const ProfileTab = {
    menuItem: {
        as: NavLink,
        id: 'profile',
        content: 'Profile',
        to: '/dashboard/profile',
        exact: true,
        key: 'profile',
    },
    pane: (
        <ProtectedRoute
            key="profile"
            exact
            path="/dashboard/profile"
            component={PTab}
        />
    ),
};

export default ProfileTab;

PTab.propTypes = {
    student: PropTypes.object,
};
