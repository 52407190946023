import React from 'react';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';
import { Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './Layout.module.css';
import { Media, MediaContextProvider } from '../NavBar/AppMedia';

/* 
    This Layout component consists of:
        - header: Navigation Bar
        - content: Page component
        - footer: Footer component
    This component will wrap the content of the Page inserted as prop
*/

const Layout = (props) => {
    return (
        <Container id={styles['main-container']} style={{ width: '100%' }}>
            <MediaContextProvider>
                <div id={styles.header}>
                    <NavBar authenticated {...props} darkMode={true}>
                        <div id={styles['content-mobile']}>{props.children}</div>
                    </NavBar>
                </div>
                <Media greaterThanOrEqual="computer">
                    <div id={styles.content}>{props.children}</div>
                </Media>
                <div id={styles.footer}>
                    <Footer />
                </div>
            </MediaContextProvider>
        </Container>
    );
};

Layout.propTypes = {
    children: PropTypes.object,
};

export default Layout;
